import React from "react";
import type { FormInstance } from "antd";
import { Button, Form, Input, Popconfirm, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const FormButtonCancel = ({
	form,
	handleFormCancel,
	formReset,
}: {
	form: FormInstance;
	handleFormCancel: any;
	formReset: any | null;
}) => {
	const [submittable, setSubmittable] = React.useState(false);
	const [initialValues, setInitialValues] = React.useState<any>(null);

	// Watch all values
	const values = Form.useWatch([], form);

	React.useEffect(() => {
		if (form.isFieldsTouched() && initialValues && formReset == false) {
			const valuesChanged = Object.keys(values).some((key) => {
				if (typeof values[key] == "object") {
					return (
						JSON.stringify(values[key]) !== JSON.stringify(initialValues[key])
					);
				} else {
					return values[key] !== initialValues[key];
				}
			});

			if (valuesChanged) {
				setSubmittable(true);
			} else {
				setSubmittable(false);
			}
		} else {
			setSubmittable(false);
		}
	}, [values, initialValues]);

	React.useEffect(() => {
		if (formReset) {
			if (values) {
				setInitialValues(values);
			}
		}
	}, [values]);

	return (
		<Popconfirm
			icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
			style={{ marginRight: "auto", background: "#393939" }}
			overlayInnerStyle={{ background: "#393939" }}
			title={
				<div style={{ maxWidth: "300px", padding: "10px" }}>
					<>You have unsaved changes. Discard them or continue editing?</>
				</div>
			}
			placement="topRight"
			cancelButtonProps={{ ghost: true }}
			cancelText="Discard changes"
			onCancel={handleFormCancel}
			okText="Continue editing"
			okType="default"
		>
			<Button
				className="ant-btn-secondary"
				// onClick={() => {
				// 	if (formReset) {
				// 		handleFormCancel();
				// 	}
				// }}
				disabled={!submittable}
			>
				Cancel
			</Button>
		</Popconfirm>
	);
};

export default FormButtonCancel;
