import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Popconfirm, Tag } from "antd";
import CustomForm from "../CustomForm";
import Table, { ColumnsType } from "antd/es/table";
import { HomeContext } from "../../containers/Home";
import { useForm } from "antd/es/form/Form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormButtonSave from "../FormButtonSave";
import {
	addLookupItem,
	deleteLookupItem,
	getColumnList,
	getLookupItem,
	updateLookupItem,
} from "../../utils/lookup_list";
import Emitter from "../../utils/emitter";

function TaskConfigurationModal(props: any) {
	const data: any = props.data;
	const context: any = useContext(HomeContext);
	const [formRef] = useForm();

	const [tableColumns, setTableColumns] = useState<ColumnsType<any>>([]);
	const [dataTable, setDataTable] = useState<any[]>([]);

	const [formReset, setFormReset] = useState<boolean>(true);
	const [updated, setUpdated] = useState<boolean>(false);
	const [expandedRowKeys, setExpandRowKey] = useState<any>([]);
	const [filteredDataTable, setFilteredDataTable] = useState<any>(null);
	const [tableHeight, setTableHeight] = useState<any>(null);
	const [ready, setReady] = useState<any>(false);
	const [initialForm, setInitialForm]: any = useState<any>(null);

	const [modalVisible, setModalVisible] = useState(false);

	const [cancelPopover, setCancelPopover] = useState<any>(false);
	const [deletePopover, setDeletePopover] = useState<any>(false);

	const [tempRecord, setTempRecord] = useState<any>(null);
	const [searchRecord, setSearchRecord] = useState<any>([]);

	const open = props.open;
	const setOpen = props.setOpen;
	const phaseID = props.phaseID;
	const taskRecord = props.taskRecord;
	const activeRowKey = props.activeRowKey;
	const handleModalClose = props.handleModalClose;
	const handleFormUpdate = props.handleFormUpdate;

	const setDeliverableRecord = props.setDeliverableRecord;
	const setDeliverableModalOpen = props.setDeliverableModalOpen;
	const extraData = props.extraData;
	useEffect(() => {
		window.addEventListener("resize", () => {
			calculateTableHeight();
		});

		calculateTableHeight();
	}, []);

	useEffect(() => {
		if (searchRecord && tempRecord) {
			let recordIndex = searchRecord.findIndex((item: any) => {
				return item.columnKey === tempRecord.columnKey;
			});
			if (recordIndex !== -1) {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record[recordIndex] = tempRecord;
					setSearchRecord(_record);
				} else {
					let _record = [...searchRecord];
					_record.splice(recordIndex, 1);
					setSearchRecord(_record);
				}
			} else {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record.push(tempRecord);
					setSearchRecord(_record);
				}
			}
		}
	}, [tempRecord]);

	useEffect(() => {
		if (searchRecord?.length > 0) {
			let data: any = [];

			data = [...dataTable];
			let filteredData = data
				?.map((element: any) => {
					let bool = searchRecord.every((item: any) => {
						console.log(element[item.columnKey]);
						if (item.extraKey) {
							console.log(extraData);
							console.log(item.extraKey);
							console.log(extraData[item.extraKey]);
						}
						if (Array.isArray(element[item.columnKey])) {
							return element[item.columnKey].some((arrayItem: any) =>
								arrayItem.toLowerCase().includes(item.value.toLowerCase())
							);
						} else {
							return element[item.columnKey]
								?.toLowerCase()
								.includes(item.value.toLowerCase());
						}
					});
					if (bool) return element;
				})
				.filter((element: any) => element !== null && element !== undefined);
			console.log(filteredData);
			setFilteredDataTable(filteredData);
		} else {
			setFilteredDataTable(null);
		}
	}, [searchRecord]);

	const handleSearch = (record: any) => {
		setTempRecord(record);
	};
	const handleKeyClick = async (record: any, element: any, key: any) => {
		try {
			setDeliverableRecord(record);
			setDeliverableModalOpen(true);
			// await context.handlePageChange();
			// setSideContainerOpen(true);
			// if (tabKey === "well_purpose" || tabKey === "well_status") {
			// 	if (element.dataIndex === "group") {
			// 		setActiveRowKey(key);
			// 		setActiveGroupKey(null);
			// 	} else {
			// 		setActiveRowKey(record?.type_uuid);
			// 		setActiveGroupKey(`${record.group_uuid}-${record.type_uuid}`);
			// 	}
			// } else {
			// 	setActiveGroupKey(null);
			// 	setActiveRowKey(key);
			// }
			// setActiveRecord(record);
			// setFormOption(element.key);
			// resetForm(record);
		} catch {}
	};

	const handleTagClick = async (record: any, element: any, key: any) => {
		if (element.key != activeRowKey) {
			try {
				await context.handlePageChange(key);
				// setSideContainerOpen(true);
				// if (tabKey === "project_tags") {
				// 	setActiveRowKey(record?.tag_uuid);
				// 	setActiveGroupKey(`${record.group_uuid}-${record.tag_uuid}`);
				// } else if (tabKey === "well_purpose" || tabKey === "well_status") {
				// 	setActiveRowKey(record?.type_uuid);
				// 	setActiveGroupKey(`${record.group_uuid}-${record.type_uuid}`);
				// } else {
				// 	setActiveRowKey(key);
				// }
				// setActiveRecord(record);
				// setFormOption(element.key);
				resetForm(record);
			} catch {}
		}
	};

	useEffect(() => {
		setReady(false);
		console.log(props);
		window.addEventListener("resize", () => {
			calculateTableHeight();
		});
		Emitter.emit("loading", true);
		let columns: any = getColumnList(
			"deliverable",
			handleSearch,
			null,
			extraData
		);

		//Fetch Deliverables data based on taskRecord._id
		if (activeRowKey)
			getLookupItem("deliverable", context.currentTenantKey, activeRowKey)
				.then((data: any) => {
					if (columns) {
						console.log(data);
						let role_assignments_data = extraData.role_assignments.map(
							(element: any) => {
								return { label: element.role, value: element.role_id };
							}
						);
						data = data.map((item: any) => {
							let accountable_roles_names =
								role_assignments_data
									.filter((roles: any) =>
										item.accountable_roles.includes(roles.value)
									)
									?.map((obj: any) => obj.label) || [];
							let informed_parties_names =
								role_assignments_data
									.filter((roles: any) =>
										item.informed_parties.includes(roles.value)
									)
									?.map((obj: any) => obj.label) || [];
							let responsible_roles_names =
								role_assignments_data
									.filter((roles: any) =>
										item.responsible_roles.includes(roles.value)
									)
									?.map((obj: any) => obj.label) || [];
							let consultants_names =
								role_assignments_data
									.filter((roles: any) =>
										item.consultants.includes(roles.value)
									)
									?.map((obj: any) => obj.label) || [];

							return {
								...item,
								accountable_roles_names,
								informed_parties_names,
								responsible_roles_names,
								consultants_names,
							};
						});
						console.log(data);
						const renderedColumns = columns?.map((element: any) => {
							if (element.isActive == true && !element.render) {
								element.render = (text: any, record: any) => {
									console.log(record, text);
									const id = record.uuid || record.group_uuid || record._id;
									if (text) {
										if (element.tag) {
											return (
												<Tag
													color={record.color}
													key={record.name + record.group}
													onClick={() => {
														handleTagClick(record, element, id);
													}}
													className="table-clickable-text"
												>
													{text}
												</Tag>
											);
										} else {
											return (
												<a
													onClick={() => {
														console.log(record);
														handleKeyClick(record, element, id);
													}}
													className="table-clickable-text"
												>
													{text}
												</a>
											);
										}
									}
								};
								return element;
							}
							// if (element.isGroup == true) {
							// 	let key = "uuid";
							// 	if (tabKey === "project_tags") {
							// 		key = "tag_uuid";
							// 	} else if (
							// 		tabKey === "well_purpose" ||
							// 		tabKey === "well_status"
							// 	) {
							// 		key = "type_uuid";
							// 	}

							// 	element.onCell = (record: any, rowIndex: any) => ({
							// 		rowSpan:
							// 			record.group == ""
							// 				? 1
							// 				: rowGroup[record.group].id == record[key]
							// 				? rowGroup[record.group].count
							// 				: 0,
							// 	});
							// }
							return element;
						});
						setTableColumns(renderedColumns);
					}
					setDataTable(data);
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {});
		if (taskRecord) {
			resetForm(taskRecord);
		}

		Emitter.emit("loading", false);
	}, [updated, context.currentTenantKey, props?.taskRecord]);

	const calculateTableHeight = () => {
		const table: any = document.getElementById("sub-table-container");
		if (table) {
			setTableHeight(table?.clientHeight - 65 - 64);
			console.log(table?.clientHeight, table?.clientHeight - 65 - 64);
		}
	};

	const resetForm = (values: any = null, reset: any = true) => {
		if (formRef) {
			setFormReset(true);
			reset && formRef.resetFields();
			formRef.setFieldsValue(values ? { ...values } : null);
			setInitialForm(values);
		}
	};

	const handleFormCancel = () => {
		setOpen(false);
		setCancelPopover(false);
		handleModalClose();
		// setSideContainerOpen(false);
		// setFilteredExtraData(null);
		// setActiveRowKey(null);
		// setActiveGroupKey(null);
		// setActiveRecord(null);
		resetForm();
	};

	const handleModalOk = (values: any) => {
		// updateLookupItem(
		// 	tabKey,
		// 	formOption,
		// 	activeRowKey,
		// 	context?.currentTenantKey,
		// 	savedValues,
		// 	initialForm
		// )
		// 	.then((updatedItem: any) => {
		// 		Emitter.emit("alert", {
		// 			type: "success",
		// 			message: "Tag lookup item has been successfully updated.",
		// 			description: "",
		// 			top: true,
		// 			closeable: false,
		// 			timeout: 3000,
		// 		});
		// 		setActiveRecord(updatedItem);
		// 		setSideContainerOpen(true);
		// 		resetForm(updatedItem);
		// 		// setFilteredExtraData(null);
		// 		setUpdated(!updated);
		// 	})
		// 	.catch((error: any) => {
		// 		console.log(error);
		// 		Emitter.emit("alert", {
		// 			type: "error",
		// 			message: "Failed to update. Please try again.",
		// 			description: "",
		// 			top: true,
		// 			closeable: false,
		// 			timeout: 3000,
		// 		});
		// 	})
		// 	.finally(() => {});
		setModalVisible(false);
	};

	const handleModalCancel = () => {
		setModalVisible(false);
		handleModalClose();
	};

	const handleFormSave = (values: any) => {
		// For each values in the form, trim the leading and trailing spaces
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});

		if (activeRowKey) {
			//Set undefined to empty string
			Object.keys(values).forEach((element: any) => {
				if (values[element] == undefined) {
					if (element == "subsidiaries") {
						values[element] = [];
					} else {
						values[element] = null;
					}
				}
			});
			updateLookupItem(
				"task",
				"task",
				activeRowKey,
				context?.currentTenantKey,
				values,
				initialForm
			)
				.then((updatedItem: any) => {
					Emitter.emit("alert", {
						type: "success",
						message: `Task has been successfully updated.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					// setActiveRecord(updatedItem);
					resetForm(values, true);
					setUpdated(!updated);
					handleFormUpdate();
				})
				.catch((error: any) => {
					if (error?.code === 11000) {
						Emitter.emit("alert", {
							type: "error",
							message: `This Task already exists, please ensure the Task ${
								Object.keys(error.keyPattern)[0]
							} is unique. Changes are not saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 6000,
						});
					} else {
						Emitter.emit("alert", {
							type: "error",
							message: "Changes are not saved. Please try again.",
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
					}
				})
				.finally(() => {});
		} else {
			addLookupItem(
				"task",
				null,
				values,
				{ phase_id: phaseID },
				context?.currentTenantKey,
				null
			)
				.then((addedItem: any) => {
					Emitter.emit("alert", {
						type: "success",
						message: `Task has been successfully saved.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});

					// closeModal(); // Close the config modal for adding new data
					// openModal(addedItem); // Open the modal for the recently added data
					// setActiveRowKey(
					// 	addedItem?.uuid ||
					// 		addedItem?.group_uuid ||
					// 		addedItem?.tag_uuid ||
					// 		addedItem?._id
					// );
					// setActiveGroupKey(`${values?.group_uuid}-${addedItem?.uuid}`);
					// setActiveRecord(addedItem);
					handleFormUpdate(addedItem);
					resetForm(values, true);
					setUpdated(!updated);
				})
				.catch((error: any) => {
					if (error.code === 11000) {
						Emitter.emit("alert", {
							type: "error",
							message: `This Task already exists, please ensure the Task ${
								Object.keys(error.keyPattern)[0]
							} is unique. Changes are not saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 6000,
						});
					} else {
						Emitter.emit("alert", {
							type: "error",
							message: "Changes are not saved. Please try again.",
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
					}
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		}
	};

	const handleFormDelete = () => {
		context?.handleFormUpdating(false);
		deleteLookupItem("task", "task", activeRowKey, context?.currentTenantKey)
			.then(() => {
				Emitter.emit("alert", {
					type: "success",
					message: `Task has been successfully deleted.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.catch(() => {
				Emitter.emit("alert", {
					type: "error",
					message: "Changes are not saved. Please try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				handleModalClose();
				handleFormUpdate();
				// setUpdated(!updated);
				// setSideContainerOpen(false);
				// setActiveRowKey(null);
				// setActiveRecord(null);
				// setFilteredExtraData(null);
			});
	};

	return (
		<Modal
			closable={false}
			width={"80%"}
			title={"Task Configuration"}
			open={open}
			classNames={{ header: "generic-header", footer: "generic-footer" }}
			centered
			destroyOnClose
			footer={() => {
				return (
					<div
						className="generic-footer"
						style={{ height: "auto", padding: "0", width: "100%" }}
					>
						<Popconfirm
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							placement="topLeft"
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										Permanently delete this task?
										<br />
										This action cannot be undone.
									</>
								</div>
							}
							open={deletePopover}
							//description="Permanently delete this item? This action cannot be undone."
							onConfirm={handleFormDelete}
							overlayClassName="popconfirm-danger"
							onCancel={() => {
								// Handle cancel action if needed
								setDeletePopover(false);
							}}
							okText="Delete"
							okType="danger"
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							cancelText={<span>Cancel</span>}
							cancelButtonProps={{ ghost: true }}
						>
							<Button
								disabled={!activeRowKey}
								className="ant-btn-secondary"
								style={{ marginRight: "auto" }}
								danger
								onClick={() => {
									setDeletePopover(true);
								}}
							>
								Delete
							</Button>
						</Popconfirm>

						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										You have unsaved changes. Discard them or continue editing?
									</>
								</div>
							}
							open={cancelPopover}
							placement="topRight"
							cancelButtonProps={{ ghost: true }}
							cancelText="Discard changes"
							onCancel={() => {
								setCancelPopover(false);
								// handleFormCancel();
								setTimeout(() => {
									handleFormCancel();
								}, 300);
							}}
							okText="Continue editing"
							okType="default"
							onConfirm={() => {
								setCancelPopover(false);
							}}
						>
							<Button
								className="ant-btn-secondary"
								onClick={() => {
									if (formReset) {
										handleFormCancel();
									} else {
										setCancelPopover(true);
									}
								}}
							>
								Cancel
							</Button>
						</Popconfirm>

						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										This will update tag in all groups. Do you wish to proceed?
									</>
								</div>
							}
							placement="topRight"
							open={modalVisible}
							cancelButtonProps={{ ghost: true }}
							cancelText="Cancel"
							onCancel={handleModalCancel}
							onConfirm={handleModalOk}
							okText="Proceed"
							okType="default"
						>
							<FormButtonSave
								form={formRef}
								handleFormSave={handleFormSave}
								formReset={formReset}
							></FormButtonSave>
						</Popconfirm>
					</div>
				);
			}}
		>
			<div
				style={{
					overflow: "auto",
					height: "50vh",
					display: "flex",
					flexDirection: "row",
					gap: "30px",
					padding: "15px",
				}}
			>
				<div>
					<CustomForm
						setFormReset={setFormReset}
						formRef={formRef}
						tabKey={"task"}
						formOption={"task"}
						activeRowKey={activeRowKey}
					/>
				</div>
				<div
					style={{
						flex: "1",
						height: "100%",
						display: "flex",
						maxWidth: "100%",
						overflow: "hidden",
						flexDirection: "column",
					}}
				>
					{" "}
					<div
						className="generic-table-header"
						style={{ display: "flex", flexDirection: "row" }}
					>
						<span
							style={{
								marginRight: "auto",
								minHeight: "32px",
								display: "flex",
								alignItems: "center",
							}}
						>
							Deliverable directory
						</span>

						<Button
							onClick={() => {
								setDeliverableModalOpen(true);
							}}
							style={{}}
							disabled={!activeRowKey}
						>
							+ Add
						</Button>
					</div>
					<div
						id="sub-table-container"
						style={{ flex: 1, maxHeight: "100%", overflow: "hidden" }}
					>
						<Table
							// rowClassName={(record: any) => {
							// 	return record?.uuid === activeRowKey ||
							// 		record?.code === activeRowKey ||
							// 		record?._id === activeRowKey ||
							// 		`${record.group_uuid}-${record.tag_uuid}` ===
							// 			activeGroupKey ||
							// 		`${record.group_uuid}-${record.type_uuid}` ===
							// 			activeGroupKey ||
							// 		(record.group_uuid === activeRowKey &&
							// 			!record.uuid &&
							// 			!record.code &&
							// 			!record._id)
							// 		? "antd-table-row-active"
							// 		: "";
							// }}
							pagination={{
								defaultPageSize: 20,
								pageSizeOptions: [20, 50, 100],
								showSizeChanger: true,
								position: ["bottomCenter"],
							}}
							columns={tableColumns}
							expandable={{
								expandedRowKeys: expandedRowKeys,
								onExpand: (expand: any, record: any) => {
									let keys = [...expandedRowKeys];
									if (expand) {
										keys.push(record.uuid);
									} else {
										let index = keys.findIndex(
											(item: any) => item == record.uuid
										);
										if (index != -1) {
											keys.splice(index, 1);
										}
									}
									setExpandRowKey(keys);
								},
							}}
							scroll={tableHeight && { y: tableHeight, x: "0px" }}
							dataSource={filteredDataTable || dataTable}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default TaskConfigurationModal;
