/**
 * Main source of the client react system
 * 	- Stores & Create routes & pages
 * 	- Auto navigate logic
 *  - Check logins & tokens
 *  -
 */

import React, { useEffect, useMemo, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
} from "react-router-dom";
import { Alert, ConfigProvider, theme } from "antd";
import Location from "./utils/location";
import Home from "./containers/Home";
import LoginPage from "./containers/LoginPage";
import Overview from "./containers/Overview";
import LoginSuccess from "./services/LoginSuccess";
import jwtDecode from "jwt-decode";
import Profile from "./containers/Profile";
import { initialExtraMenu, initialMainMenu } from "./services/MainMenu";
import { setContainer } from "./utils/containers";
import { addUser, getUser } from "./services/api-server/user";
import Emitter from "./utils/emitter";
import { socket } from "./utils/socket";
import { logout, getAlertStyle } from "./utils/utils";
import { refreshToken } from "./services/api-server/usertoken";
import NoAccess from "./containers/NoAccess";
import { superAdminRole } from "./utils/_exports";
import { getAppRoles, getAppUsers } from "./services/api-server/graphql";
import { GetAntIcon } from "./utils/ant_icons";
import {
	getSpecificTenants,
	getTenantList,
} from "./services/api-server/tenant_management";
import { products as helpCenterProducts } from "./containers/help_center/HelpCenter";
export const Mode = `${process.env.REACT_APP_MODE}`;
export let Tenant = `elemental`;

const { defaultAlgorithm, darkAlgorithm } = theme;

if (localStorage.getItem("theme")) {
	const theme = localStorage.getItem("theme");
	if (theme == "dark") {
		document.body.classList.toggle("dark-mode", true);
	} else if (theme == "light") {
		document.body.classList.toggle("dark-mode", false);
	}
} else {
	localStorage.setItem("theme", "dark");
	document.body.classList.toggle("dark-mode", true);
}

let tokenCheck: any = null;
const restricted = ["/", "loginsuccess", "logout"];

const App = () => {
	let isLogin = false;
	let shouldRedirect = false;
	let currentDate: any = Date.now() / 1000;
	let timeout: any = null;

	const restricted = [
		"",
		"/",
		"/loginsuccess",
		"/loginsuccess/",
		"/logout",
		"/logout/",
	];

	const [user, setUser] = useState<any>(null);
	const [userRole, setUserRole] = useState<any>([]);
	const [menu, setMenu] = useState<[]>([]);
	const [menuRoutes, setMenuRoutes] = useState<[]>([]);
	const [adminMenuRoutes, setAdminMenuRoutes] = useState<[]>([]);
	const [currentLocation, setCurrentLocation] = useState<any>(
		window.location.pathname
	);
	const [accessTokenDecoded, setAccessTokenDecoded] = useState<any>(null);
	const [idTokenDecoded, setIDTokenDecoded] = useState<any>(null);

	const [alert, setAlert] = useState<any>(null);

	const [tenantDataList, setTenantDataList] = useState<any>(null);

	// This is to define the current url location of the user
	// This is mostly use to define
	const onLocationChange = (location: Location) => {
		setCurrentLocation(location.pathname);
	};

	// Quick simple check for sign-ins
	localStorage.removeItem("isLogin");
	if (!restricted.includes(currentLocation)) {
		if (
			(!localStorage.getItem("accessToken") &&
				!localStorage.getItem("idToken")) ||
			localStorage.getItem("accessToken") === "undefined" ||
			localStorage.getItem("idToken") === "undefined"
		) {
			localStorage.removeItem("accessToken");
			localStorage.removeItem("idToken");
			shouldRedirect = true;
		} else {
			isLogin = true;
		}
	}

	// Get user information from mongoDB, and populate user info into the App
	const getUserInfo = (email: any, name: any) => {
		getUser(email)
			.then((data: any) => {
				if (data) {
					setUser(data[0]);
				} else {
					addUser({
						name: name,
						email: email,
					})
						.then((data: any) => {
							setUser(data);
						})
						.catch((error: any) => {
							console.log(error);
						});
				}
			})
			.catch((error: any) => {
				console.log(error);
				logout(true);
			});
	};

	// Emitter controller - Control events within the app itself
	const emitterController = () => {
		Emitter.on("userSaved", (data: any) => {
			getUserInfo(data.email, data.name);
		});

		Emitter.on("alert", (payload: any) => {
			if (payload) {
				if (payload.timeout) {
					if (timeout) {
						clearTimeout(timeout);
						timeout = null;
					}
					timeout = setTimeout(() => {
						setAlert(null);
					}, payload.timeout);
				}
				setAlert({
					type: payload.type,
					message: payload.message,
					description: payload.description,
					top: payload.top,
					closeable: payload.closable,
				});
			} else {
				setAlert(null);
			}
		});
	};

	// Socket Controller - control events with the back-end server and will conduct all socket io messages
	const socketController = () => {
		const socketServer: string = process.env.REACT_APP_SOCKET_SERVER as string;
		// const socketIDToken: any = localStorage.getItem(`${Tenant}:idToken`);

		socket.on("connect", () => {
			setInterval(() => {
				const start = Date.now();

				socket.emit("ping", () => {
					const duration = Date.now() - start;
					// console.log("Latency:", duration + "ms");
				});
			}, 1000);
		});
	};

	// Token Controller - to check initial token expiry and set tokens for user info usage
	const tokenController = (
		email: any,
		idTokenDecoded: any,
		accessTokenDecoded: any
	) => {
		let currentDate = Date.now() / 1000;
		if (idTokenDecoded && accessTokenDecoded) {
			if (currentDate > accessTokenDecoded?.exp - 5 * 60) {
				if (currentDate > accessTokenDecoded?.exp + 30 * 60) {
					logout(true);
				} else {
					//RefreshToken
					refreshToken(email)
						.then((refreshed_token: any) => {
							setAccessTokenDecoded(jwtDecode(refreshed_token));
							Emitter.emit("refreshed", null);
						})
						.catch(() => {
							logout(true);
						});
				}
			}
		} else {
			if (!idTokenDecoded) {
				alert("NO ID TOKEN FOUND");
				logout(true);
			} else if (!accessTokenDecoded) {
				alert("NO ACCESS TOKEN FOUND");
				logout(true);
			} else {
				alert("You have been log out for unknown reasons");
				logout(true);
			}
		}
	};

	// Check Login && Redirect
	useEffect(() => {
		if (!restricted.includes(currentLocation) && !user)
			try {
				const idToken_decoded: any = jwtDecode(
					localStorage.getItem("accessToken") || ""
				);
				const accessToken_decoded: any = jwtDecode(
					localStorage.getItem("idToken") || ""
				);

				emitterController();
				socketController();
				tokenController(
					idToken_decoded.preferred_username,
					idToken_decoded,
					accessToken_decoded
				);
				setAccessTokenDecoded(accessToken_decoded);
				setIDTokenDecoded(idToken_decoded);
				getAppRoles().then((values: any) => {
					console.log(values);
				});
				getAppUsers().then((values: any) => {
					console.log(values);
				});
				getUserInfo(idToken_decoded.preferred_username, idToken_decoded.name);
				setUserRole(idToken_decoded.roles);
			} catch (err: any) {
				logout(true);
			}
	}, [currentLocation]);

	// Interval check for token expiry of users
	useEffect(() => {
		if (tokenCheck) clearInterval(tokenCheck);

		if (idTokenDecoded && accessTokenDecoded)
			tokenCheck = setInterval(() => {
				tokenController(
					idTokenDecoded?.preferred_username,
					idTokenDecoded,
					accessTokenDecoded
				);
			}, 60000);
	}, [accessTokenDecoded, idTokenDecoded]);

	// Load Menus and populate routes
	useEffect(() => {
		if (user) {
			const mainMenu: any = initialMainMenu;
			const extraMenu: any = initialExtraMenu;
			let allMenu: any = [...mainMenu, ...extraMenu];

			const menuRoutes: any = [];
			const adminMenuRoutes: any = [];
			let params: any = { user, userRole, tenantDataList };
			const traverse = (menuItems: any, parentPath: any = "") => {
				menuItems.forEach((item: any) => {
					let route = null;
					if (item.to) {
						if (item.requires_admin) {
							if (params?.userRole?.includes(superAdminRole)) {
								route = (
									<Route
										key={item.key}
										// path={"admin/" + item.to.split("/").pop()}
										path={item.to.split("/").pop()}
										element={setContainer(
											item.container,
											item.propTitle,
											item.key,
											{ ...params, ...item },
											userRole
										)}
									/>
								);
							} else {
								route = (
									<Route
										key={item.key}
										// path={"admin/" + item.to.split("/").pop()}
										path={item.to.split("/").pop()}
										element={
											<NoAccess
												break={true}
												text={
													"Oops, looks like you don't have the authorisation to view this page."
												}
											/>
										}
									/>
								);
							}
						} else {
							if (item.container === "projects") {
								route = (
									<>
										<Route
											key={item.key}
											path={item.to.split("/").pop()}
											element={setContainer(
												item.container,
												item.propTitle,
												item.key,
												{ ...params, ...item, userRole },
												userRole
											)}
										></Route>
										<Route
											key={"projectsOverview"}
											path={item.to.split("/").pop() + "/:projectname"}
											element={
												params?.userRole?.includes(superAdminRole) ? (
													setContainer(
														"projectCampaignOverview",
														"Project Overview",
														"projectCampaignOverview",
														{
															key: "project-menu",
															label: "Project Overview",
															propTitle: "Project Overview",
															container: "projectCampaignOverview",
															icon: GetAntIcon("project"),
															to: `/overview`,
															requires_admin: true,
															...params,
															userRole,
														},
														userRole
													)
												) : (
													<NoAccess
														break={true}
														text={
															"Oops, looks like you don't have the authorisation to view this page."
														}
													/>
												)
											}
										></Route>
										<Route
											key={"addNewProject"}
											path={item.to.split("/").pop() + "/add-new-project"}
											element={
												params?.userRole?.includes(superAdminRole) ? (
													setContainer(
														"projectSettings",
														"Projects",
														"projectSettings",
														{
															key: "project-menu",
															label: "projectSettings",
															propTitle: "projectSettings",
															container: "projectSettings",
															icon: GetAntIcon("project"),
															to: "/projects",
															requires_admin: true,
															...params,
															userRole,
														},
														userRole
													)
												) : (
													<NoAccess
														break={true}
														text={
															"Oops, looks like you don't have the authorisation to view this page."
														}
													/>
												)
											}
										></Route>
										<Route
											key={"projects"}
											path={
												item.to.split("/").pop() +
												"/:projectname/project-settings"
											}
											element={
												params?.userRole?.includes(superAdminRole) ? (
													setContainer(
														"projectSettings",
														"Projects",
														"projectSettings",
														{
															key: "project-menu",
															label: "projectSettings",
															propTitle: "projectSettings",
															container: "projectSettings",
															icon: GetAntIcon("project"),
															to: "/projects",
															requires_admin: true,
															...params,
															userRole,
														},
														userRole
													)
												) : (
													<NoAccess
														break={true}
														text={
															"Oops, looks like you don't have the authorisation to view this page."
														}
													/>
												)
											}
										></Route>
										<Route
											key={"projects"}
											path={
												item.to.split("/").pop() + "/:projectname/:wellname"
											}
											element={
												params?.userRole?.includes(superAdminRole) ? (
													setContainer(
														"WellOverview",
														"wellOverview",
														"WellOverview",
														{
															key: "project-well-overview",
															label: "wellOverview",
															propTitle: "wellOverview",
															container: "wellOverview",
															icon: GetAntIcon("project"),
															to: "/projects",
															requires_admin: true,
															...params,
															userRole,
														},
														userRole
													)
												) : (
													<NoAccess
														break={true}
														text={
															"Oops, looks like you don't have the authorisation to view this page."
														}
													/>
												)
											}
										></Route>
										<Route
											key={"projects"}
											path={
												item.to.split("/").pop() +
												"/:projectname/:wellname/well-settings"
											}
											element={
												params?.userRole?.includes(superAdminRole) ? (
													setContainer(
														"WellSettings",
														"wellSettings",
														"WellSettings",
														{
															key: "project-well-settings",
															label: "WellSettings",
															propTitle: "WellSettings",
															container: "WellSettings",
															icon: GetAntIcon("project"),
															requires_admin: true,
															...params,
															userRole,
														},
														userRole
													)
												) : (
													<NoAccess
														break={true}
														text={
															"Oops, looks like you don't have the authorisation to view this page."
														}
													/>
												)
											}
										></Route>
									</>
								);
							} else if (item.container === "helpCenter") {
								route = (
									<>
										<Route
											key={item.key}
											path={item.to.split("/").pop()}
											element={setContainer(
												item.container,
												item.propTitle,
												item.key,
												{ ...params, ...item, userRole },
												userRole
											)}
										></Route>
										{helpCenterProducts.map((product: any) => {
											return (
												<Route
													key={`help-center-${product.key}`}
													path={item.to.split("/").pop() + `/${product.key}`}
													element={setContainer(
														product.key,
														product.label,
														product.key,
														{
															key: product.key,
															label: product.label,
															propTitle: product.propTitle,
															contents: product.contents,
															...params,
															userRole,
														},
														userRole
													)}
												></Route>
											);
										})}
									</>
								);
							} else {
								route = (
									<Route
										key={item.key}
										path={item.to.split("/").pop()}
										element={setContainer(
											item.container,
											item.propTitle,
											item.key,
											{ ...params, ...item, userRole },
											userRole
										)}
									/>
								);
							}
						}
						if (item.requires_admin) {
							adminMenuRoutes.push({
								item: item,
								route: route,
							});
						} else {
							menuRoutes.push({
								item: item,
								route: route,
							});
						}
					}
					if (item.children && Array.isArray(item.children)) {
						traverse(item.children, parentPath + (item.to || ""));
					}
				});
			};
			traverse(allMenu);
			menuRoutes.push({
				item: null,
				route: <Route path="*" element={<NoAccess />} />,
			});
			adminMenuRoutes.push({
				item: null,
				route: <Route path="*" element={<NoAccess />} />,
			});
			setMenuRoutes(menuRoutes);
			setAdminMenuRoutes(adminMenuRoutes);
		}
	}, [userRole, user]);

	// set tenant role information
	useEffect(() => {
		const setTenant = () => {
			if (user) {
				let tenants = userRole?.filter(
					(element: any) => element !== superAdminRole
				);
				if (tenants?.length > 0 || userRole?.includes(superAdminRole)) {
					Promise.all([
						getSpecificTenants(userRole, tenants),
						getAppRoles(
							tenants,
							userRole?.includes(superAdminRole) ? superAdminRole : null
						),
					])
						.then((data: any) => {
							let tenantArray: any = data[1];
							let databaseTenantArray: any = data[0] || [];
							let combinedArray: any = [];
							tenantArray.forEach((appTenant: any) => {
								const foundObj = databaseTenantArray.find(
									(dbTenant: any) => dbTenant.uuid === appTenant.id
								);

								if (foundObj) {
									combinedArray.push({ ...appTenant, ...foundObj });
								} else {
									combinedArray.push(appTenant);
								}
							});

							combinedArray = combinedArray.sort((a: any, b: any) => {
								let aName = a.name || a.displayName;
								let bName = b.name || b.displayName;
								return aName.localeCompare(bName);
							});
							console.log(combinedArray);
							setTenantDataList(combinedArray);
						})
						.catch((error: any) => {
							console.log(error);
						})
						.finally(() => {
							console.log("Finish");
						});
				}
			}
		};

		setTenant();
		Emitter.on("tenantUpdated", () => {
			setTenant();
		});
	}, [userRole, user]);

	return (
		<ConfigProvider theme={{ hashed: false, algorithm: darkAlgorithm }}>
			<div className="main-page">
				<Router>
					<Location onChange={onLocationChange}></Location>
					{shouldRedirect ? <Navigate to="" /> : <></>}
					<Routes>
						<Route path="" element={<LoginPage />} />
						<Route path="loginsuccess" element={<LoginSuccess />} />
						{isLogin ? (
							<>
								{" "}
								<Route
									path=""
									element={
										<Home
											tenants={tenantDataList}
											userInfo={{ user, userRole }}
											currentLocation={currentLocation}
											menu={[...menuRoutes, ...adminMenuRoutes]}
										/>
									}
								>
									{menuRoutes.map((menuItem: any) => {
										return menuItem.route;
									})}
									{adminMenuRoutes.map((menuItem: any) => {
										return menuItem.route;
									})}
								</Route>
							</>
						) : (
							<></>
						)}
					</Routes>
				</Router>
				{alert && (
					<Alert
						className={
							alert?.top ? "alert-message-box-top" : "alert-message-box"
						}
						type={alert?.type}
						message={alert?.message}
						description={alert?.description}
						showIcon
						closable={alert?.closable || false}
						afterClose={() => setAlert(null)}
						style={{
							fontFamily: "Poppins, sans-serif",
							...getAlertStyle(alert?.type),
						}}
					/>
				)}
			</div>
		</ConfigProvider>
	);
};

export default App;
