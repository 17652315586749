export const projectTest = [
	{
		project_name: "Deepwater Exploration",
		client: "Addax Petroleum",
		status: "Completed",
		project_code: "DEEP001",
		project_manager: "John Smith",
		rig_type: "test",
		spud_date: "2023-01-15",
		regional_manager: "Emily Johnson",
		region: "Gulf of Mexico",
		planned_well_duration: "90",
		water_depth: "3000",
		latlong: [28.7041, -81.6476],
	},
	{
		project_name: "Arctic Drilling Expedition",
		client: "Addax Petroleum",
		status: "In Progress",
		project_code: "ARCT002",
		project_manager: "Sarah Anderson",
		rig_type: "test",
		spud_date: "2023-02-20",
		regional_manager: "Michael Brown",
		region: "Arctic Ocean",
		planned_well_duration: "120",
		water_depth: "4000",
		latlong: [90.0, 135.0],
	},
	{
		project_name: "Bay of Bengal Exploration",
		client: "Borr Drilling",
		status: "New",
		project_code: "BAYB003",
		project_manager: "David Wilson",
		rig_type: "test",
		spud_date: "2023-03-25",
		regional_manager: "Jessica Clark",
		region: "Bay of Bengal",
		planned_well_duration: "75",
		water_depth: "2500",
		latlong: [20.0, 85.0],
	},
	{
		project_name: "Mediterranean Survey",
		client: "Cairn India",
		status: "Completed",
		project_code: "MEDI004",
		project_manager: "Sophia Miller",
		rig_type: "test",
		spud_date: "2023-04-30",
		regional_manager: "Daniel Turner",
		region: "Mediterranean Sea",
		planned_well_duration: "60",
		water_depth: "2000",
		latlong: [35.0, 25.0],
	},
	{
		project_name: "South China Sea Quest",
		client: "Chrysaor",
		status: "In Progress",
		project_code: "SCSQ005",
		project_manager: "Xiao Chen",
		rig_type: "test",
		spud_date: "2023-05-05",
		regional_manager: "Li Wei",
		region: "South China Sea",
		planned_well_duration: "45",
		water_depth: "1500",
		latlong: [10.0, 115.0],
	},
	{
		project_name: "Amazon Basin Drilling",
		client: "Energean Oil and Gas",
		status: "New",
		project_code: "ABD006",
		project_manager: "Anna Petrov",
		rig_type: "test",
		spud_date: "2023-06-10",
		regional_manager: "Dmitri Ivanov",
		region: "Amazon Basin",
		planned_well_duration: "180",
		water_depth: "6000",
		latlong: [-3.4653, -62.2159],
	},
	{
		project_name: "Black Sea Exploration",
		client: "Hess Denmark",
		status: "Completed",
		project_code: "BSE007",
		project_manager: "Oliver Jensen",
		rig_type: "test",
		spud_date: "2023-07-15",
		regional_manager: "Mia Larsen",
		region: "Black Sea",
		planned_well_duration: "105",
		water_depth: "3500",
		latlong: [43.0, 35.0],
	},
	{
		project_name: "Nordic Fjord Expedition",
		client: "Providence Resource Plc",
		status: "In Progress",
		project_code: "NFE008",
		project_manager: "Thomas O'Connor",
		rig_type: "test",
		spud_date: "2023-08-20",
		regional_manager: "Emma Murphy",
		region: "Nordic Fjords",
		planned_well_duration: "135",
		water_depth: "4500",
		latlong: [60.0, 10.0],
	},
	{
		project_name: "Caribbean Treasure Hunt",
		client: "Addax Petroleum",
		status: "New",
		project_code: "CTH009",
		project_manager: "Lucas Fernandez",
		rig_type: "test",
		spud_date: "2023-09-25",
		regional_manager: "Isabella Lopez",
		region: "Caribbean Sea",
		planned_well_duration: "210",
		water_depth: "7000",
		latlong: [12.0, -68.0],
	},
	{
		project_name: "Amazon Deep Dive",
		client: "Addax Petroleum",
		status: "Completed",
		project_code: "ADDE010",
		project_manager: "Eduardo Silva",
		rig_type: "test",
		spud_date: "2023-10-30",
		regional_manager: "Ana Rodriguez",
		region: "Amazon Basin",
		planned_well_duration: "150",
		water_depth: "5000",
		latlong: [-3.4653, -62.2159],
	},
	{
		project_name: "Baltic Explorer",
		client: "Borr Drilling",
		status: "In Progress",
		project_code: "BALE011",
		project_manager: "Lena Eriksson",
		rig_type: "test",
		spud_date: "2023-11-04",
		regional_manager: "Gustav Dahl",
		region: "Baltic Sea",
		planned_well_duration: "90",
		water_depth: "3000",
		latlong: [56.0, 18.0],
	},
	{
		project_name: "Mekong Delta Drift",
		client: "Cairn India",
		status: "New",
		project_code: "MEDD012",
		project_manager: "Trung Nguyen",
		rig_type: "test",
		spud_date: "2023-12-10",
		regional_manager: "Linh Tran",
		region: "Mekong Delta",
		planned_well_duration: "120",
		water_depth: "4000",
		latlong: [10.0, 106.0],
	},
	{
		project_name: "Aegean Odyssey",
		client: "Chrysaor",
		status: "Completed",
		project_code: "AEGE013",
		project_manager: "Sophia Papadopoulos",
		rig_type: "test",
		spud_date: "2024-01-15",
		regional_manager: "Nikos Kostopoulos",
		region: "Aegean Sea",
		planned_well_duration: "60",
		water_depth: "2000",
		latlong: [37.0, 25.0],
	},
	{
		project_name: "Pearl River Expedition",
		client: "CNOOC Int",
		status: "In Progress",
		project_code: "PREX014",
		project_manager: "Hui Li",
		rig_type: "test",
		spud_date: "2024-02-20",
		regional_manager: "Wei Chen",
		region: "Pearl River Delta",
		planned_well_duration: "45",
		water_depth: "1500",
		latlong: [22.0, 113.0],
	},
	{
		project_name: "Red Sea Exploration",
		client: "Energean Oil and Gas",
		status: "New",
		project_code: "RSE015",
		project_manager: "Ahmed Hassan",
		rig_type: "test",
		spud_date: "2024-03-25",
		regional_manager: "Fatima Ali",
		region: "Red Sea",
		planned_well_duration: "180",
		water_depth: "6000",
		latlong: [22.0, 38.0],
	},
	{
		project_name: "Scandinavian Search",
		client: "Hess Denmark",
		status: "Completed",
		project_code: "SCSE016",
		project_manager: "Erik Svensson",
		rig_type: "test",
		spud_date: "2024-04-30",
		regional_manager: "Maja Andersen",
		region: "Scandinavian Fjords",
		planned_well_duration: "105",
		water_depth: "3500",
		latlong: [60.0, 5.0],
	},
	{
		project_name: "Mid-Atlantic Mystery",
		client: "Providence Resource Plc",
		status: "In Progress",
		project_code: "MAM017",
		project_manager: "Daniel Murphy",
		rig_type: "test",
		spud_date: "2024-05-05",
		regional_manager: "Ava Wilson",
		region: "Mid-Atlantic Ridge",
		planned_well_duration: "135",
		water_depth: "4500",
		latlong: [30.0, -42.0],
	},
	{
		project_name: "Great Barrier Reef Quest",
		client: "Addax Petroleum",
		status: "New",
		project_code: "GBRQ018",
		project_manager: "Oliver Baker",
		rig_type: "test",
		spud_date: "2024-06-10",
		regional_manager: "Sophie Turner",
		region: "Coral Sea",
		planned_well_duration: "210",
		water_depth: "7000",
		latlong: [-18.0, 147.0],
	},
	{
		project_name: "Antarctic Expedition",
		client: "Addax Petroleum",
		status: "Completed",
		project_code: "ANTE019",
		project_manager: "Maria Rodriguez",
		rig_type: "test",
		spud_date: "2024-07-15",
		regional_manager: "Carlos Martinez",
		region: "Antarctic Ocean",
		planned_well_duration: "150",
		water_depth: "5000",
		latlong: [-90.0, 0.0],
	},
	{
		project_name: "Mediterranean Mystery",
		client: "Borr Drilling",
		status: "In Progress",
		project_code: "MEDM020",
		project_manager: "Luis Fernandez",
		rig_type: "test",
		spud_date: "2024-08-20",
		regional_manager: "Carmen Sanchez",
		region: "Mediterranean Sea",
		planned_well_duration: "90",
		water_depth: "3000",
		latlong: [35.0, 20.0],
	},
	{
		project_name: "Yellow Sea Search",
		client: "Cairn India",
		status: "New",
		project_code: "YESS021",
		project_manager: "Xing Li",
		rig_type: "test",
		spud_date: "2024-09-25",
		regional_manager: "Mei Wang",
		region: "Yellow Sea",
		planned_well_duration: "120",
		water_depth: "4000",
		latlong: [36.0, 122.0],
	},
	{
		project_name: "Pacific Paradise",
		client: "Chrysaor",
		status: "Completed",
		project_code: "PACP022",
		project_manager: "Kaitlyn Bennett",
		rig_type: "test",
		spud_date: "2024-10-30",
		regional_manager: "Chris Thompson",
		region: "Pacific Ocean",
		planned_well_duration: "105",
		water_depth: "3500",
		latlong: [0.0, -150.0],
	},
];

// export const clientList = [
// 	{ name: "Elemental", icon: null, label: "Elemental", value: "Elemental" },
// 	{
// 		name: "Addax Petroleum",
// 		icon: null,
// 		label: "Addax Petroleum",
// 		value: "Addax Petroleum",
// 	},
// 	{
// 		name: "Borr Drilling",
// 		icon: null,
// 		label: "Borr Drilling",
// 		value: "Borr Drilling",
// 	},
// 	{
// 		name: "Cairn India",
// 		icon: null,
// 		label: "Cairn India",
// 		value: "Cairn India",
// 	},
// 	{ name: "Chrysaor", icon: null, label: "Chrysaor", value: "Chrysaor" },
// 	{ name: "CNOOC Int", icon: null, label: "CNOOC Int", value: "CNOOC Int" },
// 	{
// 		name: "Energean Oil and Gas",
// 		icon: null,
// 		label: "Energean Oil and Gas",
// 		value: "Energean Oil and Gas",
// 	},
// 	{
// 		name: "Hess Denmark",
// 		icon: null,
// 		label: "Hess Denmark",
// 		value: "Hess Denmark",
// 	},
// 	{
// 		name: "Providence Resource Plc",
// 		icon: null,
// 		label: "Providence Resource Plc",
// 		value: "Providence Resource Plc",
// 	},
// ];

export const projectType = [
	// { label: "Consultancy", value: "Consultancy" },
	// { label: "Hybrid/Managed Service", value: "Hybrid/Managed Service" },
	// { label: "Manpower", value: "Manpower" },
	// { label: "Training", value: "Training" },
	// { label: "Well Examination", value: "Well Examination" },
	{ label: "Wells Project Management", value: "Wells Project Management" },
];

export const regionType = [
	{
		label: "Americas",
		value: "Americas",
		Lat: [53.11, 71.5388],
		Long: [-34.83333, -171.7911],
	},
	{
		label: "North Asia",
		value: "North Asia",
		Lat: [5.0, 70.0],
		Long: [25.0, 180.0],
	},
	{
		label: "North Europe",
		value: "North Europe",
		Lat: [36.65, 71.1851],
		Long: [-6.6486, 34.8251],
	},
	{
		label: "South Asia and Middle East",
		value: "South Asia and Middle East",
		Lat: [5.0, 70.0],
		Long: [25.0, 180.0],
	},
	{
		label: "South Europe",
		value: "South Europe",
		Lat: [36.7095, 44.4066],
		Long: [-9.19, 28.2479],
	},
	{
		label: "UK & I",
		value: "UK & I",
		Lat: [51.416, 60.8596],
		Long: [-10.8133, -6.1895],
	},
];
