import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "phases";

export const getPhasesAndTasks = (tenantKey: any, well_project_code: any) => {
	console.log(tenantKey, url, apiURL, well_project_code);
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}/getPhasesTasks`, {
				params: {
					...well_project_code,
				},
				...AuthHeader,
			})
			.then((_data: any) => {
				console.log(_data?.data);
				if (_data?.data) {
					console.log(_data?.data);
					resolve(_data.data);
				} else {
					console.log("fail");
					resolve(null);
				}
			})
			.catch((e: any) => {
				console.log(e);
				reject(e);
			});
	});
};

export const addPhaseItem = (tenantKey: any, data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${tenantKey}/${apiURL}`, data, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const updatePhaseItem = (tenantKey: any, id: any, data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${tenantKey}/${apiURL}`,
				{
					filter: { _id: id },
					data: data,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const deletePhaseItem = (tenantKey: any, id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${url}/${tenantKey}/${apiURL}`, {
				params: {
					_id: id,
				},
				...AuthHeader,
			})
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};
