import React, { useState, useEffect } from "react";
import {
	Dropdown,
	Button,
	Checkbox,
	Input,
	Space,
	Tooltip,
	Popover,
	Typography,
} from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { GetAntIcon } from "../utils/ant_icons";
import { SearchOutlined } from "@ant-design/icons";

const CheckboxDropdown = ({
	options,
	onChange,
	onClear,
	defaultValue,
	Key,
	label,
}: {
	options: any;
	onChange: (selectedValues: CheckboxValueType[]) => void;
	onClear: any;
	defaultValue: any;
	Key: any;
	label: any;
}) => {
	const [selectedCount, setSelectedCount] = useState<any>(null);
	const [searchTerm, setSearchTerm] = useState<any>("");
	const [allOptions, setAllOptions] = useState<any>(options);
	const [filteredOptions, setFilteredOptions] = useState<any>(options);
	const [checked, setChecked] = useState(true);
	const [checkedList, setCheckedList] = useState<any>(defaultValue || []);
	const { Paragraph, Text } = Typography;
	const [ellipsis, setEllipsis] = useState(true);

	useEffect(() => {
		const storedState = localStorage.getItem(Key);
		if (storedState) {
			setCheckedList(JSON.parse(storedState));
		}
	}, [Key]);

	useEffect(() => {
		// Update the filtered options when checkedList changes
		setFilteredOptions(
			options
				.map((option: any) => ({
					...option,
					checked: checkedList?.includes(option.value),
				}))
				.filter((option: any) =>
					option.label.toLowerCase().includes(searchTerm.toLowerCase())
				)
		);
	}, [options, checkedList]);

	// Search function to search in dropdown
	const handleSearch = (term: string) => {
		setSearchTerm(term);
		const filtered = options.filter((option: any) =>
			option.label.toLowerCase().includes(term.toLowerCase())
		);
		setFilteredOptions(filtered);
	};

	// Set filter values and number of values/checkboxes selected, and store filter values
	const handleCheckboxChange = (selectedValues: CheckboxValueType[]) => {
		console.log(selectedValues);
		onChange(selectedValues);
		setCheckedList(selectedValues);
		setSelectedCount(selectedValues.length);
		// localStorage.setItem(Key, JSON.stringify({selectedValues}));
	};

	// Handle specific check box changes rather than the general change
	const handleSpecificCheckboxChange = (e: CheckboxChangeEvent) => {
		let list = checkedList || [];

		let foundIndex = list.findIndex((value: any) => e.target.value === value);
		if (foundIndex === -1) {
			list.push(e.target.value);
		} else {
			list.splice(foundIndex, 1);
		}

		onChange(list);
		setCheckedList(list);
		setSelectedCount(list.length);
	};

	// Reset filter values, number of checkboxes selected, search value of correspoding dropdown
	const handleReset = () => {
		onChange([]);
		setCheckedList([]);
		setSelectedCount(0);

		toggleChecked();
		setChecked(!checked);
		setSearchTerm("");
		setFilteredOptions(options);

		localStorage.removeItem(Key);
	};

	// Render custom dropdown with search column and checkboxes
	const renderDropdown = () => {
		console.log("Rendering dropdown with defaultValue:", defaultValue);
		return (
			<div
				style={{
					maxHeight: "200px",
					overflowY: "auto",
					background: "rgb(31,31,31)",
					width: "100%",
					maxWidth: "300px",
				}}
			>
				<div style={{ padding: "8px", width: "auto", maxWidth: "300px" }}>
					<Input
						placeholder="Search"
						value={searchTerm}
						style={{
							width: "auto",
							minWidth: "200px",
							maxWidth: "300px",
						}}
						prefix={<SearchOutlined />}
						onChange={(e) => handleSearch(e.target.value)}
						onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
							e.currentTarget.blur();
						}}
						allowClear
					/>
				</div>

				<Checkbox.Group
					className="checkbox-dropdown"
					style={{
						display: "flex",
						width: "100%",
						maxWidth: "300px",
						justifyItems: "left",
						flexDirection: "column",
						padding: "0 10px 15px",
					}}
					// defaultValue={defaultValue}
					//options={filteredOptions}
					value={checkedList ? checkedList : defaultValue}
					// onChange={handleCheckboxChange}
				>
					{filteredOptions.map((option: any) => (
						// <Tooltip title={option.label} key={option.value} placement="right">
						<Checkbox
							key={option.value}
							value={option.value}
							className="custom-checkbox"
							title={option.value}
							onChange={handleSpecificCheckboxChange}
						>
							{/* <span className="ellipsis-label">{option.label}</span>  */}
							<Paragraph
								style={ellipsis ? { width: 200 } : undefined}
								ellipsis={ellipsis ? { tooltip: option.label } : false}
							>
								{option.label}
							</Paragraph>
						</Checkbox>
						// </Tooltip>
					))}
				</Checkbox.Group>
			</div>
		);
	};

	const toggleChecked = () => {
		setChecked(!checked);
	};

	// const indeterminate =
	// 	checkedList.length > 0 && checkedList.length < options.length;

	// const onCheckAllChange = (e: CheckboxChangeEvent) => {
	// 	setCheckedList(e.target.checked ? options : []);
	// };

	// Render custom dropdown with reset as footer
	const renderFooter = () => {
		return (
			<div
				style={{
					padding: "8px",
					borderTop: "1px solid #CCC",
					textAlign: "right",
					background: "rgb(31,31,31)",
				}}
			>
				<Button
					type="default"
					onClick={handleReset}
					style={{
						border: "none",
						boxShadow: "none",
						background: "transparent",
					}}
				>
					Reset
				</Button>
			</div>
		);
	};

	return (
		<div className="test">
			<Dropdown
				dropdownRender={() => (
					<div style={{ width: "100%" }}>
						{renderDropdown()}
						{renderFooter()}
					</div>
				)}
				placement="bottomRight"
				trigger={["click"]}
			>
				<Button
					style={{
						border: "none",
						boxShadow: "none",
						background: "transparent",
					}}
				>
					{/* {defaultValue && selectedCount > 0 ? (
            <span>{`${defaultValue} (${selectedCount})`} {GetAntIcon("downarrow")}</span>
          ) : (
            <div>
              <span>{defaultValue}</span>
              <span style={{marginLeft: "8px"}}>{GetAntIcon("downarrow")}</span>
              </div>  )} */}
					<div>
						<span>
							{label}{" "}
							{defaultValue.length > 0 ? `(${defaultValue.length})` : null}{" "}
						</span>
						<span style={{ marginLeft: "8px" }}>{GetAntIcon("downarrow")}</span>
					</div>
				</Button>
			</Dropdown>
		</div>
	);
};

export default CheckboxDropdown;
