import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Popconfirm } from "antd";
import CustomForm from "../CustomForm";
import Table, { ColumnsType } from "antd/es/table";
import { HomeContext } from "../../containers/Home";
import { useForm } from "antd/es/form/Form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormButtonSave from "../FormButtonSave";
import {
	addLookupItem,
	deleteLookupItem,
	getColumnList,
	updateLookupItem,
} from "../../utils/lookup_list";
import Emitter from "../../utils/emitter";

function DeliverableConfigurationModal(props: any) {
	const data: any = props.data;
	const context: any = useContext(HomeContext);
	const [formRef] = useForm();

	const [formReset, setFormReset] = useState<boolean>(true);
	const [updated, setUpdated] = useState<boolean>(false);
	const [initialForm, setInitialForm]: any = useState<any>(null);

	const [modalVisible, setModalVisible] = useState(false);

	const [cancelPopover, setCancelPopover] = useState<any>(false);
	const [deletePopover, setDeletePopover] = useState<any>(false);

	const open = props.open;
	const setOpen = props.setOpen;
	const taskID = props.taskID;
	const deliverableRecord = props.deliverableRecord;
	const activeRowKey = props.activeRowKey;
	const handleModalClose = props.handleModalClose;
	const handleFormUpdate = props.handleFormUpdate;

	const extraData = props.extraData;

	useEffect(() => {
		Emitter.emit("loading", true);

		resetForm(deliverableRecord);

		Emitter.emit("loading", false);
	}, [updated, context.currentTenantKey, props?.deliverableRecord]);

	const resetForm = (values: any = null, reset: any = true) => {
		if (formRef) {
			setFormReset(true);
			reset && formRef.resetFields();
			formRef.setFieldsValue(values ? { ...values } : null);
			setInitialForm(values);
		}
	};

	const handleFormCancel = () => {
		setOpen(false);
		setCancelPopover(false);
		handleModalClose();
		// setSideContainerOpen(false);
		// setFilteredExtraData(null);
		// setActiveRowKey(null);
		// setActiveGroupKey(null);
		// setActiveRecord(null);
		resetForm();
	};

	const handleModalOk = (values: any) => {
		// updateLookupItem(
		// 	tabKey,
		// 	formOption,
		// 	activeRowKey,
		// 	context?.currentTenantKey,
		// 	savedValues,
		// 	initialForm
		// )
		// 	.then((updatedItem: any) => {
		// 		Emitter.emit("alert", {
		// 			type: "success",
		// 			message: "Tag lookup item has been successfully updated.",
		// 			description: "",
		// 			top: true,
		// 			closeable: false,
		// 			timeout: 3000,
		// 		});
		// 		setActiveRecord(updatedItem);
		// 		setSideContainerOpen(true);
		// 		resetForm(updatedItem);
		// 		// setFilteredExtraData(null);
		// 		setUpdated(!updated);
		// 	})
		// 	.catch((error: any) => {
		// 		console.log(error);
		// 		Emitter.emit("alert", {
		// 			type: "error",
		// 			message: "Failed to update. Please try again.",
		// 			description: "",
		// 			top: true,
		// 			closeable: false,
		// 			timeout: 3000,
		// 		});
		// 	})
		// 	.finally(() => {});
		setModalVisible(false);
	};

	const handleModalCancel = () => {
		setModalVisible(false);
		handleModalClose();
	};

	const handleFormSave = (values: any) => {
		// For each values in the form, trim the leading and trailing spaces
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});

		if (activeRowKey) {
			//Set undefined to empty string
			Object.keys(values).forEach((element: any) => {
				if (values[element] == undefined) {
					if (element == "subsidiaries") {
						values[element] = [];
					} else {
						values[element] = null;
					}
				}
			});
			updateLookupItem(
				"deliverable",
				"deliverable",
				activeRowKey,
				context?.currentTenantKey,
				values,
				initialForm
			)
				.then((updatedItem: any) => {
					Emitter.emit("alert", {
						type: "success",
						message: `Deliverable has been successfully updated.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					// setActiveRecord(updatedItem);
					resetForm(values, true);
					setUpdated(!updated);
					handleFormUpdate(updatedItem);
				})
				.catch((error: any) => {
					if (error?.code === 11000) {
						Emitter.emit("alert", {
							type: "error",
							message: `This deliverable already exists, please ensure the deliverable ${
								Object.keys(error.keyPattern)[0]
							} is unique. Changes are not saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 6000,
						});
					} else {
						Emitter.emit("alert", {
							type: "error",
							message: "Changes are not saved. Please try again.",
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
					}
				})
				.finally(() => {});
		} else {
			addLookupItem(
				"deliverable",
				null,
				values,
				{ task_id: taskID },
				context?.currentTenantKey,
				null
			)
				.then((addedItem: any) => {
					Emitter.emit("alert", {
						type: "success",
						message: `Deliverable has been successfully saved.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});

					// closeModal(); // Close the config modal for adding new data
					// openModal(addedItem); // Open the modal for the recently added data
					// setActiveRowKey(
					// 	addedItem?.uuid ||
					// 		addedItem?.group_uuid ||
					// 		addedItem?.tag_uuid ||
					// 		addedItem?._id
					// );
					// setActiveGroupKey(`${values?.group_uuid}-${addedItem?.uuid}`);
					// setActiveRecord(addedItem);
					handleFormUpdate(addedItem);
					resetForm(values, true);
					setUpdated(!updated);
				})
				.catch((error: any) => {
					if (error.code === 11000) {
						Emitter.emit("alert", {
							type: "error",
							message: `This deliverable already exists, please ensure the deliverable ${
								Object.keys(error.keyPattern)[0]
							} is unique. Changes are not saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 6000,
						});
					} else {
						Emitter.emit("alert", {
							type: "error",
							message: "Changes are not saved. Please try again.",
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
					}
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		}
	};

	const handleFormDelete = () => {
		context?.handleFormUpdating(false);
		deleteLookupItem(
			"deliverable",
			"deliverable",
			activeRowKey,
			context?.currentTenantKey
		)
			.then(() => {
				Emitter.emit("alert", {
					type: "success",
					message: `Deliverable has been successfully deleted.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				setDeletePopover(false);
				setTimeout(() => {
					handleModalClose();
					handleFormUpdate();
				}, 100);
			})
			.catch(() => {
				Emitter.emit("alert", {
					type: "error",
					message: "Changes are not saved. Please try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				// setUpdated(!updated);
				// setSideContainerOpen(false);
				// setActiveRowKey(null);
				// setActiveRecord(null);
				// setFilteredExtraData(null);
			});
	};

	return (
		<Modal
			closable={false}
			width={"80%"}
			title={"Deliverable Configuration"}
			open={open}
			classNames={{ header: "generic-header", footer: "generic-footer" }}
			centered
			destroyOnClose
			footer={() => {
				return (
					<div
						className="generic-footer"
						style={{ height: "auto", padding: "0", width: "100%" }}
					>
						<Popconfirm
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							placement="topLeft"
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										Permanently delete this deliverable?
										<br />
										This action cannot be undone.
									</>
								</div>
							}
							open={deletePopover}
							//description="Permanently delete this item? This action cannot be undone."
							onConfirm={handleFormDelete}
							overlayClassName="popconfirm-danger"
							onCancel={() => {
								// Handle cancel action if needed
								setDeletePopover(false);
							}}
							okText="Delete"
							okType="danger"
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							cancelText={<span>Cancel</span>}
							cancelButtonProps={{ ghost: true }}
						>
							<Button
								disabled={!activeRowKey}
								className="ant-btn-secondary"
								style={{ marginRight: "auto" }}
								danger
								onClick={() => {
									setDeletePopover(true);
								}}
							>
								Delete
							</Button>
						</Popconfirm>

						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										You have unsaved changes. Discard them or continue editing?
									</>
								</div>
							}
							open={cancelPopover}
							placement="topRight"
							cancelButtonProps={{ ghost: true }}
							cancelText="Discard changes"
							onCancel={() => {
								setCancelPopover(false);
								// handleFormCancel();
								setTimeout(() => {
									handleFormCancel();
								}, 300);
							}}
							okText="Continue editing"
							okType="default"
							onConfirm={() => {
								setCancelPopover(false);
							}}
						>
							<Button
								className="ant-btn-secondary"
								onClick={() => {
									if (formReset) {
										handleFormCancel();
									} else {
										setCancelPopover(true);
									}
								}}
							>
								Cancel
							</Button>
						</Popconfirm>

						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										This will update tag in all groups. Do you wish to proceed?
									</>
								</div>
							}
							placement="topRight"
							open={modalVisible}
							cancelButtonProps={{ ghost: true }}
							cancelText="Cancel"
							onCancel={handleModalCancel}
							onConfirm={handleModalOk}
							okText="Proceed"
							okType="default"
						>
							<FormButtonSave
								form={formRef}
								handleFormSave={handleFormSave}
								formReset={formReset}
								buttonType={"dropdown-save"}
								labelText="Save and close"
								saveFunction={(values: any) => {
									handleFormSave(values);
									handleModalClose();
									handleFormUpdate();
								}}
							></FormButtonSave>
						</Popconfirm>
					</div>
				);
			}}
		>
			<div
				style={{
					overflow: "auto",
					height: "50vh",
					display: "flex",
					flexDirection: "row",
					gap: "30px",
					padding: "15px",
				}}
			>
				<div style={{ width: "100%", height: "100%" }}>
					<CustomForm
						setFormReset={setFormReset}
						formRef={formRef}
						dataOptions={extraData}
						tabKey={"deliverable"}
						formOption={"deliverable"}
						activeRowKey={activeRowKey}
					/>
				</div>
			</div>
		</Modal>
	);
}

export default DeliverableConfigurationModal;
