import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Card, Divider, Input, Popconfirm, Space, Tabs } from "antd";
import { GetAntIcon } from "../../utils/ant_icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormButtonCancel from "../../components/FormButtonCancel";
import { useForm } from "antd/es/form/Form";
import FormButtonSave from "../../components/FormButtonSave";
import CustomForm from "../../components/CustomForm";
import well_delivered_svg from "../../assets/image/'e' outline well-delivered.svg";
import well_secured_svg from "../../assets/image/'e' outline well-secured.svg";
import disableIcon from "../../assets/image/disable 'e'.png";
import { useNavigate } from "react-router";
import Emitter from "../../utils/emitter";
import { SearchOutlined } from "@ant-design/icons";
import { sendEmail } from "../../services/api-server/email";
import { HomeContext } from "../Home";

const well_delivered_contents = [
	{
		key: "introduction",
		label: "Introduction",
		contents: [
			{
				title: "What is Well-delivered?",
				subContent: [
					{
						paragraph:
							"Well-delivered is a powerful team collaboration platform designed to streamline your work processes. Whether you're working on projects, documenting your team's efforts, or keeping track of progress, our platform is here to simplify and enhance your collaborative experience.",
						type: "paragraph",
					},
					{
						paragraph: "Key features:",
						type: "list",
						list: [
							"Collaboration Hub: Learn how to effectively use Well-delivered as a centralized space for your team to collaborate in real-time, fostering communication and idea exchange",
							"Document Management: Discover the ins and outs of our document management system, ensuring that your project files and documentation are organized and accessible when you need them",
							"Progress Tracking: Explore how to use Well-delivered to keep tabs on project milestones and task completion, enabling you to monitor progress efficiently",
							"Communication Tools: Learn about the various communication tools integrated into Well-delivered to keep your team connected and informed throughout your collaborative projects",
						],
					},
				],
			},
			{
				title: "Our vision",
				subContent: [
					{
						paragraph:
							"Having engineered and managed projects around the world from concept to completion, we understand technical and environmental challenges and how to design a project that safely and efficiently delivers client objectives.",
						type: "paragraph",
					},
					{
						paragraph:
							"Led by your project goals and strategic objectives, we work with our clients to meet project commitments, reach targets and fulfil responsibilities.",
						type: "paragraph",
					},
				],
			},
			{
				title: "Our expertise",
				subContent: [
					{
						paragraph:
							"By harnessing the brightest wells and subsurface engineering talent within the oil and gas sector, Elemental Energies responsibly supports continued exploration, appraisal and development projects and finds intelligent solutions to decommissioning and the energy transition.",
						type: "paragraph",
					},
					{
						paragraph:
							"Our safe, efficient, operations deliver the highest value for our clients.",
						type: "paragraph",
					},
					{
						paragraph:
							"By investing in our people, we ensure they understand and work to the latest global standards.",
						type: "paragraph",
					},
					{
						paragraph:
							"We are committed to supporting the long-term aspirations of the energy transition – providing expert technical engineering and enabling our people to develop their careers with us.",
						type: "paragraph",
					},
				],
			},
		],
	},
	{
		key: "dashboard",
		label: "Dashboard",
		contents: [
			{
				title: "What is dashboard for?",
				subContent: [
					{
						paragraph:
							"The dashboard is your centralized command center for overseeing and managing projects with precision. Navigate through a dynamic interface that provides a visually intuitive map displaying project locations based on clients, bringing clarity and insight to your project management.",
						type: "paragraph",
					},
					{
						paragraph: "Key features:",
						type: "list",
						list: [
							"Project Location Pins: Instantly visualize the geographical distribution of your projects with interactive location pins. Identify clusters, patterns, and project density for strategic decision-making",

							"Client-Centric Organization: Projects are seamlessly organized by clients, offering a client-centric perspective. Effortlessly locate and explore the projects associated with each client on the map",

							"Metadata at a Glance: Dive deep into project details without switching screens. The dashboard showcases essential metadata, providing at-a-glance insights into project status, milestones, and key information",

							"Interactive Mapping: Zoom, pan, and explore. The interactive map allows you to navigate effortlessly, ensuring you have a comprehensive view of your projects' spatial distribution",

							"Efficient Project Management: Leverage the power of spatial intelligence for efficient project management. Identify potential resource allocation challenges, optimize workflows, and make informed decisions with ease",
						],
					},
				],
			},
			{
				title: "How to use",
				subContent: [
					{
						type: "ordered-list",
						list: [
							"Navigate to the client you wish to view",
							"Select on any projects from the side panel",
							"The projects card will expand and the pin would enlarge",
							"User may also expand and collapse the project cards",
							"Clicking on the project title will redirect users to the comprehensive project overview screen, offering a more in-depth perspective.",
						],
					},
				],
			},
		],
	},
	{
		key: "projects",
		label: "Projects",
		contents: [
			{
				title: "What is projects page for?",
				subContent: [
					{
						paragraph:
							"This dynamic space combines the power of a Kanban board and a visually immersive gallery to provide a comprehensive overview of all your projects.",
						type: "paragraph",
					},
					{
						paragraph: "Key features:",
						type: "list",
						list: [
							"Kanban board: Visualize your projects' workflow and progress with our intuitive Kanban board. The board shows the states of the projects you are involved in",
							"Gallery: Thumbnails of your projects, providing a quick way to navigate and manage your project",
							"Project cards: View the projects’ meta data easily by expanding the project cards with a click",
							"Filter function: Take control of your project visibility with our powerful filter function. Easily sort projects based on criteria such as client, status, or due date. Find what you need when you need it, streamlining your project management workflow",
						],
					},
				],
			},
			{
				title: "How to use",
				subContent: [
					{
						type: "ordered-list",
						list: [
							"By default, you will be in the Kanban view, your projects will be sorted into New, In progress, and Completed states.",
							"Click on the card to expand and view the metadata",
							"Click on the project title to view the project’s overview",
							"To switch to Gallery view, click on the ‘Gallery’ tab at the top",
						],
					},
					{
						paragraph: "Filtering function:",
						type: "ordered-list",
						list: [
							"Click on the filter icon to open a panel of filter options",
							"Select the specific filter options that align with your criteria",
							"Select the specific filter items you wish to apply",
							"The board/gallery will be filtered based on the filters you have applied",
							"Multiple filter items/options may be applied on the Kanban board and gallery",
						],
					},
				],
			},
		],
	},
	{
		key: "profile",
		label: "Profile",
		contents: [],
	},
	{
		key: "troubleshooting-faqs",
		label: "Troubleshooting & FAQs",
		contents: [],
	},
	{
		key: "updates-new-features",
		label: "Updates & new features",
		contents: [],
	},
];

const featured_topics = [
	{
		label: "Getting started",
		key: "getting-started",
		icon: "play",
	},
	{
		label: "Roles & permissions",
		key: "roles-permissions",
		icon: "unlock",
	},
	{
		label: "Troubleshooting & FAQs",
		key: "troubleshooting-faqs",
		icon: "question3",
	},
	{
		label: "Updates & new features",
		key: "updates-new-features",
		icon: "star",
	},
	{
		label: "Security & privacy",
		key: "security-privacy",
		icon: "security",
	},
];

export const products = [
	{
		key: "well-delivered",
		label: "Well Delivered",
		propTitle: "Well Delivered",
		image: well_delivered_svg,
		contents: well_delivered_contents,
	},
	{
		key: "well-secured",
		label: "Well Performed",
		propTitle: "Well Performed",
		image: well_secured_svg,
		disable: true,
	},
];

const HelpCenter = (props: any) => {
	const context: any = useContext(HomeContext);
	const navigate = useNavigate();
	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [sideContainerOpen, setSideContainerOpen] = useState(true);
	const [wellDeliveredContents, setWellDeliveredContents] = useState<any>(
		well_delivered_contents
	);
	const initialForm = useRef(null);

	useEffect(() => {
		let initialFormData: any = {
			name: props.params.user.name,
			email: props.params.user.email,
			country_code: props.params.user.country_code,
			phone_number: props.params.user.phone_number,
		};
		initialForm.current = initialFormData;
		formRef.setFieldsValue(initialForm.current);
	});

	const handleFormCancel = () => {
		console.log("cancel");
		formRef.resetFields();
		formRef.setFieldsValue(initialForm.current);
	};

	const handleFormSave = () => {
		const data = formRef.getFieldsValue();
		// Add current time in utc into data
		data["time"] = new Date().toUTCString();
		Emitter.emit("loading", true);
		sendEmail(data)
			.then((res) => {
				Emitter.emit("alert", {
					type: "success",
					message: `Request successfully submitted.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				formRef.resetFields();
				formRef.setFieldsValue(initialForm.current);
			})
			.catch((err) => {
				Emitter.emit("alert", {
					type: "error",
					message: "Request submission failed. Try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				formRef.resetFields();
				formRef.setFieldsValue(initialForm.current);
			})
			.finally(() => {
				Emitter.emit("loading", false);
			});
	};

	const sideContainer = () => {
		return (
			<div
				style={{
					display: sideContainerOpen ? "block" : "none",
					position: "absolute",
					height: "100%",
					width: "30%",
					top: 0,
					right: 0,
					zIndex: "999",
				}}
			>
				<div
					className="side-container"
					style={{
						flex: "1",
						display: "flex",
						flexDirection: "column",
						borderRadius: "3px",
						background: "rgba(31,31,31,0.85)",
						height: "100%",
					}}
				>
					<div
						className="generic-header"
						style={{ height: "50px", justifyContent: "space-between" }}
					>
						<div>Submit a request</div>
						<Button
							type="link"
							onClick={() => {
								console.log("close container");
								setSideContainerOpen(false);
							}}
							style={{ color: "rgba(41, 155, 163, 1)" }}
						>
							Close
						</Button>
					</div>
					<div
						className="generic-content"
						style={{
							flex: "1",
							flexDirection: "column",
							flexWrap: "nowrap",
							maxHeight: "100%",
							overflow: "auto",
							background:
								"linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(8, 124, 133, 0.5) 100%)",
						}}
					>
						<Space direction="vertical" style={{ width: "100%", gap: "15px" }}>
							<Button
								type="link"
								style={{
									color: "rgba(41, 155, 163, 1)",
									paddingLeft: "0",
								}}
							>
								{GetAntIcon("phone")} Contact support team
							</Button>
							<CustomForm
								setFormReset={setFormReset}
								tabKey={"help-center"}
								formRef={formRef}
							/>
						</Space>
					</div>
					<div className="generic-footer" style={{ height: "50px" }}>
						<FormButtonCancel
							form={formRef}
							handleFormCancel={handleFormCancel}
							formReset={formReset}
						/>
						<FormButtonSave
							form={formRef}
							handleFormSave={handleFormSave}
							formReset={formReset}
							labelText="Submit"
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div
			className="generic-container"
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "10px",
				background: "linear-gradient(180deg, #000 0%, #087C85 100%)",
				width: sideContainerOpen ? "70%" : "100%",
				overflow: "auto",
			}}
		>
			{sideContainer()}
			<Space direction="horizontal" style={{ justifyContent: "space-between" }}>
				<span
					style={{
						fontSize: "20px",
						fontWeight: "600",
						color: "rgba(255, 255, 255, 0.85)",
					}}
				>
					{props.params.propTitle}
				</span>
				<Button
					type="primary"
					style={{ display: sideContainerOpen ? "none" : "" }}
					onClick={() => {
						console.log("open side container");
						setSideContainerOpen(true);
					}}
				>
					{GetAntIcon("form")}
				</Button>
			</Space>
			<div
				style={{
					flex: 1,
					display: "flex",
					flexDirection: "column",
					margin: "40px 120px",
					gap: "30px",
				}}
			>
				<Space style={{ gap: 30 }}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="96"
						height="96"
						viewBox="0 0 96 96"
						fill="none"
					>
						<g clip-path="url(#clip0_5561_221906)">
							<path
								d="M47.8377 92.6994C23.0177 92.6894 2.90773 72.5594 2.91773 47.7394C2.91773 45.5994 3.06773 43.4594 3.37773 41.3394H0.527734C-6.45227 90.1794 57.5877 116.689 87.3977 74.5494L84.7677 73.3594C76.3777 85.4794 62.5777 92.7094 47.8377 92.6994Z"
								fill="white"
							/>
							<path
								d="M8.23779 21.0994L10.8478 22.2794C24.9378 1.85936 52.9178 -3.28064 73.3378 10.8094C87.4178 20.5194 94.7578 37.3894 92.2778 54.3094H95.1078C102.018 6.02936 38.4178 -21.5506 8.23779 21.0994Z"
								fill="white"
							/>
							<path
								d="M63.5124 32.5256C62.5671 30.4595 61.2235 28.6028 59.5086 27.0161C56.0246 23.782 51.3996 21.9995 46.4978 21.9995C41.596 21.9995 36.971 23.782 33.487 27.0094C31.7721 28.6028 30.4285 30.4528 29.4832 32.5256C28.4974 34.6862 27.9978 36.9751 27.9978 39.3382V41.1612C27.9978 41.5798 28.3354 41.9174 28.754 41.9174H32.4C32.8186 41.9174 33.1562 41.5798 33.1562 41.1612V39.3382C33.1562 32.6201 39.1383 27.1579 46.4978 27.1579C53.8573 27.1579 59.8394 32.6201 59.8394 39.3382C59.8394 42.0929 58.8604 44.6856 57.0036 46.8462C55.1671 48.9866 52.5744 50.5192 49.7049 51.1674C48.0642 51.5387 46.5856 52.4637 45.539 53.7871C44.4962 55.1059 43.9277 56.7375 43.9254 58.4189V60.5389C43.9254 60.9575 44.2629 61.2951 44.6816 61.2951H48.3275C48.7462 61.2951 49.0838 60.9575 49.0838 60.5389V58.4189C49.0838 57.3588 49.8197 56.4271 50.8392 56.1975C54.7823 55.3063 58.3608 53.1794 60.9197 50.2086C62.2093 48.703 63.2153 47.0285 63.9108 45.2123C64.6332 43.3285 64.9978 41.3502 64.9978 39.3382C64.9978 36.9751 64.4982 34.6794 63.5124 32.5256ZM46.4978 66.6966C44.4115 66.6966 42.7168 68.3913 42.7168 70.4776C42.7168 72.5639 44.4115 74.2586 46.4978 74.2586C48.5841 74.2586 50.2788 72.5639 50.2788 70.4776C50.2788 68.3913 48.5841 66.6966 46.4978 66.6966Z"
								fill="white"
							/>
						</g>
						<defs>
							<clipPath id="clip0_5561_221906">
								<rect width="95.63" height="95.56" fill="white" />
							</clipPath>
						</defs>
					</svg>
					<div
						style={{
							fontSize: "20px",
							fontWeight: "700",
							lineHeight: "25px",
						}}
					>
						How may we help you?
					</div>
				</Space>
				<Input
					placeholder="Search"
					className="input-clearable"
					allowClear
					prefix={<SearchOutlined />}
				/>
				<Divider
					style={{ margin: 0, color: "rgba(125, 125, 125, 1)" }}
					orientation="left"
				>
					Featured topics
				</Divider>
				<div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
					{featured_topics.map((topic: any) => {
						return (
							<Button
								className="ant-btn-secondary"
								icon={GetAntIcon(topic.icon)}
								style={{
									borderRadius: "2px",
								}}
								ghost
							>
								{topic.label}
							</Button>
						);
					})}
				</div>
				<Divider
					style={{ margin: 0, color: "rgba(125, 125, 125, 1)" }}
					orientation="left"
				>
					Products
				</Divider>
				<div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
					{products.map((product: any) => {
						return (
							<Button
								className="ant-btn-secondary"
								style={{
									background: "rgba(20, 20, 20, 0.50)",
									width: "194px",
									height: "145px",
									border: "1px solid #434343",
								}}
								onClick={async () => {
									try {
										await context.handlePageChange();
										// redirect to well-product page product.to
										Emitter.emit("setBreadcrumb", product.key);
										navigate(`/help-center/${product.key}`, {
											replace: true,
										});
									} catch (err) {
										console.log(err);
									}
								}}
								disabled={product.disable}
							>
								<Space direction="vertical">
									<img
										src={product.disable ? disableIcon : product.image}
										style={{ width: "80px", height: "80px" }}
									/>
									<div
										style={{
											fontSize: "16px",
											fontWeight: "600",
											lineHeight: "20px",
										}}
									>
										{product.label}
									</div>
								</Space>
							</Button>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default HelpCenter;
