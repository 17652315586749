import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "projects";

export const getProjects = (tenantKey: any, company_keys: any = []) => {
	return new Promise((resolve, reject) => {
		console.log(url, tenantKey, apiURL);
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getProjectsCode = (tenantKey: any, code: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: {
					project_code: { $regex: code, $options: "i" },
				},
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getProject = (tenantKey: any, code: any) => {
	return new Promise((resolve, reject) => {
		if (!code) {
			resolve(null);
		} else {
			axios
				.get(`${url}/${tenantKey}/${apiURL}/project`, {
					...AuthHeader,
					params: {
						project_code: code,
					},
				})
				.then((_data: any) => {
					if (_data?.data) {
						resolve(_data.data);
					} else {
						resolve(null);
					}
				})
				.catch((e: any) => {
					reject(e);
				});
		}
	});
};

export const addProjectItem = (tenantKey: any, data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${tenantKey}/${apiURL}`, data, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const updateProjectItem = (tenantKey: any, code: any, data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${tenantKey}/${apiURL}`,
				{
					filter: { project_code: code },
					data: data,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const deleteProjectItem = (tenantKey: any, uuid: any) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${url}/${tenantKey}/${apiURL}`, {
				params: {
					uuid: uuid,
				},
				...AuthHeader,
			})
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};
