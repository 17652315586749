import React from "react";
import LookupMissingTooltip from "../components/LookupMissingTooltip";
import { Tag } from "antd";
import { countryList } from "./countryCodes";
import { convertToDMS } from "./utils";
import { info } from "console";

const lookupCases = [
	"region",
	"country",
	"rig_type",
	"client",
	"company",
	"business_unit",
	"well_status",
	"well_status_type",
	"well_purpose",
	"well_purpose_type",
	"operation_type",
	"latitude",
	"longitude",
];

const infoKeySpecialCases = ["latitude", "longitude", "role_assignments"];

// data: a list of lookup data
// baseData: an object of current data where I want to find the lookup
// infoKey: field/property name from base data
export const setLookupData = (
	data: any = [],
	baseData: any,
	infoKey: any,
	propKey: any = null
) => {
	let content: any = (
		<LookupMissingTooltip userRole={data?.userInfo?.userRole} />
	);
	console.log(infoKey, baseData[infoKey]);
	if (
		(!baseData[infoKey] || baseData[infoKey] == "") &&
		!infoKeySpecialCases.includes(infoKey)
	) {
		return "-";
	}
	if (data) {
		let found: any = null;
		switch (infoKey) {
			case "region":
				found = data?.regions?.find((element: any) => {
					return element.uuid === baseData[infoKey];
				});
				if (found) {
					content = found.name;
				}
				break;
			case "country":
				found = countryList?.find((element: any) => {
					return baseData.country === element["alpha-3"];
				});
				if (found) {
					content = found.name;
				} else {
					content = "Aberdeen";
				}
				break;
			case "rig_type":
				if (infoKey == "rig_type" && !baseData[infoKey]) content = "Drilling";
				break;
			case "business_unit":
				found = data?.business_units?.find((element: any) => {
					return element.uuid == baseData[infoKey];
				});
				if (found) {
					content = found.name;
				}
				break;
			case "client":
			case "company":
				found = data?.companies?.find((element: any) => {
					return element.uuid === baseData[infoKey];
				});
				if (found) {
					content = found.name;
				}
				break;
			case "operating_office":
				found = data?.operating_offices?.find((element: any) => {
					return element.uuid === baseData[infoKey];
				});
				if (found) {
					content = found.name;
				}
				break;
			case "project_tags":
				let tags = baseData.project_tags.map((uuid: any) => {
					let found = data?.project_tags?.find((element: any) => {
						return element.uuid == uuid;
					});
					return found;
				});

				return tags.map((tag: any) => {
					if (tag) {
						return (
							<Tag
								color={tag.color}
								key={tag.name}
								style={{ cursor: "default" }}
							>
								{tag.name}
							</Tag>
						);
					}
				});
			case "latitude":
				console.log(baseData);
				let latitudeValue: any = baseData.latlong[0] || null;
				content = convertToDMS(latitudeValue, "latitude");
				break;
			case "longitude":
				console.log(baseData);
				let longtitudeValue: any = baseData.latlong[1] || null;
				content = convertToDMS(longtitudeValue, "longitude");
				break;

			case "role_assignments":
				if (baseData?.[propKey].length > 0) {
					found = data?.[infoKey]?.filter((element: any) => {
						return baseData?.[propKey]?.includes(element.role_id);
					});
					if (found?.length > 0) {
						content = found;
					}
					break;
				} else {
					content = "-";
					break;
				}

			default:
				if (lookupCases.includes(infoKey)) {
					found = data?.[infoKey]?.find((element: any) => {
						let baseID = element.uuid || element._id;
						return baseID === baseData[infoKey];
					});
					if (found) {
						content = found.name;
					}
				} else if (baseData[infoKey]) {
					console.log(infoKey);
					if (infoKey.includes("date")) {
						console.log("HEY DATE HERE");
						var date = new Date(baseData[infoKey]);
						content = date.toLocaleDateString("en-GB", {
							year: "numeric",
							month: "short",
							day: "numeric",
						});
					} else content = baseData[infoKey];
				}

				break;
		}
	}
	return content;
};
