import React, { useEffect, useState, useContext } from "react";
import { HomeContext } from "../../containers/Home";
import CRUDTabTableModal from "../../components/CRUDTabTableModal";

const ProjectOutline = (props: any) => {
	const context: any = useContext(HomeContext);

	const [otherData, setOtherData] = useState<any>(null);

	useEffect(() => {
		setOtherData({ project_code: props.currentProject });
	}, [props.currentProject]);

	return (
		<>
			{props.currentProject !== null && context?.allData && otherData && (
				<CRUDTabTableModal
					items={[
						{
							label: "Well management",
							key: "well_project",
							directoryName: "Well directory",
						},
					]}
					tabKey={"project-outline"}
					otherData={otherData}
					currentProject={props.currentProject}
					headerName={"Settings"}
					buttonType={"dropdown-save"}
					labelText={"Save and manage"}
				/>
			)}
		</>
	);
};

export default ProjectOutline;
