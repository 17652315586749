import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import "../../assets/css/pieChart.css";
/*

*/

interface PieChartProps {
	data: number[]; // Adjust this according to your data structure
	dataLabels?: string[]; // Adjust this according to your data structure
	sliceStatus?: number[]; // [-1,0,1]
	mouseEvent?: any;
	labels?: string[];
	showData?: boolean;
	valueRender?: any;
	mainLabel?: string;
}

const PieChart: React.FC<PieChartProps> = (props: any) => {
	const d3Container: any = useRef(null);
	const colorScale = d3
		.scaleLinear<String>()
		.domain([0, 1, 2, 3, 4])
		.range(["#195b59", "#1f706d", "#258683", "#2c9e9a", "#34beb9"]);
	const [mouseXY, setMouseXY] = React.useState([0, 0]);
	const [componentDimension, setComponentDimension] = React.useState<any>(null);

	const sumAllData = () => {
		// console.log('data',props.data)
		let sum = 0;
		props.data.forEach((d: number) => {
			sum += d;
		});
		return sum;
	};

	const handleMouseLeave = (event: any) => {
		// updateToolTip(null)
	};

	const updateLocalToolTip = (d: any) => {
		// draw a circle under the mouse
		// console.log('updateLocalToolTip',d)
		const svg = d3.select(d3Container.current).select("svg");
		if (d3Container.current !== null && d) {
			svg.select("#basePie").selectAll("#localToolTip").remove();
			svg.select("#basePie").append("g").attr("id", "localToolTip");
			const tooltip = d3.select(d3Container.current).select("#localToolTip");
			const _width = 50;
			const _height = 20;

			const _x = d.x > 0 ? d.x - (_width + 10) : d.x + 10;
			const _y = d.y;
			tooltip
				.append("rect")
				.attr("id", "localToolTip")
				.attr("x", _x)
				.attr("y", _y)
				.attr("width", _width)
				.attr("height", _height)
				.attr("fill", "white")
				.attr("stroke", "black")
				.attr("stroke-width", 0.5);
			tooltip
				.append("text")
				.attr("id", "localToolTipText")
				.attr("x", _x + _width / 2)
				.attr("y", _y + _height / 2)
				.attr("fill", "black")
				.attr("font-size", "0.5em")
				.attr("text-anchor", "middle")
				.text((_d: any) => {
					// console.log('d',d?.data?.data)
					return d?.data?.data;
				});
		}
	};

	useEffect(() => {
		if (d3Container.current && props.data && componentDimension) {
			// Clear the container

			d3.select(d3Container.current).selectAll("*").remove();
			const { data, sliceStatus } = props;
			// width and height should be the parent container width and height
			const width = componentDimension[0];
			const height = componentDimension[1];
			const _radius =
				Math.min(width, height) / 2 - Math.min(width, height) * 0.1;
			const radius = () => {
				if (props.sliceStatus?.length > 0) {
					return _radius * 0.85;
				} else {
					return _radius;
				}
			};
			const innerRadius = _radius - _radius * 0.5;
			const outerRadius = _radius;

			// Create pie generator
			const pie = d3.pie();

			// Create arc generator
			const arc: any = d3.arc().innerRadius(innerRadius).outerRadius(radius);

			// Create donut arc generator
			const donutArc: any = d3.arc().innerRadius(radius).outerRadius(_radius);

			// Create SVG element
			const svg = d3
				.select(d3Container.current)
				.append("svg")
				.attr("width", width)
				.attr("height", height)
				.append("g")
				.attr("id", "basePie")
				.attr("transform", `translate(${width / 2}, ${height / 2})`);

			// Bind data and create one path per pie slice
			d3.select(d3Container.current)
				.select("#basePie")
				.selectAll("#pieSlices")
				.remove();
			const _pieSlices = d3
				.select(d3Container.current)
				.select("#basePie")
				.append("g")
				.attr("id", "pieSlices");

			_pieSlices
				.selectAll("#pieSlices")
				.data(pie(data))
				.enter()
				.append("path")
				.attr("id", (d, i) => `pie${i}`)
				.attr("d", arc)
				.attr("fill", (d, i): any => {
					return colorScale(i);
				})
				.attr("stroke", "white")
				.on("mouseenter", (event, i) => {
					const data: any = i;
					data["type"] = "slice";
					const [x, y] = d3.pointer(event);
					updateLocalToolTip({ x: x, y: y, data: data });
				})
				.on("mouseleave", (event, i) => {
					// console.log('mouseout1')
					// removeLocalToolTip()
				});

			if (props.sliceStatus?.length > 0 && sliceStatus) {
				// Bind all sliceStatus
				d3.select(d3Container.current)
					.select("#basePie")
					.selectAll("#sliceStatus")
					.remove();
				const _slices = d3
					.select(d3Container.current)
					.select("#basePie")
					.append("g")
					.attr("id", "sliceStatus")
					.attr("class", "pie-slice");

				_slices
					.selectAll("#sliceStatus")
					.append("g")
					.data(pie(props.data))
					.enter()
					.append("path")
					.attr("id", (d, i) => `sliceStatus${i}`)
					.attr("d", donutArc)
					.attr("class", (d, i) => {
						const _status: number = sliceStatus[i];
						if (_status < 0) {
							return "late";
						} else if (_status > 0) {
							return "early";
						} else {
							return "on-time";
						}
					})
					.on("mouseenter", (event, i) => {
						const data: any = i;
						data["type"] = "slice";
						const _status = sliceStatus[data.index];
						if (_status < 0) {
							data["data"] = "Overdue";
						} else {
							data["data"] = "Ahead";
						}
						data["data"] = `Red = Overdue Green = Ahead`;
						const [x, y] = d3.pointer(event);
						updateLocalToolTip({ x: x, y: y, data: data });
					})
					.on("mouseleave", (d, i) => {
						// console.log('mouseout')
						// removeLocalToolTip()
					});
			} else {
				// console.log('no sliceStatus')
			}

			if (props.showData === true) {
				// console.log(sumAllData())
				// d3.select('#basePie').selectAll('#showData').remove();
				if (props.mainLabel) {
					// use props.mainLabel as the main label
					let mainLabel: any = props.mainLabel;
					let isSplit = false;
					if (props.mainLabel.split("/").length > 1) {
						isSplit = true;
						mainLabel = props.mainLabel.split("/");
					} else {
						mainLabel = props.mainLabel + " days";
					}
					d3.select(d3Container.current).select("#showData").remove();
					d3.select(d3Container.current)
						.select("#basePie")
						.append("g")
						.attr("id", "showData");
					if (isSplit) {
						svg
							.select("#showData")
							.append("g")
							.attr("id", "centerLabel")
							.append("text")
							.attr("id", "showData")
							//.attr('transform', `translate(${width / 2}, ${height / 2})`)
							.attr("text-anchor", "middle")
							.attr("font-size", "0.5em")
							.attr("fill", "white")
							.attr("font-weight", "bold")
							.attr("dy", "-0.6em")
							.text(() => {
								return `${mainLabel[0]}`;
							});
						// add a solid white line in the middle of the circle
						svg
							.select("#showData")
							.append("g")
							.attr("id", "centerLabel")
							.append("line")
							.attr("id", "showData")
							.attr("x1", -innerRadius)
							.attr("x2", innerRadius)
							.attr("y1", 0)
							.attr("y2", 0)
							.attr("stroke", "white")
							.attr("stroke-width", "1px")
							.attr("stroke-opacity", "1")
							.attr("fill", "none");
						svg
							.selectAll("#showData")
							.append("g")
							.attr("id", "centerLabel")
							.append("text")
							.attr("id", "showData")
							.attr("text-anchor", "middle")
							.attr("font-size", "0.5em")
							.attr("fill", "white")
							.attr("font-weight", "bold")
							.attr("dy", "1.2em")
							.text(() => {
								return `${mainLabel[1]}`;
							});
					} else {
						svg
							.selectAll("#showData")
							.append("g")
							.append("text")
							.attr("id", "showData")
							.attr("text-anchor", "middle")
							.attr("font-size", "0.5em")
							.attr("fill", "white")
							.attr("font-weight", "bold")
							.text(() => {
								return `${mainLabel}`;
							});
					}
				} else {
					d3.select(d3Container.current)
						.select("#basePie")
						.append("g")
						.attr("id", "showData");
					svg
						.selectAll("#showData")
						.append("g")
						.attr("class", "centerLabel")
						.append("text")
						.attr("id", "showData")
						.attr("text-anchor", "middle")
						.attr("font-size", "0.7em")
						.attr("fill", "white")
						.attr("font-weight", "bold")
						.text(() => {
							// if props.valueRender is a function, call it
							if (typeof props.valueRender === "function") {
								return `${props.valueRender(sumAllData())}`;
							} else {
								return `${sumAllData()}`;
							}
						});
				}

				// style the centerLabel
				d3.select(d3Container.current)
					.selectAll("#centerLabel")
					.attr("class", (d, i) => {
						return "x";
					});
			}
		}
	}, [props, componentDimension]);
	useEffect(() => {
		const parentWidth: number = d3Container.current.parentElement.clientWidth;
		const parentHeight: number = d3Container.current.parentElement.clientHeight;
		setComponentDimension([parentWidth, parentHeight]);
		// console.log(parentWidth)
	}, [d3Container.current]);

	return (
		<div
			style={{
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			className="d3-component"
			ref={d3Container}
			onMouseLeave={handleMouseLeave}
		/>
	);
};

export default PieChart;
