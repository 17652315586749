import React, { useEffect } from "react";
import { Space, Input, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const WellDelivered = (props: any) => {
	const [tabKey, setTabKey] = React.useState(props.params.contents[0].key);
	const [tabContents, setTabContents] = React.useState(
		props.params.contents[0].contents
	);

	const handleTabChange = (key: any) => {
		setTabKey(key);
		// Look for the key in the contents array and set the contents
		props.params.contents.map((item: any) => {
			if (item.key === key) {
				setTabContents(item.contents);
			}
		});
	};

	useEffect(() => {
		console.log("1-Tabs", tabKey, tabContents);
	}, [tabKey, tabContents]);

	return (
		<div className="generic-container" style={{ gap: "20px" }}>
			<Space direction="vertical">
				<span
					style={{
						fontSize: "20px",
						fontWeight: "600",
						color: "rgba(255, 255, 255, 0.85)",
					}}
				>
					{props.params.propTitle}
				</span>
			</Space>
			<div
				className="main-container"
				style={{
					flex: 1,
					width: "100%",
					maxHeight: "100%",
					overflow: "hidden",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div
					className="generic-header"
					style={{ height: "50px", justifyContent: "space-between" }}
				>
					Guides
					<Input
						placeholder="Search"
						className="input-clearable"
						style={{ maxWidth: "265px" }}
						allowClear
						prefix={<SearchOutlined />}
					/>
				</div>
				<div
					className="generic-content"
					style={{
						flex: "1",
						gap: "0px",
						maxHeight: "100%",
						overflow: "hidden",
						flexWrap: "nowrap",
					}}
				>
					<Tabs
						style={{ lineHeight: "1.55" }}
						tabPosition="left"
						onChange={handleTabChange}
						activeKey={tabKey}
						items={props.params.contents}
					></Tabs>
					<div
						style={{
							flex: "1",
							height: "100%",
							display: "flex",
							maxWidth: "100%",
							overflow: "auto",
							flexDirection: "column",
							gap: "20px",
						}}
					>
						{tabContents.map((item: any) => {
							return (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										gap: "15px",
										margin: "15px 0px",
									}}
								>
									<span
										style={{
											fontSize: "16px",
											fontWeight: "600",
											lineHeight: "20px",
											borderBottom: "1px solid rgba(125, 125, 125, 1)",
											paddingBottom: "8px",
										}}
									>
										{item.title}
									</span>
									<span
										style={{
											fontSize: "14px",
											fontWeight: "400",
											color: "rgba(255, 255, 255, 0.85)",
											lineHeight: "25px",
										}}
									>
										{item.subContent &&
											item.subContent.map((subItem: any) => {
												switch (subItem.type) {
													case "list":
														return (
															<div>
																{subItem.paragraph}
																<ul style={{ paddingInlineStart: "20px" }}>
																	{subItem.list.map((listItem: any) => {
																		return <li>{listItem}</li>;
																	})}
																</ul>
															</div>
														);
													case "ordered-list":
														return (
															<div>
																{subItem.paragraph}
																<ol style={{ paddingInlineStart: "20px" }}>
																	{subItem.list.map((listItem: any) => {
																		return <li>{listItem}</li>;
																	})}
																</ol>
															</div>
														);
													default:
														return (
															<div>
																<p>{subItem.paragraph}</p>
															</div>
														);
												}
											})}
									</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WellDelivered;
