/**
 * Terra's Dashboard page with the usage of Maps, showing client list and showing project list
 */

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Map from "../components/Overview/Map";
import "../assets/css/overview.css";
import { projectTest } from "../utils/test";
import { Avatar, Badge, Button, Select, Space, Tag, Typography } from "antd";
import { GetAntIcon } from "../utils/ant_icons";
import { getProjects } from "../services/api-server/projects";
import Emitter from "../utils/emitter";
import { getBusinessUnitList } from "../services/api-server/business_unit";
import { getProjectTagList } from "../services/api-server/project_tags";
import { getOperatingOfficeList } from "../services/api-server/operating_office";
import { getRegionList } from "../services/api-server/region";
import { getCompanyList } from "../services/api-server/company";
import { checkPrivilege } from "../utils/utils";
import { countryList } from "../utils/countryCodes";
import { HomeContext } from "./Home";

const { Text } = Typography;

const ring_svg = (
	<svg
		width="102"
		height="102"
		viewBox="0 0 102 102"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.51052 44.2593C-2.21794 71.6324 16.9487 96.8461 44.3218 100.575C63.166 103.142 81.8355 94.8032 92.4966 79.0543L86.2902 76.2425"
			stroke="white"
			stroke-width="1"
			stroke-miterlimit="10"
		/>
		<path
			d="M100.622 57.8642C104.401 30.4988 85.2805 5.25019 57.9113 1.47125C39.0088 -1.13868 20.2694 7.23482 9.60059 23.0536L15.8613 25.8849"
			stroke="white"
			stroke-width="1"
			stroke-miterlimit="10"
		/>
	</svg>
);

const statusOrder: any = {
	"In Progress": 1,
	New: 2,
	Completed: 3,
};

const Overview = (props: any) => {
	const navigate = useNavigate();
	const context: any = useContext(HomeContext);
	let userRole = props?.params?.userRole;

	// const [selectedClient, setSelectedClient] = useState<any>(clientList[0].name);
	const [selectedClient, setSelectedClient] = useState<any>();
	const [selectedProject, setSelectedProject] = useState<any>(null);
	const [projectFilterKey, setProjectFilterKey] = useState<any>(null);
	const [projectList, setProjectList] = useState<any>(null);
	const [projectStatusCount, setProjectStatusCount] = useState<any>({});
	const [allProjects, setAllProjects] = useState<any>([]);
	const [collapsed, setCollapsed] = useState<boolean>(false);

	const [businessUnits, setBusinessUnits] = useState<any>([]);
	const [projectTags, setProjectTags] = useState<any>([]);
	const [operatingOffices, setOperatingOffices] = useState<any>([]);
	const [regions, setRegions] = useState<any>([]);
	const [companies, setCompanies] = useState<any>([]);

	const handleExpandCollapse = () => {
		setCollapsed(!collapsed);
	};

	const handleSelectChange = (value: any) => {
		selectClient(value);
	};

	const filterOption = (
		input: string,
		option?: { label: string; value: string }
	) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const setProjectContent = (currentProject: any, infoKey: any) => {
		let content = "-";
		if (currentProject[infoKey]) {
			if (infoKey === "region") {
				let found = regions.find((element: any) => {
					return element.uuid === currentProject[infoKey];
				});
				if (found) {
					content = found.name;
				}
			} else if (infoKey == "country") {
				let found = countryList.find((element: any) => {
					return currentProject.country === element["alpha-3"];
				});
				if (found) {
					content = found.name;
				} else {
					content = "Aberdeen";
				}
			} else if (infoKey === "client") {
				let found = companies.find((element: any) => {
					return element.uuid === currentProject[infoKey];
				});
				if (found) {
					content = found.name;
				}
			} else if (infoKey == "business_unit") {
				let found = businessUnits.find((element: any) => {
					return element.uuid == currentProject.business_unit;
				});
				if (found) {
					content = found.name;
				}
			} else if (infoKey == "project_tags") {
				let tags = currentProject.project_tags.map((uuid: any) => {
					let found = projectTags.find((element: any) => {
						return element.uuid == uuid;
					});
					return found;
				});

				return tags.map((tag: any) => {
					if (tag) {
						return (
							<Tag
								color={tag.color}
								key={tag.name}
								style={{ cursor: "default" }}
							>
								{tag.name}
							</Tag>
						);
					}
				});
			} else {
				content = currentProject[infoKey];
			}
		}
		return content;
	};

	useEffect(() => {
		if (context?.currentTenantKey) {
			let projects: any = [];
			let companyList: any = [];
			Emitter.emit("loading", true);
			Promise.all([
				getBusinessUnitList().then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});

						setBusinessUnits(data);
					}
				}),

				getProjectTagList().then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.color = item.color;
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						console.log(data);
						setProjectTags(data);
					}
				}),

				getOperatingOfficeList().then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = `${item.name}, ${item.country}`;
							return item;
						});
						setOperatingOffices(data);
					}
				}),

				getProjects(
					context?.currentTenantKey,
					context?.currentTenantData?.company_list
				)
					.then((data: any) => {
						console.log(context);
						console.log(data);
						if (data) {
							data
								.filter((item: any) => !item.is_removed)
								.forEach((item: any) => {
									projects.push(item);
								});
						}
						setAllProjects(projects);
					})
					.catch((error: any) => {
						console.log(error);
					}),

				getRegionList().then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						setRegions(data);
					}
				}),
				// getCompanyList(context?.currentTenantData?.company_list).then(
				getCompanyList([], true).then((_data: any) => {
					if (_data) {
						let data = _data.map((item: any) => {
							item.value = item.uuid;
							item.label = item.name;
							return item;
						});
						companyList = data;
						// setCompanies(data);
					}
				}),
			])
				.then(() => {
					let uniqueCompanies: any = [];
					projects.forEach((project: any) => {
						let foundProject = companyList.find((company: any) => {
							return project.client === company.uuid;
						});
						if (foundProject) {
							let isDuplicate = uniqueCompanies.some(
								(obj: any) => obj.uuid === foundProject.uuid
							);
							if (isDuplicate) {
								console.log("The 'id' already exists in the array.");
							} else {
								console.log(
									"The 'id' doesn't exist in the array. Adding the object."
								);
								uniqueCompanies.push(foundProject);
							}
						}
					});
					if (uniqueCompanies.length > 0) {
						setCompanies(uniqueCompanies);
						setSelectedClient(uniqueCompanies[0].uuid);
					}
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		}
	}, [context.currentTenantKey]);

	useEffect(() => {
		const filterProjectsbyClients = (clientName: any) => {
			let projects = allProjects.filter(
				(element: any) =>
					element.client === clientName || clientName === "Elemental"
			);
			let statusCount: any = {
				inprogress: 0,
				new: 0,
				completed: 0,
			};

			projects
				.sort((a: any, b: any) => statusOrder[a.status] - statusOrder[b.status])
				.map((element: any) => {
					statusCount[element?.status?.replace(" ", "").toLowerCase()]++;
				});

			setProjectList(projects);
			setProjectStatusCount(statusCount);
		};

		setProjectFilterKey(null);
		filterProjectsbyClients(selectedClient);
	}, [selectedClient, allProjects]);

	useEffect(() => {
		let projects: any = [];
		let statusCount: any = {
			inprogress: 0,
			new: 0,
			completed: 0,
		};
		if (projectFilterKey) {
			projects = allProjects.filter(
				(element: any) =>
					(element.client === selectedClient ||
						selectedClient === "Elemental") &&
					element.status === projectFilterKey
			);
		} else {
			projects = allProjects.filter(
				(element: any) =>
					element.client === selectedClient || selectedClient === "Elemental"
			);
		}

		projects
			.sort((a: any, b: any) => statusOrder[a.status] - statusOrder[b.status])
			.map((element: any) => {
				statusCount[element?.status?.replace(" ", "").toLowerCase()]++;
			});

		setProjectList(projects);
	}, [projectFilterKey, selectedClient, allProjects]);

	const handleProjectFiltering = (key: any) => {
		if (projectFilterKey === key) {
			setProjectFilterKey(null);
		} else {
			setProjectFilterKey(key);
		}
	};

	const selectProject = (projectKey: any) => {
		if (selectedProject != projectKey) {
			setSelectedProject(projectKey);

			const selectedElement = document.getElementById(`project-${projectKey}`);

			setTimeout(() => {
				if (selectedElement) {
					selectedElement.scrollIntoView({ behavior: "smooth" });
				}
			}, 1);
		} else {
			setSelectedProject(null);
		}
	};

	const selectClient = (clientName: any) => {
		if (selectedClient != clientName) {
			setSelectedClient(clientName);
			setSelectedProject(null);
		}
	};

	const listClients = () => {
		const projectCount = (clientName: any) => {
			let count = allProjects.filter(
				(element: any) =>
					element.client === clientName || clientName === "Elemental"
			).length;

			return `${count} project${count > 1 ? "s" : ""}`;
		};

		return companies?.map((element: any) => {
			return (
				<Button
					type="link"
					className={
						selectedClient === element.uuid
							? "client-list-button client-list-button-selected"
							: "client-list-button"
					}
					style={{
						width: "109px",
						height: "109px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					shape="circle"
					icon={ring_svg}
					onClick={() => {
						selectClient(element.uuid);
						setSelectedClient(element.uuid);
					}}
				>
					<Space
						direction="vertical"
						size={2}
						style={{ position: "absolute", fontSize: "13px" }}
						styles={{ item: { width: "83px", whiteSpace: "initial" } }}
					>
						{element.icon ? (
							element.icon
						) : (
							<span
								className="client-list-project-name"
								style={{ whiteSpace: "unset" }}
							>
								{element.name}
							</span>
						)}
						<span
							className={
								selectedClient === element.uuid
									? "client-list-project-counter-selected"
									: "client-list-project-counter"
							}
						>
							{projectCount(element.uuid)}
						</span>
					</Space>
				</Button>
			);
		});
	};

	const listProjects = () => {
		const renderStatus = (projectStatus: String) => {
			switch (projectStatus) {
				case "In Progress":
					return (
						<div className="project-status-item status-counter-in-progress">
							<span>In Progress</span>
						</div>
					);

				case "New":
					return (
						<div className="project-status-item status-counter-new">
							<span>New</span>
						</div>
					);

				case "Completed":
					return (
						<div className="project-status-item status-counter-completed">
							<span>Completed</span>
						</div>
					);
			}
		};

		const renderDetail = (project: any) => {
			const isplaceholder = projectTest.find((element: any) => {
				return element.project_name === project.project_name;
			});
			const showDetailInfo = () => {
				const infoKeyList = [
					"project_code",
					"client",
					"region",
					"country",
					"business_unit",
					// "project_manager",
					// "regional_manager",
					// "rig_type",
					// "spud_date",
					// "planned_well_duration",
					// "water_depth",
				];
				return (
					<>
						{infoKeyList.map((infoKey: any) => {
							return (
								<Space direction="vertical" size={0}>
									<span
										style={{
											color: "rgba(255,255,255,0.45)",
											fontSize: "12px",
										}}
									>
										{infoKey
											.split("_")
											.join(" ")
											.replace(/^./, infoKey[0].toUpperCase()) +
											(infoKey.includes("depth") ? " (meters)" : "")}
									</span>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											fontSize: "14px",
											minWidth: "100%",
											width: "0",
										}}
									>
										<Text
											title=""
											style={
												infoKey == "country"
													? {
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
													  }
													: {}
											}
											ellipsis={{
												// onEllipsis: () => {},
												tooltip: (
													<span>{setProjectContent(project, infoKey)}</span>
												),
											}}
										>
											<span
												style={
													infoKey == "country"
														? {
																whiteSpace: "nowrap",
																overflow: "hidden",
																textOverflow: "ellipsis",
														  }
														: {}
												}
											>
												{setProjectContent(project, infoKey)}
											</span>
										</Text>
									</div>
									{/* <span>{setProjectContent(project, infoKey)}</span> */}
								</Space>
							);
						})}
					</>
				);
			};
			const showDetailInfoProjectTags = () => {
				return (
					<div
						style={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
							gap: "8px",
							color: "rgba(255,255,255,0.45)",
							fontSize: "12px",
							minWidth: "100%",
							width: "0",
						}}
					>
						<span>{GetAntIcon("tags")}</span>
						<span>{setProjectContent(project, "project_tags")}</span>
					</div>
				);
			};

			return (
				<div className="project-detailed-container">
					<Space
						direction="vertical"
						style={{ color: "white", width: "100%" }}
						size={15}
					>
						<Space
							direction="vertical"
							size={0}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<span
								style={{
									color: "rgba(255,255,255,0.45)",
									fontSize: "14px",
									lineHeight: "25px",
								}}
							>
								Project title
							</span>
							<div style={{ fontSize: "20px", lineHeight: "25px" }}>
								<span
									className={
										checkPrivilege(userRole, ["project-manager"])
											? "project-title-clickable"
											: ""
									}
									style={{
										whiteSpace: "normal",
										display: "inline",
										overflowWrap: "break-word",
									}}
									onClick={() => {
										if (checkPrivilege(userRole, ["project-manager"])) {
											Emitter.emit("projectName", project.project_name);
											localStorage.setItem(
												"currentProject",
												project.project_code
											);
											navigate(`/projects/${project.project_code}`, {
												replace: false,
												state: { ...project },
											});
										}
									}}
								>
									{project.project_name}
								</span>
							</div>
						</Space>
						<div className="project-status">{renderStatus(project.status)}</div>
						<div className="project-grid-container">{showDetailInfo()}</div>
						<div>{showDetailInfoProjectTags()}</div>
					</Space>
				</div>
			);
		};

		const renderCompact = (project: any) => {
			return (
				<div className="project-mini-container">
					<Space direction="vertical" style={{ color: "white" }} size={9}>
						<Space
							direction="vertical"
							size={0}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<span
								style={{ color: "rgba(255,255,255,0.45)", fontSize: "12px" }}
							>
								Project title
							</span>
							<span
								className={
									checkPrivilege(userRole, ["project-manager"])
										? "project-title-clickable"
										: ""
								}
								style={{
									whiteSpace: "normal",
									display: "inline",
									overflowWrap: "break-word",
								}}
								onClick={() => {
									if (checkPrivilege(userRole, ["project-manager"])) {
										Emitter.emit("projectName", project.project_name);
										localStorage.setItem(
											"currentProject",
											project.project_code
										);
										navigate(`/projects/${project.project_code}`, {
											replace: false,
											state: { ...project },
										});
									}
								}}
							>
								{project.project_name}
							</span>
						</Space>
						<div className="project-status">{renderStatus(project.status)}</div>
					</Space>
				</div>
			);
		};

		if (projectList) {
			return projectList.map((element: any) => {
				return (
					<div
						id={`project-${element.project_code}`}
						className={`project-container`}
						onClick={() => selectProject(element.project_code)}
					>
						{element.project_code === selectedProject ? (
							<>{renderDetail(element)}</>
						) : (
							<>{renderCompact(element)}</>
						)}
					</div>
				);
			});
		} else {
			return null;
		}
	};

	const listProjectCounter = () => {
		return (
			<Space
				className="project-list-status-counter-container"
				align="center"
				direction="horizontal"
				styles={{ item: { cursor: "pointer" } }}
			>
				<div
					id="inprogress"
					style={{ display: "flex", alignItems: "center" }}
					onClick={(ev: any) => {
						handleProjectFiltering("In Progress");
					}}
				>
					{" "}
					<Avatar
						className="status-counter status-counter-in-progress"
						shape="square"
					>
						{projectStatusCount.inprogress}
					</Avatar>
					<span
						className={`${
							projectFilterKey == "In Progress" && "status-counter-active"
						}`}
					>
						{" "}
						In Progress
					</span>
				</div>
				<div
					id="new"
					style={{ display: "flex", alignItems: "center" }}
					onClick={(ev: any) => {
						handleProjectFiltering("New");
					}}
				>
					{" "}
					<Avatar className="status-counter status-counter-new" shape="square">
						{projectStatusCount.new}
					</Avatar>
					<span
						className={`${
							projectFilterKey == "New" && "status-counter-active"
						}`}
					>
						New
					</span>
				</div>
				<div
					id="completed"
					style={{ display: "flex", alignItems: "center" }}
					onClick={(ev: any) => {
						handleProjectFiltering("Completed");
					}}
				>
					{" "}
					<Avatar
						className="status-counter status-counter-completed"
						shape="square"
					>
						{projectStatusCount.completed}
					</Avatar>
					<span
						className={`${
							projectFilterKey == "Completed" && "status-counter-active"
						}`}
					>
						Completed
					</span>
				</div>
			</Space>
		);
	};

	return (
		<div style={{ display: "flex", height: "100%", flexDirection: "column" }}>
			<div
				style={{
					width: "100%",
					height: collapsed ? "52px" : "150px",
					transition: "height 0.3s ease-in-out",
				}}
				className="client-list"
			>
				<Space
					direction={collapsed ? "horizontal" : "vertical"}
					style={{
						width: "100%",
						height: "100%",
						padding: collapsed ? "10px 30px 8px 30px" : "2px 30px 8px 30px",
					}}
					styles={{
						item: { width: "100%", height: "100%", position: "relative" },
					}}
					size={0}
				>
					{collapsed ? (
						<div style={{ alignItems: "center" }}>
							<span style={{ marginRight: "20px", alignItems: "center" }}>
								Currently viewing:
							</span>
							<Select
								getPopupContainer={(trigger: any) => trigger.parentNode}
								showSearch
								style={{ width: "320px", margin: "auto" }}
								optionFilterProp="label"
								filterOption={filterOption}
								onChange={handleSelectChange}
								options={companies}
								defaultValue={selectedClient}
							/>
						</div>
					) : (
						<Space
							direction="horizontal"
							style={{ width: "100%" }}
							styles={{ item: { padding: "10px" } }}
							size={10}
						>
							{listClients()}
						</Space>
					)}
					<Button
						icon={collapsed ? GetAntIcon("downarrow") : GetAntIcon("up")}
						ghost
						style={{
							color: "#FFFFFFD9",
							fontSize: "12px",
							border: "0px",
							float: "right",
							marginTop: "-12px",
							position: "absolute",
							bottom: "0",
							right: "0",
							...(collapsed && { paddingTop: "12px", marginTop: "0" }),
						}}
						onClick={handleExpandCollapse}
					>
						{collapsed ? "Expand" : "Hide"}
					</Button>
				</Space>
			</div>
			<div
				className="map-container"
				style={{ flex: "1", position: "relative" }}
			>
				<Map
					projectList={projectList}
					selectProject={selectProject}
					selectedProject={selectedProject}
				/>
				<div
					className="sub-container"
					style={{
						position: "absolute",
						// backgroundColor: "black",
						zIndex: 401,
						height: "100%",
						width: "470px",
						padding: "10px 30px 10px 0px",
						right: 0,
						top: 0,
						display: "flex",
						alignItems: "center",
					}}
				>
					<div
						className="project-main-container"
						style={{ height: "90%", width: "100%" }}
					>
						<div
							style={{
								backgroundColor: "rgba(255,255,255, 0.45)",
								width: "100%",
								height: "auto",
								maxHeight: "100%",
								padding: "10px",
								display: "flex",
								flexDirection: "column",
							}}
						>
							{listProjectCounter()}
							<Space className="project-list" direction="vertical">
								{listProjects()}
							</Space>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Overview.propTypes = {
	params: PropTypes.any,
};

export default Overview;
