import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "company";

export const getCompanyList = (
	company_keys: any = [],
	isLookup: any = false
) => {
	if (company_keys?.length == 0 && !isLookup) {
		company_keys = [""];
	}
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${apiURL}`, {
				params: {
					uuid: { $in: company_keys },
				},
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addCompanyItem = (data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${apiURL}/addCompany`, data, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const updateCompanyItem = (uuid: any, data: any, prevData: any) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${apiURL}/updateCompany`,
				{
					filter: { uuid: uuid },
					data: data,
					options: { upsert: true, new: true },
					prevData: prevData,
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const deleteCompanyItem = (uuid: any) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${url}/${apiURL}/deleteCompany`, {
				params: {
					uuid: uuid,
				},
				...AuthHeader,
			})
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
