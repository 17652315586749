import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
	Button,
	Form,
	Input,
	InputNumber,
	Modal,
	Popconfirm,
	Popover,
	Select,
	Space,
	Tabs,
	Tag,
	Divider,
} from "antd";
import Emitter from "../utils/emitter";
import { useForm, useWatch } from "antd/es/form/Form";
import "../assets/css/projects.css";
import FormButtonSave from "../components/FormButtonSave";
import FormButtonCancel from "../components/FormButtonCancel";
import { projectTest, projectType, regionType } from "../utils/test";
import { getBusinessUnitList } from "../services/api-server/business_unit";
import { countryCodes, countryList } from "../utils/countryCodes";
import { getProjectTagList } from "../services/api-server/project_tags";
import {
	addProjectItem,
	deleteProjectItem,
	getProject,
	getProjects,
	getProjectsCode,
	updateProjectItem,
} from "../services/api-server/projects";
import { getRolePermissionList } from "../services/api-server/roles_permissions";
import { getAllUsers } from "../services/api-server/user";
import { useLocation, useNavigate } from "react-router";
import { getOperatingOfficeList } from "../services/api-server/operating_office";
import { GetAntIcon } from "../utils/ant_icons";
import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { getRegionList } from "../services/api-server/region";
import { getCompanyList } from "../services/api-server/company";
import LookupMissingTooltip from "../components/LookupMissingTooltip";
import ProjectConfiguration from "./projectTabs/ProjectConfiguration";
import { HomeContext } from "./Home";
import { getTenantSpecificUsers } from "../services/api-server/graphql";
import ProjectOutline from "./projectTabs/ProjectOutline";
import { getOperationTypeList } from "../services/api-server/operation_type";
import CRUDTabTableModal from "../components/CRUDTabTableModal";
import TaskConfigurationModal from "../components/Modal/TaskConfigurationModal";
import DeliverableConfigurationModal from "../components/Modal/DeliverableConfigurationModal";
import NoAccess from "./NoAccess";
import {
	addWellProjectItem,
	getWellProjects,
	updateWellProjectItem,
	deleteWellProjectItem,
	getCampaignBasedWellProjects,
} from "../services/api-server/well_projects";
import CustomForm from "../components/CustomForm";
import { getLookupItem } from "../utils/lookup_list";

// import ProjectDetails from "../projectTabs/ProjectDetails";

const { Option } = Select;

/*
	- currentWellProject is the project code of the current project
*/
function WellSettings(props: any) {
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();
	const [allData, setAllData] = useState<any>({});

	const [projectInfo, setProjectInfo] = useState<any>(null);
	const [wellInfo, setWellInfo] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<any>(true);

	const [phaseTaskRecord, setPhaseTaskRecord] = useState<any>(null);
	const [deliverableRecord, setDeliverableRecord] = useState<any>(null);
	const [taskModalOpen, setTaskModalOpen] = useState<any>(false);
	const [deliverableModalOpen, setDeliverableModalOpen] = useState<any>(false);

	const [allWellProjects, setAllWellProjects] = useState<any>([]);
	const [currentWellProject, setCurrentWellProject] = useState<any>(null);
	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [emptyModalOpen, setEmptyModalOpen] = useState<any>(null);

	const [initialForm, setInitialForm] = useState<any>(null);
	const [popOverVisibility, setPopOverVisibility] = useState<boolean>(false);
	const [dropdownOpen, setDropdownOpen] = useState<any>(false);

	const [otherData, setOtherData] = useState<any>(null);
	const [deliverableExtraData, setDeliverableExtraData] = useState<any>(null);

	const tabList: any = [
		{ label: "Project details", key: "well-project-details" },
		{
			label: "Well programme",
			key: "well-programme",
		},
	];
	const [tabKey, setTabKey] = useState<any>(() => {
		if (sessionStorage.getItem("main-tabkey")) {
			let sessionTabKey: any = sessionStorage.getItem("main-tabkey");
			let found = tabList.find((tab: any) => {
				return tab.key === sessionTabKey;
			});
			if (found) {
				return sessionTabKey;
			} else {
				sessionStorage.setItem("main-tabkey", "well-programme");
				return "well-programme";
			}
		} else {
			sessionStorage.setItem("main-tabkey", "well-programme");
			return "well-programme";
		}
	});

	// const [history, setHistory] = useState<any>([]);
	// const [sideContainerOpen, setSideContainerOpen] = useState<any>(false);

	// Load all the data needed for the form
	useEffect(() => {
		if (context.currentTenantKey && context.allData) {
			if (context.currentTenantKey && context.allData) {
				let project_code: any =
					location?.state?.project_code || location?.pathname?.split("/")[2];
				let well_project_code: any =
					location?.state?.well_project_code ||
					location?.pathname?.split("/")[3];
				console.log("i-debug check project code", well_project_code);

				getProject(context.currentTenantKey, project_code)
					.then((project: any) => {
						let allData: any = project;
						setProjectInfo(project);
						context.setCurrentProject(project);
						getCampaignBasedWellProjects(context.currentTenantKey, {
							project_code,
						})
							.then((data: any) => {
								console.log(data);
								setAllWellProjects(data);

								let foundWellProject = data.find(
									(project: any) =>
										project?.well_project_code === well_project_code
								);

								if (foundWellProject) {
									setWellInfo(foundWellProject);
									context.setCurrentWell(foundWellProject);
									resetForm(foundWellProject);
									setCurrentWellProject(well_project_code);
									setOtherData({
										well_project_code: foundWellProject.well_project_code,
									});
									setDeliverableExtraData({
										...allData,
									});
									setAllData({
										...allData,
									});
								} else {
									setWellInfo(null);
								}
							})
							.catch((error: any) => {
								setWellInfo(null);
							})
							.finally(() => {
								setIsLoading(false);
							});
					})
					.catch(() => {
						setProjectInfo(null);
						setIsLoading(false);
					});
			}
		}
	}, [context.currentTenantKey, context.allData, location.pathname]);

	// Emitter addition
	useEffect(() => {
		Emitter.on("taskModalOpen", (payload: any) => {
			setPhaseTaskRecord(payload);
			setTaskModalOpen(true);
		});
	}, []);

	// Handling Task Modal close
	const handleTaskModalClose = () => {
		setPhaseTaskRecord(null);
	};

	// Force table re-render
	// Have to figure out a way to update just one specific task to the table renderer
	const handleTaskFormUpdate = (payload: any) => {
		setOtherData({ well_project_code: currentWellProject });
		if (payload) {
			setPhaseTaskRecord({ ...phaseTaskRecord, task: payload });
		}
	};

	const handleDeliverableModalClose = () => {
		setDeliverableModalOpen(false);
		setDeliverableRecord(null);
	};

	const handleDeliverableFormUpdate = (payload: any = null) => {
		setOtherData({ well_project_code: currentWellProject });
		//Force re-render to download deliverable data
		setPhaseTaskRecord({
			...phaseTaskRecord,
			task: { ...phaseTaskRecord.task },
		});
		setDeliverableRecord(payload);
	};

	// reset form
	const resetForm = (values: any = null, data: any = null) => {
		let filteredCountries: any = [];
		if (formRef) {
			setFormReset(true);
			formRef.resetFields();
			formRef.setFieldsValue(values);

			setInitialForm(values);
		}
	};

	// Function to sort array of objects alphabetically based on property's name
	const sortData = (data: any) => {
		data.sort((a: any, b: any) => {
			if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			if (a.label.toLowerCase() > b.label.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return data;
	};

	// Handle when project has change / adding a new project
	const handleProjectChange = async (
		key: any = null,
		data: any = null,
		manual: boolean = false
	) => {
		try {
			let projects = data?.projects || allWellProjects;
			await context.handlePageChange();
			Emitter.emit("loading", true);
			setCurrentWellProject(key);
			navigate(`/projects/${projectInfo?.project_code}/${key}/well-settings`);

			//Always set tabKey to project-details when project has changed
			if (manual) {
				setTabKey("well-project-details");
				sessionStorage.removeItem("tabKey");
			}

			if (key == "Add new project" || key == null) {
				Emitter.emit("projectName", "Add new project");
				setTabKey("well-project-details");
				sessionStorage.removeItem("tabKey");
				resetForm(null);
			} else {
				const project = projects.find((project: any) => {
					// Emit the project name to set the breadcrumb
					Emitter.emit("projectName", project.project_name);
					return project.project_code == key;
				});
				resetForm(project, data);
			}
			Emitter.emit("loading", false);
		} catch {}
	};

	const handleTabChange = async (key: any) => {
		try {
			await context.handlePageChange();
			setTabKey(key);
			setFormReset(true);
			sessionStorage.setItem("main-tabkey", key);
			const project = allWellProjects.find((project: any) => {
				return project.well_project_code == currentWellProject;
			});
			resetForm(project, null);
			// setSideContainerOpen(false);
		} catch {}
	};

	// Handle form save
	const handleFormSave = (formValues: any | null = null, type: any = null) => {
		let values = formValues || formRef.getFieldsValue();

		// For each values in the form, trim the leading and trailing spaces
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});

		Emitter.emit("loading", true);

		console.log(currentWellProject);
		values.well_project_code = currentWellProject;

		if (currentWellProject && wellInfo) {
			values.modified_on = Date.now();
			console.log("i-debug data to be updated", {
				"tenant key": context.currentTenantKey,
				"well id": wellInfo._id,
				values,
			});
			updateWellProjectItem(context.currentTenantKey, wellInfo._id, values)
				.then((updatedData: any) => {
					Emitter.emit("alert", {
						type: "success",
						message: "Project details has been successfully updated.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					resetForm(updatedData);

					getWellProjects(context.currentTenantKey)
						.then((_data: any) => {
							console.log("i-debug data after update", _data);
							// 		if (_data) {
							// 			let data = _data
							// 				.filter((item: any) => !item.is_removed)
							// 				.map((item: any) => {
							// 					if (!item.is_removed) {
							// 						item.value = item.project_code;
							// 						item.label = item.project_name;
							// 						return item;
							// 					}
							// 				});
							// 			console.log(data);
							setAllWellProjects(_data);
							// 			setAllData({ ...allData, projects: data });
							// 		}
						})
						.catch((error: any) => {
							console.log(error);
						});
				})
				.catch((error: any) => {
					console.log("i-debug error", error);
					Emitter.emit("alert", {
						type: "error",
						message: "Failed to update project. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					resetForm();
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		}

		// if (currentWellProject != "Add new project" && currentWellProject) {
		// 	values.modified_on = Date.now();
		// 	if (type == "New") {
		// 		addProjectItem(context.currentTenantKey, values)
		// 			.then((data: any) => {
		// 				Emitter.emit("alert", {
		// 					type: "success",
		// 					message: "New project has been successfully added.",
		// 					description: "",
		// 					top: true,
		// 					closeable: false,
		// 					timeout: 3000,
		// 				});
		// 				resetForm(data);
		// 				Emitter.emit("projectName", data.project_name);
		// 				getProjects(
		// 					context.currentTenantKey,
		// 					context?.currentTenantData?.company_list
		// 				).then((_data: any) => {
		// 					if (_data) {
		// 						let data = _data
		// 							.filter((item: any) => !item.is_removed)
		// 							.map((item: any) => {
		// 								if (!item.is_removed) {
		// 									item.value = item.project_code;
		// 									item.label = item.project_name;
		// 									return item;
		// 								}
		// 							});

		// 						setAllWellProjects(data);
		// 						setAllData({
		// 							...allData,
		// 							projects: data,
		// 						});
		// 						setCurrentWellProject(values.project_code);
		// 					}
		// 				});
		// 			})
		// 			.catch((error: any) => {
		// 				if (error.code === 11000) {
		// 					Emitter.emit("alert", {
		// 						type: "error",
		// 						message: `This project already exists, please ensure the project ${
		// 							Object.keys(error.keyPattern)[0]
		// 						} is unique. Changes are not saved.`,
		// 						description: "",
		// 						top: true,
		// 						closeable: false,
		// 						timeout: 4000,
		// 					});
		// 				} else {
		// 					Emitter.emit("alert", {
		// 						type: "error",
		// 						message: "Failed to add new project. Please try again.",
		// 						description: "",
		// 						top: true,
		// 						closeable: false,
		// 						timeout: 3000,
		// 					});
		// 				}
		// 			});
		// 	} else {
		// 		updateProjectItem(context.currentTenantKey, currentWellProject, values)
		// 			.then((updatedData: any) => {
		// 				Emitter.emit("alert", {
		// 					type: "success",
		// 					message: "Project has been successfully updated.",
		// 					description: "",
		// 					top: true,
		// 					closeable: false,
		// 					timeout: 3000,
		// 				});
		// 				console.log(updatedData);
		// 				resetForm(updatedData);
		//
		// 				getProjects(
		// 					context.currentTenantKey,
		// 					context?.currentTenantData?.company_list
		// 				)
		// 					.then((_data: any) => {
		// 						console.log(_data);
		// 						if (_data) {
		// 							let data = _data
		// 								.filter((item: any) => !item.is_removed)
		// 								.map((item: any) => {
		// 									if (!item.is_removed) {
		// 										item.value = item.project_code;
		// 										item.label = item.project_name;
		// 										return item;
		// 									}
		// 								});
		// 							console.log(data);
		// 							setAllWellProjects(data);
		// 							setAllData({ ...allData, projects: data });
		// 						}
		// 					})
		// 					.catch((error: any) => {
		// 						console.log(error);
		// 					});
		// 			})
		// 			.catch((error: any) => {
		// 				Emitter.emit("alert", {
		// 					type: "error",
		// 					message: "Failed to update project. Please try again.",
		// 					description: "",
		// 					top: true,
		// 					closeable: false,
		// 					timeout: 3000,
		// 				});
		// 				resetForm();
		// 			})
		// 			.finally(() => {
		// 				Emitter.emit("loading", false);
		// 			});
		// 	}
		// } else {
		// 	values.created_on = Date.now();
		// 	values.modified_on = Date.now();

		// 	getProjectsCode(context.currentTenantKey, values.project_code)
		// 		.then((data: any) => {
		// 			console.log(data);
		// 			if (data) {
		// 				const latest_code = data
		// 					.filter((element: any) => {
		// 						let str = element.project_code.slice(0, -3);
		// 						return str === values.project_code;
		// 					})
		// 					.map((element: any) => element.project_code)
		// 					.sort((a: any, b: any) => a.localeCompare(b))
		// 					.pop();

		// 				const code = latest_code.slice(-3);
		// 				const inputString = code;
		// 				const intValue = parseInt(inputString, 10); // Parse the string as an integer
		// 				const incrementedValue = intValue + 1; // Increment the integer by 1
		// 				const formattedString = String(incrementedValue).padStart(3, "0"); // Format as a string with leading zeros
		// 				values.project_code += formattedString;
		// 			} else {
		// 				values.project_code += "001";
		// 			}
		// 			addProjectItem(context.currentTenantKey, values)
		// 				.then((data: any) => {
		// 					Emitter.emit("alert", {
		// 						type: "success",
		// 						message: "New project has been successfully added.",
		// 						description: "",
		// 						top: true,
		// 						closeable: false,
		// 						timeout: 3000,
		// 					});
		// 					resetForm(data);
		// 					getProjects(
		// 						context.currentTenantKey,
		// 						context.currentTenantData.company_list
		// 					).then((_data: any) => {
		// 						if (_data) {
		// 							let data = _data
		// 								.filter((item: any) => !item.is_removed)
		// 								.map((item: any) => {
		// 									if (!item.is_removed) {
		// 										item.value = item.project_code;
		// 										item.label = item.project_name;
		// 										return item;
		// 									}
		// 								});
		// 							setAllWellProjects(data);
		// 							setAllData({ ...allData, projects: data });
		// 							setCurrentWellProject(values.project_code);
		// 							Emitter.emit("projectName", values.project_name);
		// 						}
		// 					});
		// 				})
		// 				.catch((error: any) => {
		// 					if (error.code === 11000) {
		// 						Emitter.emit("alert", {
		// 							type: "error",
		// 							message: `This project already exists, please ensure the project ${
		// 								Object.keys(error.keyPattern)[0]
		// 							} is unique. Changes are not saved.`,
		// 							description: "",
		// 							top: true,
		// 							closeable: false,
		// 							timeout: 4000,
		// 						});
		// 					} else {
		// 						Emitter.emit("alert", {
		// 							type: "error",
		// 							message: "Failed to add new project. Please try again.",
		// 							description: "",
		// 							top: true,
		// 							closeable: false,
		// 							timeout: 3000,
		// 						});
		// 					}
		// 				});
		// 		})
		// 		.catch((error: any) => {
		// 			console.log(error);
		// 		})
		// 		.finally(() => {
		// 			Emitter.emit("loading", false);
		// 		});
		// }
	};

	// Handle form delete
	const handleFormDelete = (project_code: any) => {
		Emitter.emit("loading", true);
		context?.handleFormUpdating(false);
		deleteWellProjectItem(context.currentTenantKey, wellInfo._id)
			.then(() => {
				Emitter.emit("alert", {
					type: "success",
					message: "Project has been successfully deleted.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});

				navigate(`/projects/${projectInfo?.project_code}`);
				resetForm();
			})
			.catch(() => {
				Emitter.emit("alert", {
					type: "error",
					message: "Failed to delete project. Please try again.",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.finally(() => {
				Emitter.emit("loading", false);
			});
	};

	// Handle form Cancel
	const handleFormCancel = () => {
		context?.handleFormUpdating(false);
		if (currentWellProject != "Add new project") {
			const project = allWellProjects.find((project: any) => {
				return project.well_project_code == currentWellProject;
			});
			console.log("i-debug cancel", project);
			resetForm(project);
		} else {
			resetForm();
		}
	};

	const tabContent = () => {
		switch (tabKey) {
			case "well-project-details":
				console.log("i-debug data", allData);
				// console.log("i-debug context data", context.allData);
				return (
					<>
						{context?.allData && (
							<div
								style={{
									flex: "1",
									display: "flex",
									flexDirection: "row",
									overflow: "auto",
								}}
							>
								<div
									className="main-container"
									style={{
										width: "70%",
										display: "flex",
										flexDirection: "column",
										borderRadius: "3px",
									}}
								>
									<div className="generic-header" style={{ height: "50px" }}>
										Well project
									</div>
									<div className="generic-content" style={{ flex: "1" }}>
										<div style={{ flex: "1", position: "relative" }}>
											{/* {currentWellProject !== "Add new project" &&
										currentWellProject && (
											<span
												style={{
													position: "absolute",
													right: 30,
													color: "rgba(255, 255, 255, 0.5)",
												}}
											>
												# {currentWellProject}
											</span>
										)} */}
											{/* Add Form here */}
											<CustomForm
												setFormReset={setFormReset}
												handleFormSave={handleFormSave}
												formRef={formRef}
												tabKey={tabKey}
												dataOptions={context.allData}
												activeRowKey={wellInfo._id}
												activeRecord={wellInfo}
											/>
										</div>
									</div>
									<div className="generic-footer" style={{ height: "50px" }}>
										{currentWellProject && (
											<Popconfirm
												style={{
													marginRight: "auto",
													background: "#393939",
												}}
												overlayInnerStyle={{
													background: "#393939",
													maxWidth: "300px",
													padding: "16px",
												}}
												placement="topLeft"
												okText="Delete"
												okType="danger"
												title=""
												icon={
													<ExclamationCircleOutlined
														style={{ color: "grey" }}
													/>
												}
												cancelText={
													<span style={{ color: "white" }}>Cancel</span>
												}
												cancelButtonProps={{ ghost: true }}
												overlayClassName="popconfirm-danger"
												description="Permanently delete this well project? This action cannot be undone."
												onOpenChange={(open: any) => {
													if (!open) {
														setEmptyModalOpen(false);
													}
												}}
												onConfirm={() => {
													setEmptyModalOpen(false);
													handleFormDelete(currentWellProject);
												}}
												onCancel={() => {
													setEmptyModalOpen(false);
												}}
											>
												<Button
													className="ant-btn-secondary"
													style={{ marginRight: "auto" }}
													danger
												>
													Delete
												</Button>
											</Popconfirm>
										)}
										<FormButtonCancel
											form={formRef}
											handleFormCancel={handleFormCancel}
											formReset={formReset}
										></FormButtonCancel>
										<Popover
											open={popOverVisibility}
											onOpenChange={(boolean: any) => {
												// if (checkCode()) {
												// 	setPopOverVisibility(boolean);
												// } else {
												// 	setPopOverVisibility(false);
												// }
											}}
											overlayStyle={{
												maxWidth: "420px",
												background: "rgba(57,57,57,1)",
												borderRadius: "8px",
												// padding: "16px",
											}}
											style={{ background: "transparent" }}
											trigger={"click"}
											content={
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														gap: "8px",
													}}
												>
													<Button
														className="ant-button-default"
														style={{
															color: "white",
															marginRight: "auto",
															border: "1px solid rgba(255, 255, 255, 0.1)",
														}}
														ghost
														onClick={() => {
															setPopOverVisibility(false);
															handleFormSave(null, "New");
														}}
													>
														Create new
													</Button>
													<Button
														className="ant-button-default"
														style={{
															color: "white",
															border: "1px solid rgba(255, 255, 255, 0.1)",
														}}
														ghost
														onClick={() => {
															setPopOverVisibility(false);
														}}
													>
														Cancel
													</Button>
													<Button
														className="ant-button-default"
														onClick={() => {
															setPopOverVisibility(false);
															handleFormSave(null, "code");
														}}
													>
														Proceed
													</Button>
												</div>
											}
											title={
												<Space>
													<div style={{ color: "grey" }}>
														{GetAntIcon("exclamation")}
													</div>
													<span style={{ fontWeight: "400" }}>
														Changing the business unit or client info affects
														the project code. Do you wish to proceed?
													</span>
												</Space>
											}
										>
											<div>
												<FormButtonSave
													form={formRef}
													handleFormSave={handleFormSave}
													formReset={formReset}
												></FormButtonSave>
											</div>
										</Popover>
									</div>
								</div>
							</div>
						)}
					</>
				);

			case "well-programme":
				return (
					<CRUDTabTableModal
						items={[
							{
								label: "Phases and tasks",
								key: "phase",
								directoryName: "Phases and tasks",
								configurationName: "Phase configuration",
							},
						]}
						tabKey={"phase"}
						otherData={otherData}
					/>
				);
			default:
				return <></>;
		}
	};

	return (
		<>
			{projectInfo && wellInfo && !isLoading && (
				<div className="generic-container">
					<Space direction="vertical">
						<Select
							getPopupContainer={(trigger: any) => trigger.parentNode}
							className="title-selector"
							style={{
								fontSize: "20px",
								fontWeight: "600",
								color: "rgba(255, 255, 255, 0.85)",
								width: "auto",
							}}
							options={allWellProjects}
							value={currentWellProject}
							open={dropdownOpen}
							onClick={() => {
								setDropdownOpen(!dropdownOpen);
							}}
							onChange={(key: any) => {
								handleProjectChange(key, null, true);
								setDropdownOpen(false);
							}}
							onBlur={(event: any) => {
								event.preventDefault();
								console.log(event.relatedTarget);
								setDropdownOpen(false);
							}}
							fieldNames={{
								label: "well_project_name",
								value: "well_project_code",
							}}
							dropdownStyle={{ minWidth: "100%", width: "auto" }}
							dropdownRender={(menu) => {
								console.log(menu);
								console.log(allWellProjects);
								console.log(currentWellProject);
								return <>{menu}</>;
							}}
						></Select>

						<Tabs
							activeKey={tabKey}
							onChange={handleTabChange}
							items={tabList}
						></Tabs>
					</Space>
					<div
						style={{
							flex: "1",
							display: "flex",
							flexDirection: "row",
							gap: "20px",
							maxHeight: "100%",
							overflow: "auto",
						}}
					>
						{currentWellProject && tabContent()}
					</div>

					{/* <div className="side-profile-container" style={{ flex: "1" }}></div> */}

					<Modal
						className="empty-modal"
						open={emptyModalOpen}
						closable={false}
						maskClosable={true}
						onCancel={() => {
							setEmptyModalOpen(false);
						}}
						zIndex={1050}
						footer={<></>}
						style={{ background: "transparent" }}
					>
						<></>
					</Modal>
					{phaseTaskRecord?._id && (
						<TaskConfigurationModal
							open={taskModalOpen}
							setOpen={setTaskModalOpen}
							handleFormUpdate={handleTaskFormUpdate}
							handleModalClose={handleTaskModalClose}
							setDeliverableModalOpen={setDeliverableModalOpen}
							setDeliverableRecord={setDeliverableRecord}
							phaseID={phaseTaskRecord?._id}
							taskRecord={phaseTaskRecord?.task}
							activeRowKey={phaseTaskRecord?.task?._id}
							extraData={allData}
						/>
					)}
					<DeliverableConfigurationModal
						open={deliverableModalOpen}
						setOpen={setDeliverableModalOpen}
						handleFormUpdate={handleDeliverableFormUpdate}
						handleModalClose={handleDeliverableModalClose}
						taskID={phaseTaskRecord?.task?._id}
						deliverableRecord={deliverableRecord}
						activeRowKey={deliverableRecord?._id}
						extraData={deliverableExtraData}
					/>
				</div>
			)}
			{!projectInfo && !isLoading && (
				<>
					<NoAccess text={"This project does not exist."} />
				</>
			)}
			{!wellInfo && projectInfo && !isLoading && (
				<>
					<NoAccess text={"This well does not exist."} />
				</>
			)}
		</>
	);
}

WellSettings.propTypes = {
	params: PropTypes.any,
};

export default WellSettings;
