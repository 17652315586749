import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "project-tags";

export const getProjectTagList = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${apiURL}`, {
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addProjectTagItem = (data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${apiURL}/addTag`, data, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const updateProjectTagItem = (uuid: any, data: any, prevData: any) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${apiURL}/updateTag`,
				{
					filter: { uuid: uuid },
					data: data,
					options: { upsert: true, new: true },
					prevData: prevData,
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const deleteProjectTagItem = (uuid: any) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${url}/${apiURL}/deleteTag`, {
				params: {
					uuid: uuid,
				},
				...AuthHeader,
			})
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
