import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { HomeContext } from "./Home";
import { useLocation, useNavigate } from "react-router";
import { Button, Divider, Empty, Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { GetAntIcon } from "../utils/ant_icons";
import CheckboxDropdown from "../components/CheckboxDropdown";
import { checkPrivilege } from "../utils/utils";
import squareHehe from "../assets/image/square hehe.svg";
import { setLookupData } from "../utils/lookupFinder";
import Emitter from "../utils/emitter";
import { getCampaignBasedWellProjects } from "../services/api-server/well_projects";
import NoAccess from "./NoAccess";
import { getPhasesAndTasks } from "../services/api-server/phases";

import completedSvg from "../assets/image/completed.svg";
import blockedSvg from "../assets/image/blocked.svg";
import loadingSvg from "../assets/image/loading.svg";
import newSvg from "../assets/image/new.svg";
import elementalSvg from "../assets/image/elemental 'e'.svg";
import { getProject } from "../services/api-server/projects";
// import {
// 	getDeliverables,
// 	getDeliverablesList,
// } from "../services/api-server/deliverables";
const WellTemplateExample: any = [
	{ label: "Well name", key: "well_project_name" },
	{ label: "Operation type", key: "operation_type" },
	{ label: "Expected end date", key: "expected_end_date" },
	{ label: "Well UID", key: "well_project_code" },
	{ label: "Planned start date", key: "planned_start_date" },
	{
		label: "Est. duration (days)",
		key: "estimated_duration_days",
		suffix: "days",
	},
];

const WellDetailsExample: any = [
	{ label: "Location", key: "location" },
	{ label: "Latitude", key: "latitude" },
	{ label: "Longitude", key: "longitude" },
	{ label: "Block", key: "block" },
	{ label: "Field", key: "field" },
	{ label: "Platform", key: "platform" },
	{ label: "Water depth", key: "water_depth", suffix: "meters" },
	{ label: "License", key: "license" },
	{ label: "Purpose", key: "well_purpose" },
	{ label: "Status", key: "well_status" },
	{ label: "Project template", key: "project_template" },
];

const tempWellList = [
	{
		well_project_name: "Well 1",
		well_project_code: "Code24A",
		planned_start_date: "Date A",
		estimated_duration_days: "30 days",
		operation_type: "Drilling",
		well_project_comments: "Some comments",
		location: "On Shore",
		latlong: ["50", "50"],
		block: "A",
		field: "C",
		platform: "Platform ABCDEF ANSHA AJS",
		water_depth: "300 meter",
		license: "License C",
		purpose: "Appraisal",
		purpose_type: "Confirmed",
		status: "Active",
		status_type: "Injecting",
		well_comments: "some well comments",
		project_template: "Template A",
		created_on: "Creation Date A",
		modified_on: "Modified Date B",
		is_removed: "false",
	},
];

const kanbanStatusExample: any = [
	"Concept",
	"Select",
	"Design",
	"Execute",
	"Evaluate",
];

function WellOverview(props: any) {
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();
	const [wellInfo, setWellInfo] = useState<any>(null);
	const [wellTitle, setWellTitle] = useState<any>(null);
	const [projectInfo, setProjectInfo] = useState<any>(null);
	const [projectTitle, setProjectTitle] = useState<any>(null);
	const [wellDetailsExpand, setWellDetailsExpand] = useState<any>(false);
	const [activePage, setActivePage] = useState<any>("kanban");
	const [filterMode, setFilterMode] = useState<boolean>(false);
	const [descMode, setDescMode] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<any>("");

	const [phaseList, setPhaseList] = useState<any>(null);
	const [taskList, setTaskList] = useState<any>(null);
	const [filteredTaskList, setFilteredTaskList] = useState<any>(null);

	const [isLoading, setIsLoading] = useState<any>(true);

	const [selectedFilters, setSelectedFilters] = useState({
		assigned_to: [],
		status: [],
	});

	const setWellContent = (currentWellData: any, infoKey: any) => {
		let content = "-";
		if (
			currentWellData[infoKey] ||
			infoKey == "latitude" ||
			infoKey == "longitude"
		) {
			content = setLookupData(context.allData, currentWellData, infoKey);
		} else if (infoKey === "expected_end_date") {
			if (
				currentWellData?.planned_start_date &&
				currentWellData?.estimated_duration_days
			) {
				var date = new Date(currentWellData.planned_start_date);
				date.setDate(date.getDate() + currentWellData?.estimated_duration_days);
				// change the date format to "1 Mar 2024"
				content = date.toLocaleDateString("en-GB", {
					year: "numeric",
					month: "short",
					day: "numeric",
				});
			} else {
				content = "-";
			}
		}
		return content;
	};

	useEffect(() => {
		let filteredTaskList = null;
		if (searchValue != "") {
			console.log(taskList, searchValue);
			let filteredTask: any = {};
			Object.keys(taskList)?.forEach((taskNumber: any) => {
				filteredTask[taskNumber] = taskList?.[taskNumber]?.filter(
					(task: any) =>
						task?.task_name
							?.toLowerCase()
							.includes(searchValue.toLowerCase()) ||
						task.task_number === searchValue
				);
			});
			filteredTaskList = filteredTask;
		} else {
			filteredTaskList = taskList;
		}
		// const check = Object.keys(selectedFilters).every(
		// 	(filterKey: any) => selectedFilters[filterKey].length > 0
		// );
		// if (check) {
		// 	filteredTaskList = filteredTaskList.filter((well: any) =>
		// 		Object.keys(selectedFilters).every((filterKey: any) => {
		// 			if (selectedFilters[filterKey].length == 0) {
		// 				return true;
		// 			}
		// 			return selectedFilters[filterKey].includes(well[filterKey]);
		// 		})
		// 	);
		// }

		setFilteredTaskList(filteredTaskList);
	}, [searchValue, selectedFilters]);

	useEffect(() => {
		if (context.currentTenantKey && context.allData) {
			let project_code: any =
				location?.state?.project_code || location?.pathname?.split("/")[2];
			let well_project_code: any =
				location?.state?.well_project_code || location?.pathname?.split("/")[3];

			getProject(context.currentTenantKey, project_code)
				.then((project: any) => {
					if (project) {
						setProjectTitle(project.project_name);
						setProjectInfo(project);
						context.setCurrentProject(project);
						getCampaignBasedWellProjects(context.currentTenantKey, {
							project_code,
							well_project_code,
						})
							.then((data: any) => {
								setWellTitle(data[0].well_project_name);
								setWellInfo(data[0]);
								context.setCurrentWell(data[0]);

								getPhasesAndTasks(context.currentTenantKey, {
									well_project_code: data[0].well_project_code,
								}).then((data: any) => {
									setPhaseList(data?.phase_data || []);
									setTaskList(data?.task_data || {});
									setIsLoading(false);
									// getDeliverablesList(context.currentTenantKey, [
									// 	"65efc784f5184013e22ffd3e",
									// 	"65f26a9625b2142464c51989",
									// ]).then((value: any) => {
									// 	console.log(value);
									// });
								});
							})
							.catch((error: any) => {
								setWellTitle(null);
								setWellInfo(null);
								setIsLoading(false);
							});
					} else {
						setProjectTitle(null);
						setProjectInfo(null);
						setIsLoading(false);
					}
				})
				.catch((e: any) => {
					setProjectTitle(null);
					setProjectInfo(null);
					setIsLoading(false);
				});
		}
	}, [context.currentTenantKey, context.allData]);

	const hasSelectedFilters = () => {
		// Check if any of the filter categories have selected values
		return Object.values(selectedFilters).some((values) => values.length > 0);
	};
	const handleMenuClick = (field: any, selectedValues: any[]) => {
		// console.log(`Selected: ${field} - ${selectedValues}`);
		// Update the selected filters state
		setSelectedFilters({ ...selectedFilters, [field]: selectedValues });

		localStorage.setItem(
			"selectedFilters",
			JSON.stringify({ ...selectedFilters, [field]: selectedValues })
		);
	};

	const handleClearAllFilters = () => {
		// Reset all filters to empty arrays
		setSelectedFilters({
			assigned_to: [],
			status: [],
		});
		setFilterMode(false);

		// Clear session storage for filter values and checkboxes
		localStorage.removeItem("selectedFilters");

		// Object.keys(selectedFilters).forEach((field) => {
		// 	const storageKey = generateStorageKey(field);
		// 	localStorage.removeItem(storageKey);
		// });

		setTimeout(() => {
			setFilterMode(true);
		}, 5);
	};

	const renderMetaData = () => {
		const numRows = Math.ceil(Object.keys(WellTemplateExample).length / 2);
		const totalColumns = numRows * 2;
		const leftOverColumns = totalColumns - WellTemplateExample.length;
		console.log("i-debug wellInfo", WellTemplateExample);

		return (
			<div
				style={{
					display: "grid",
					columnGap: "20px",
					rowGap: "20px",
					// gridTemplateRows: `repeat(${numRows}, auto)`,
					gridTemplateColumns: "repeat(3, auto)",
				}}
			>
				{WellTemplateExample.map((element: any) => (
					<div
						style={{
							display: "grid",
							rowGap: "10px",
							gridTemplateRows: "auto auto",
						}}
					>
						<span
							style={{
								height: "min-content",
								color: "rgba(255, 255, 255, 0.45)",
								textAlign: "left",
								fontSize: "12px",
							}}
						>
							{element.label}
						</span>
						<span
							style={{
								fontSize: "14px",
								textAlign: "left",
								height: "min-content",
							}}
						>
							{setWellContent(wellInfo, element.key)}
						</span>
					</div>
				))}
				{Array.from({ length: leftOverColumns }, (_, index) => (
					<div
						style={{
							display: "grid",
							rowGap: "10px",
							gridTemplateRows: "auto auto",
						}}
					>
						<span key={index}>{""}</span>
						<span key={index}>{""}</span>
					</div>
				))}
			</div>
		);
	};

	const renderDetailData = () => {
		const numRows = Math.ceil(Object.keys(WellDetailsExample).length / 3);
		const totalColumns = numRows * 3;
		const leftOverColumns = totalColumns - WellDetailsExample.length;

		return (
			<div
				style={{
					display: "grid",
					// columnGap: "20px",
					rowGap: "20px",
					gridTemplateRows: `repeat(5, auto)`,
					gridTemplateColumns: "repeat(3, auto)",
					gridAutoFlow: "column",
				}}
			>
				{WellDetailsExample.map((element: any) => (
					<div
						style={{
							display: "grid",
							columnGap: "20px",
							alignItems: "center",
							alignContent: "center",
							gridTemplateColumns: "50% 50%",
						}}
					>
						<span
							style={{
								height: "min-content",
								color: "rgba(255, 255, 255, 0.45)",
								textAlign: "right",
								fontSize: "12px",
							}}
						>
							{element.label}
						</span>
						<span
							style={{
								fontSize: "14px",
								textAlign: "left",
								height: "min-content",
							}}
						>
							{setWellContent(wellInfo, element.key)}
						</span>
					</div>
				))}
				{Array.from({ length: leftOverColumns }, (_, index) => (
					<>
						<span key={index}>{""}</span>
						<span key={index}>{""}</span>
					</>
				))}
			</div>
		);
	};

	const renderKanbanColumns = () => {
		const renderTasks = (phaseId: any) => {
			const currentTaskList = filteredTaskList || taskList;
			return currentTaskList?.[phaseId]?.map((element: any) => {
				return (
					<div className="task-item">
						<span>
							{element.task_number} {element.task_name}
						</span>
						<img src={newSvg} />
					</div>
				);
			});
		};

		return phaseList?.map((phase: any) => {
			var percentage = 0;
			// Math.floor(Math.random() * 101)

			return (
				<div
					style={{
						minWidth: "320px",
						maxWidth: "320px",
						height: "100%",
						display: "flex",
						flexDirection: "column",
						gap: "30px",
					}}
				>
					<div
						style={{
							width: "100%",
							background: "rgba(255,255,255,0.20)",
							height: "46px",
							color: "white",
							borderRadius: "3px",
							position: "relative",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexShrink: 0,
						}}
					>
						<span style={{ zIndex: "1" }}>{percentage}%</span>
						<div
							style={{
								left: "0",
								position: "absolute",
								width: `${percentage}%`,
								height: "46px",
								background: "linear-gradient(90deg, #225C61 0%, #299BA3 100%)",
								borderRadius: "3px",
							}}
						></div>
					</div>
					<div
						className="project-status-container phase-container"
						style={{
							minWidth: "320px",
							maxWidth: "320px",
							flex: 1,
							height: "100px",
						}}
					>
						<div
							className="project-status-container-header phase-item"
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<img src={squareHehe} /> <span>{phase.phase_name}</span>
						</div>
						<div
							className="project-status-container-content task-container"
							style={{ padding: "0", gap: "0px" }}
						>
							{renderTasks(phase._id)}
						</div>
					</div>
				</div>
			);
		});
	};

	return (
		<>
			{projectInfo && wellInfo && !isLoading && (
				<div
					className="generic-container"
					style={{ gap: "20px", overflow: "auto" }}
				>
					<Space direction="vertical">
						<span
							style={{
								fontSize: "20px",
								fontWeight: "600",
								color: "rgba(255, 255, 255, 0.85)",
							}}
						>
							{projectTitle} / {wellTitle}
						</span>
					</Space>
					{/* Top Container */}
					<div
						className="main-container"
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div
							className="container-header"
							style={{
								display: "flex",
								padding: "12px 30px",
								minHeight: "56px",
								alignItems: "center",
								borderRadius: "3px",
								background: "#0D5257",
								boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
							}}
						>
							<div
								className={""}
								style={{
									display: "flex",
									padding: "4px 15px",
									gap: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									setWellDetailsExpand(!wellDetailsExpand);
								}}
							>
								<span>Well project details</span>
								{wellDetailsExpand ? GetAntIcon("up") : GetAntIcon("downarrow")}
							</div>{" "}
						</div>
						{wellDetailsExpand ? (
							<div
								className="details-content project-details-container"
								style={{
									display: "flex",
									flexWrap: "inherit",
									flexDirection: "row",
									alignItems: "flex-start",
									justifyContent: "space-between",
									padding: "30px",
									gap: 0,
									overflowX: "auto",
									maxHeight: "100%",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										maxWidth: "50%",
										height: "100%",
										position: "relative",
										gap: "20px",
									}}
								>
									{renderMetaData()}
									<div></div>
									{checkPrivilege(props.params.userRole, [
										"project-manager",
									]) && (
										<a
											style={{ marginTop: "auto" }}
											onClick={() => {
												navigate("well-settings", {
													replace: false,
													state: { ...projectInfo, ...wellInfo },
												});
											}}
										>
											{GetAntIcon("setting")} Well settings
										</a>
									)}
								</div>

								<Divider
									type="vertical"
									style={{ height: "100%", margin: "0px 20px" }}
								></Divider>
								<div
									style={{
										flex: "1",
										// background: "rgba(31,31,31,0.2)",
										// padding: "0px 20px",
									}}
								>
									<Divider
										orientation="left"
										style={{
											fontSize: "14px",
											color: "rgba(255, 255, 255, 0.45)",
											marginTop: "0px",
										}}
									>
										Well details
									</Divider>
									<div>{renderDetailData()}</div>
									{/* <Table dataSource={[]} columns={[]} /> */}
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
					{/* Bottom Container */}
					<div
						className="main-container"
						style={{
							minHeight: "85%",
							width: "100%",
							// maxHeight: "100%",
							overflow: "hidden",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div
							className="project-header"
							style={{
								display: "flex",
								padding: "12px 30px",
								alignItems: "center",
								borderRadius: "3px",
								background: "#0D5257",
								boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
							}}
						>
							<div
								style={{ display: "flex", flexDirection: "row", gap: "10px" }}
							>
								<div
									className={
										activePage === "kanban" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("kanban");
									}}
								>
									{GetAntIcon("project")}
									<span>Kanban</span>
								</div>{" "}
								{/* <div
									className={
										activePage === "ganttChart" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("ganttChart");
									}}
								>
									{GetAntIcon("app")}
									<span>Gantt chart</span>
								</div> */}
							</div>
							<div
								style={{
									display: "flex",
									flex: "1",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: "15px",
								}}
							>
								<Input
									placeholder="Search"
									className="input-clearable"
									style={{ maxWidth: "265px" }}
									onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
										setSearchValue(e.currentTarget.value);
										e.currentTarget.blur();
									}}
									onChange={(e) => {
										if (e.target.value === "") {
											setSearchValue("");
										}
									}}
									allowClear
									prefix={<SearchOutlined />}
								/>
								<div style={{ display: "flex", flexDirection: "row" }}>
									{" "}
									<div
										className={
											filterMode || hasSelectedFilters()
												? "project-active-button"
												: ""
										}
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setFilterMode(!filterMode);
										}}
									>
										{GetAntIcon("filter")}
									</div>
									{/* <div
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setDescMode(!descMode);
										}}
									>
										{descMode
											? GetAntIcon("descending")
											: GetAntIcon("ascending")}
									</div> */}
									{/* <div style={{ padding: "4px 10px", cursor: "pointer" }}>
										{GetAntIcon("setting")}
									</div> */}
								</div>
							</div>
						</div>
						<div
							className="generic-content project-overview-container"
							style={{
								flex: 1,
								display: "flex",
								flexWrap: "inherit",
								flexDirection: "column",
								alignItems: "flex-start",
								padding: 0,
								gap: 0,
								overflowX: "auto",
								maxHeight: "100%",
							}}
						>
							<div
								style={{
									display: "flex",
									padding: filterMode ? "12px 30px" : "0 30px",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: "5px",
									transform: filterMode ? "translateY(0)" : "translateY(-150%)",
									alignSelf: "stretch",
									background: "rgba(6,45,48)",
									transition: "0.3s ease-in-out",
								}}
							>
								{filterMode && (
									<>
										{" "}
										<CheckboxDropdown
											options={[]}
											onChange={(selectedValues) =>
												handleMenuClick("assigned_to", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters.assigned_to}
											Key="assigned_to"
											label="Assigned to"
										/>
										<CheckboxDropdown
											options={[]}
											onChange={(selectedValues) =>
												handleMenuClick("status", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters.status}
											Key="status"
											label="Status"
										/>
										<div className="test">
											<Button
												type="link"
												style={{ color: "rgba(255, 255, 255, 0.5)" }}
												onClick={handleClearAllFilters}
											>
												Reset All
											</Button>
										</div>
									</>
								)}
							</div>
							{activePage === "kanban" && (
								<div
									className="kanban-columns-container"
									style={{
										display: "flex",
										flex: 1,
										alignItems: "flex-start",
										padding: "30px",
										gap: "25px",
										alignSelf: "stretch",
										overflow: "auto",
									}}
								>
									{phaseList?.length > 0 ? (
										renderKanbanColumns()
									) : (
										<Empty
											className="no-data-empty"
											style={{ margin: "auto", fontSize: "20px" }}
											image={elementalSvg}
											description="Programme for well is not built yet."
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			{!projectInfo && !isLoading && (
				<>
					<NoAccess text={"This project does not exist."} />
				</>
			)}
			{!wellInfo && projectInfo && !isLoading && (
				<>
					<NoAccess text={"This well does not exist."} />
				</>
			)}
		</>
	);
}

export default WellOverview;
