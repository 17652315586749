import React, { useEffect, useState, useRef, useContext } from "react";
import {
	Tabs,
	Form,
	Input,
	Select,
	Button,
	Divider,
	Popconfirm,
	Popover,
	Space,
} from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import Emitter from "../../utils/emitter";
import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import FormButtonSave from "../../components/FormButtonSave";
import FormButtonCancel from "../../components/FormButtonCancel";
import { GetAntIcon } from "../../utils/ant_icons";
import CustomForm from "../../components/CustomForm";
import {
	getProjects,
	updateProjectItem,
} from "../../services/api-server/projects";
import { HomeContext } from "../../containers/Home";

const ProjectConfiguration = (props: any) => {
	const context: any = useContext(HomeContext);
	const currentProject = props.currentProject;
	const data = props.data || [];

	const [formRef]: any = useForm();
	const [formReset, setFormReset] = useState<boolean>(true);
	const [popOverVisibility, setPopOverVisibility] = useState<boolean>(false);
	const [extraData, setExtraData] = useState<any[]>([]);
	const [projectData, setProjectData] = useState<any>(null);

	// const [initialForm, setInitialForm] = useState<any>(null);
	const initialForm = useRef(null);

	useEffect(() => {
		// set project data
		if (currentProject != "Add new project" && currentProject) {
			let project = data.projects.filter(
				(item: any) => item.project_code == currentProject
			);
			console.log("project", project);
			setProjectData(project[0]);
			let initialFormData: any = {
				project_manager: project[0].project_manager,
				role_assignments: project[0].role_assignments,
			};
			initialForm.current = initialFormData;
			formRef.setFieldsValue(initialForm.current);
		}
	}, [formRef, data]);

	useEffect(() => {
		console.log(formReset);
	}, [formReset]);

	const handleFormCancel = () => {
		if (currentProject != "Add new employee") {
			resetForm(initialForm.current);
		} else {
			resetForm();
		}
	};

	const handleFormSave = (values: any, type: string) => {
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});

		// 	console.log("save items", values);
		Emitter.emit("loading", true);

		if (currentProject != "Add new project" && currentProject) {
			updateProjectItem(context?.currentTenantKey, currentProject, values)
				.then(() => {
					Emitter.emit("alert", {
						type: "success",
						message: "Project has been successfully updated.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					resetForm(values);
					props?.handleProjectConfigSave();
				})
				.finally(() => {
					Emitter.emit("loading", false);
				});
		}
	};

	const handleFormDelete = (values: any, type: string) => {
		console.log("delete items", values);
	};

	const resetForm = (values: any = null, data: any = null) => {
		let filteredCountries: any = [];
		if (formRef) {
			setFormReset(true);
			if (values) {
				Object.keys(values).forEach((key: any) => {
					// values[key] = checkLookup(key, values[key], data || allData);
					console.log(key, values[key]);
				});
			}
			formRef.resetFields();
			formRef.setFieldsValue(values);

			initialForm.current = values;
		}
	};

	return (
		<div
			className="main-container"
			style={{
				width: "70%",
				display: "flex",
				flexDirection: "column",
				borderRadius: "3px",
			}}
		>
			<div className="generic-header" style={{ height: "50px" }}>
				Settings
			</div>
			<div
				className="generic-content"
				style={{
					flex: "1",
					gap: "0px",
					maxHeight: "100%",
					overflow: "hidden",
					flexWrap: "nowrap",
				}}
			>
				<Tabs
					tabPosition="left"
					defaultValue={"team-roles"}
					onChange={(key: any) => {
						// setTableColumns([]);
						// setDataTable([]);
						// setSideContainerOpen(false);
						// setTabKey(key);
						// setExpandRowKey([]);
						// setFilteredExtraData(null);
						// setActiveRowKey(null);
						// setActiveGroupKey(null);
					}}
					defaultActiveKey={"company"}
					items={[
						{ label: "Team roles", key: "team-roles" },
						{ label: "Client roles", key: "client roles", disabled: true },
					]}
				></Tabs>
				<div
					style={{
						flex: "1",
						height: "100%",
						display: "flex",
						maxWidth: "100%",
						overflow: "auto",
						flexDirection: "column",
					}}
				>
					<CustomForm
						setFormReset={setFormReset}
						tabKey={"roles-configuration"}
						formRef={formRef}
						dataOptions={data}
					/>
				</div>
			</div>
			<div className="generic-footer">
				<FormButtonCancel
					form={formRef}
					handleFormCancel={handleFormCancel}
					formReset={formReset}
				></FormButtonCancel>
				<FormButtonSave
					form={formRef}
					handleFormSave={handleFormSave}
					formReset={formReset}
				></FormButtonSave>
			</div>
		</div>
	);
};

export default ProjectConfiguration;
