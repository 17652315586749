import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Space } from "antd";
import Emitter from "../utils/emitter";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function LeaveWithChangesModal(props: any) {
	const [open, setOpen] = useState<any>(false);

	useEffect(() => {
		// if a form is updating and page has change, trigger the open modal
		if (props.isFormUpdating && props.pageChange) {
			setOpen(true);
		}
	}, [props.isFormUpdating, props.pageChange]);

	return (
		<Modal
			open={open}
			// footer={null}
			closable={false}
			maskClosable={false}
			title={null}
			// icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
			style={{ marginRight: "auto", background: "#393939", maxWidth: "318px" }}
			cancelButtonProps={{ ghost: true }}
			cancelText="Leave"
			okText="Continue editing"
			okType="default"
			rootClassName="leave-changes-modal"
			onOk={() => {
				setOpen(false);
				Emitter.emit("stay", null);
			}}
			onCancel={() => {
				setOpen(false);
				props.setFormUpdating(false);
				Emitter.emit("leave", null);
			}}
		>
			<Space>
				<ExclamationCircleOutlined style={{ color: "grey" }} />
				Leave without saving? Changes will not be saved.
			</Space>
		</Modal>
	);
}

export default LeaveWithChangesModal;
