import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Form,
	Input,
	Select,
	Tag,
	Divider,
	ColorPicker,
	Space,
	Checkbox,
	InputNumber,
	DatePicker,
	Radio,
	Button,
} from "antd";
import locale from "antd/es/date-picker/locale/en_GB";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import TextArea from "antd/es/input/TextArea";
import { multiCheckItems, getFormOptionList } from "../utils/lookup_list";
import { GetAntIcon } from "../utils/ant_icons";
import UploadComponent from "./UploadComponent";
import { convertToDMS, getInitials } from "../utils/utils";
import Emitter from "../utils/emitter";
import { uniqueCountryCodes } from "../utils/countryCodes";
dayjs.extend(utc);

const CustomForm = (props: any) => {
	const [initialForm, setInitialForm]: any = useState<any>(null);
	const [selectedRole, setSelectedRole] = useState<any>(null);
	const [selectFieldValues, setSelectFieldValues] = useState<any>({});
	const [dynamicInput, setDynamicInput] = useState<any>({});
	const [firstField, setFirstField] = useState<any>(null);
	const [image, setImage] = useState<any>(null);
	const [filteredDataOptions, setFilteredDataOptions] = useState<any>(null);
	const [editLatLong, setEditLatLong] = useState<any>({
		latitude: false,
		longitude: false,
	});

	// Function to force re-render form data render
	const [forceRender, forceRerender] = useState<any>(0);

	const formRef = props.formRef;
	const dataOptions = props.dataOptions; // Data from the table which is previously named as extraData
	const activeRowKey = props?.activeRowKey;
	const activeRecord = props?.activeRecord;
	const setActiveRecord = props?.setActiveRecord;
	const handleFormCancel = props?.handleFormCancel;
	const handleFormSave = props?.handleFormSave;
	const handleFormDelete = props?.handleFormDelete;
	const getDeleteMessage = props?.getDeleteMessage;
	const resetForm = props?.resetForm;
	const setFormReset = props?.setFormReset;
	const tabKey = props?.tabKey;
	const formOption = props?.formOption;

	useEffect(() => {
		console.log("Updating");
		if (props.filteredDataOptions) {
			setFilteredDataOptions(props.filteredDataOptions);
		}
	}, [props.filteredDataOptions]);

	const filterOption = (input: any, option: any) => {
		return option.label.toLowerCase().includes(input.toLowerCase());
	};

	const handleSelectOptions = (
		keyProperty: any,
		keyLabel: any,
		data: any = null,
		relay: any = null
	) => {
		let mappedArray: any = [];
		const uniqueValues = new Set();

		// set Dropdown selection list
		if (keyProperty && keyLabel) {
			let dataArray = data;

			// If it requires a relay but the relay value has no data, return empty
			if (
				(relay && formRef.getFieldValue(relay) == undefined) ||
				formRef.getFieldValue(relay) == ""
			) {
				mappedArray = [];
			} else {
				mappedArray = dataArray
					?.map((obj: any) => {
						const label = obj[keyLabel];
						const key = obj[keyProperty];

						// Check if the value is unique
						if (!uniqueValues.has(key) && label != "" && key) {
							uniqueValues.add(key);
							return { label: label, value: key };
						}

						return null; // If not unique, return null
					})
					.filter(Boolean) // Remove null values
					.sort((a: any, b: any) => {
						if (a.label.toLowerCase() < b.label.toLowerCase()) {
							return -1;
						}
						if (a.label.toLowerCase() > b.label.toLowerCase()) {
							return 1;
						}
						return 0;
					});
			}
		}
		return mappedArray;
	};

	// Set the form field type based on the type from the form list
	const setInputType = (element: any, index: any, isChild: boolean = false) => {
		let dataObject: any = null;
		switch (element.type) {
			case "input":
				return (
					<Form.Item
						label={element.label}
						name={element.name}
						rules={[{ required: element.required, message: "" }]}
					>
						<Input
							// autoFocus={index == 0 && !activeRowKey && !isChild}
							autoComplete="off"
							count={
								element.count && {
									show: true,
									max: element.count,
								}
							}
							onChange={(event: any) => {
								let value = event.target.value
									.replace(/^ /, "")
									.replace(/\s+/g, " ");
								if (element.count) {
									value = value.slice(0, element.count);
								}
								formRef.setFieldValue(element.name, value);

								setFormReset(false);
							}}
							style={{
								width: element.width ? `${element.width * 4}%` : "100%",
							}}
							disabled={element.disabled}
						></Input>
					</Form.Item>
				);
			case "textArea":
				return (
					<Form.Item
						label={element.label}
						name={element.name}
						rules={[{ required: element.required, message: "" }]}
					>
						<TextArea
							// autoFocus={index == 0 && !activeRowKey && !isChild}
							count={
								element.count && {
									show: true,
									max: element.count,
								}
							}
							autoSize={element.size || { minRows: 3, maxRows: 5 }}
							onChange={(event: any) => {
								let value = event.target.value
									.replace(/^ /, "")
									.replace(/\s+/g, " ");

								if (element.count) {
									value = value.slice(0, element.count);
								}
								formRef.setFieldValue(element.name, value);

								setFormReset(false);
							}}
							style={{
								resize: element.resize == false ? "none" : "vertical",
							}}
						></TextArea>
					</Form.Item>
				);
			case "select":
				dataObject = filteredDataOptions || dataOptions;
				console.log("dataObject", dataObject);
				return (
					<Form.Item
						label={element.label}
						name={element.name}
						rules={[{ required: element.required, message: "" }]}
					>
						<Select
							getPopupContainer={(trigger: any) => trigger.parentNode}
							// autoFocus={index == 0 && !activeRowKey && !isChild}
							allowClear
							filterOption={filterOption}
							showSearch
							options={handleSelectOptions(
								element.optionKey,
								element.optionLabel,
								element.extraKey ? dataObject[element.extraKey] : null,
								element.relayFrom
							)}
							mode={element.mode || undefined}
							tagRender={
								element.mode == "tags"
									? (props: any) => {
											if (element.mode === "tags") {
												let tag = dataOptions[element.extraKey]?.find(
													(element: any) => {
														return props.label == element.name;
													}
												);
												if (tag) {
													return <Tag color={tag.color}>{tag.name}</Tag>;
												}
											}
											return <></>;
									  }
									: undefined
							}
							onChange={(value: any) => {
								if (element.relayTo) {
									formRef.setFieldValue(element.relayTo, undefined);
									let indexArray = dataOptions[element.extraKey].find(
										(object: any) => object[element.optionKey] === value
									);
									let relayArray = dataOptions[element.relayTo];
									if (relayArray) {
										let filteredData: any = {};
										if (indexArray) {
											filteredData[element.relayTo] = relayArray
												?.map((object: any) => {
													if (
														indexArray[element.relayIndexKey]?.includes(
															object[element.relayLookupKey]
														)
													) {
														return object;
													}
												})
												.filter((item: any) => item);
										} else {
											filteredData = relayArray;
										}

										if (filteredData) {
											setFilteredDataOptions({
												...dataObject,
												...filteredData,
											});
										}
									}
								}
								setFormReset(false);
							}}
							disabled={element.disabled}
						></Select>
					</Form.Item>
				);
			case "select-multiple":
				let fieldValues = formRef.getFieldValue(element.name) || [];
				dataObject = filteredDataOptions || dataOptions;
				let initialValues = initialForm ? initialForm[element.name] || [] : [];
				return (
					<div
						style={{ gap: "10px", display: "flex", flexDirection: "column" }}
					>
						<div
							style={{ gap: "8px", display: "flex", flexDirection: "column" }}
						>
							<Form.Item
								style={{ display: "none" }}
								name={element.name}
								rules={[{ required: element.required, message: "" }]}
							></Form.Item>
							<span>
								{element.label}{" "}
								{!element.required && (
									<span style={{ color: "rgba(255, 255, 255, 0.45)" }}>
										(optional)
									</span>
								)}
							</span>
							<Select
								getPopupContainer={(trigger: any) => trigger.parentNode}
								// autoFocus={index == 0 && !activeRowKey && !isChild}
								filterOption={filterOption}
								showSearch
								options={handleSelectOptions(
									element.optionKey,
									element.optionLabel,
									element.extraKey ? dataObject[element.extraKey] : null,
									element.relayFrom
								)}
								value={null}
								autoClearSearchValue
								mode={element.mode || undefined}
								tagRender={(props: any) => {
									if (element.mode === "tags") {
										let tag = dataOptions?.find((element: any) => {
											return props.label == element.name;
										});
										if (tag) {
											return <Tag color={tag.color}>{tag.name}</Tag>;
										}
									}
									return <></>;
								}}
								onChange={(value: any) => {
									setFormReset(false);
									let formValues = formRef.getFieldValue(element.name) || [];
									formValues = [...formValues];

									formValues.unshift(value);
									formRef.setFieldValue(element.name, formValues);

									let dataState: any = [];
									let data: any = {};

									if (filteredDataOptions) {
										dataState = [...filteredDataOptions[element.extraKey]];
										data = { ...filteredDataOptions };
									} else {
										dataState = [...dataOptions[element.extraKey]];
										data = { ...dataOptions };
									}
									let filteredData = dataState.filter(
										(item: any) => item[element.optionKey] != value
									);
									data[element.extraKey] = [...filteredData];
									console.log(data);
									setFilteredDataOptions(data);

									//BAD
									if (tabKey == "company") {
										let formValue = formRef.getFieldValue("parent");
										if (value == formValue) {
											formRef.setFieldValue("parent", undefined);
										}
									}
								}}
							></Select>
						</div>
						{dataOptions[element.extraKey]
							?.filter((item: any) => {
								return fieldValues?.includes(item[element.optionKey]);
							})
							.sort((a: any, b: any) => {
								if (
									!initialValues.includes(a[element.optionKey]) ||
									!initialValues.includes(b[element.optionKey])
								) {
									return (
										fieldValues.indexOf(a[element.optionKey]) -
										fieldValues.indexOf(b[element.optionKey])
									);
								} else {
									return a[element.optionLabel].localeCompare(
										b[element.optionLabel]
									);
								}
							})
							?.map((item: any) => {
								return (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "10px",
											alignSelf: "stretch",
										}}
									>
										<Input
											style={{ color: "rgba(255,255,255,0.85)", flex: 1 }}
											disabled
											value={item[element.optionLabel]}
										/>
										<span
											className="clickable-text"
											style={{ cursor: "pointer" }}
											onClick={() => {
												let formValues =
													formRef.getFieldValue(element.name) || [];
												formValues = [...formValues];
												let dataState: any = [];
												let data: any = {};

												if (filteredDataOptions) {
													dataState = [
														...filteredDataOptions[element.extraKey],
													];
													data = { ...filteredDataOptions };
												} else {
													dataState = [...dataOptions[element.extraKey]];
													data = { ...dataOptions };
												}

												let filteredData = dataState;
												let foundData = dataOptions[element.extraKey].find(
													(_element: any) =>
														_element[element.optionKey] ===
														item[element.optionKey]
												);
												let foundIndex = formValues.findIndex(
													(_element: any) =>
														_element === item[element.optionKey]
												);
												if (foundIndex != -1 && foundData) {
													formValues.splice(foundIndex, 1);
													filteredData.push(foundData);
													filteredData = filteredData.sort((a: any, b: any) =>
														a[element.optionLabel].localeCompare(
															b[element.optionLabel]
														)
													);
													formRef.setFieldValue(element.name, formValues);
													data[element.extraKey] = [...filteredData];
												}
												setFilteredDataOptions(data);
												setFormReset(false);
											}}
										>
											{GetAntIcon("close")}
										</span>
									</div>
								);
							})}
					</div>
				);
			case "role-dynamic-form":
				const formChanged = () => {
					let formData = formRef.getFieldValue(element.name);
					let filteredData = dataOptions[element.extraKey].filter(
						(data: any) =>
							data["name"].toLowerCase() !== "project manager" &&
							!formData.some((item: any) => item["role"] === data["name"])
					);

					let data = { ...dataOptions };
					data[element.extraKey] = [...filteredData];
					setFilteredDataOptions(data);
					setFormReset(false);
				};
				dataObject = filteredDataOptions || dataOptions;
				return (
					<div
						style={{
							gap: "10px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{/* Create a form item that will use to select options as its value */}
						<div
							style={{
								gap: "8px",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
									gap: `${element.gap}`,
								}}
							>
								<Form.List name={element.name}>
									{(fields, { add, remove }) => (
										<>
											{/* First loop for trigerring the mapping */}
											{element.children?.map((child: any, index: any) => (
												<Form.Item
													label={child.label}
													required={child.required}
												>
													<Select
														getPopupContainer={(trigger: any) =>
															trigger.parentNode
														}
														onClear={() => {
															if (element.children.length - 1 !== index) {
																setSelectedRole(null);
															}
														}}
														allowClear
														onDropdownVisibleChange={() => formChanged()}
														// autoFocus={index == 0 && !activeRowKey && !isChild}
														showSearch
														mode={child.mode}
														disabled={
															element.children.length - 1 === index &&
															!selectedRole
														}
														options={handleSelectOptions(
															child.optionKey,
															child.optionLabel,
															child.extraKey
																? dataObject[child.extraKey]
																: null,
															child.relayFrom
														)}
														autoClearSearchValue
														value={selectFieldValues[child.name]}
														onChange={(value: any, option: any) => {
															console.log(element.children);

															if (element.children.length - 1 !== index) {
																// option.label is assigned to store the label of the option which is the role name
																let formValues = selectFieldValues;
																formValues[child.uniqueKeyName] = option?.value;
																formValues[child.name] = option?.label;
																setSelectFieldValues(formValues);
																setSelectedRole(option?.label);
																// console.log("form values", formValues);
															} else {
																// value is pushed to store the email of the appointee
																let formValues = selectFieldValues;
																formValues[child.name] = value;
																setSelectFieldValues(formValues);
															}

															// The dynamic form will be added only if the all the fields are filled
														}}
														onBlur={() => {
															if (
																Object.keys(selectFieldValues).length ===
																element.children.length + 1
															) {
																add(selectFieldValues);
																setSelectedRole(null);
																setSelectFieldValues({});
															}
														}}
													></Select>
												</Form.Item>
											))}
											<div
												style={{
													margin: "10px 0px 10px 0px",
													color: "rgba(255,255,255,0.45)",
													gridColumn: "1 / -1",
													display:
														formRef.getFieldValue(element.name)?.length === 0
															? "none"
															: "block",
												}}
												key={element.label}
											>
												<span
													style={{
														marginRight: "10px",
													}}
												>
													{GetAntIcon("team")}
												</span>
												List
											</div>
											{/* Dynamic Part where mapping is */}
											{fields?.map(({ key, name, ...restField }) => (
												<>
													{element.children?.map((child: any, index: any) =>
														element.children.length - 1 !== index ? (
															<>
																<Form.Item
																	{...restField}
																	name={[name, child.name]}
																	rules={[
																		{
																			required: true,
																			message: "",
																		},
																	]}
																>
																	<Input
																		style={{
																			color: "rgba(255,255,255,0.85)",
																			flex: 1,
																		}}
																		disabled
																	/>
																</Form.Item>
															</>
														) : (
															<div
																style={{
																	display: "flex",
																	alignContent: "center",
																	alignItems: "center",
																	gap: "10px",
																}}
															>
																<Form.Item
																	{...restField}
																	name={[name, child.name]}
																	rules={[
																		{
																			required: true,
																			message: "",
																		},
																	]}
																	style={{
																		flex: 1,
																	}}
																>
																	<Select
																		getPopupContainer={(trigger: any) =>
																			trigger.parentNode
																		}
																		allowClear
																		mode={child.mode || undefined}
																		filterOption={filterOption}
																		showSearch
																		options={handleSelectOptions(
																			child.optionKey,
																			child.optionLabel,
																			child.extraKey
																				? dataObject[child.extraKey]
																				: null,
																			child.relayFrom
																		)}
																		onChange={(value: any) => {
																			setFormReset(false);
																		}}
																	></Select>
																</Form.Item>
																{/* Used Form.Item to use the same css settings as other form items. */}
																<Form.Item>
																	{/* Close Button */}
																	<span
																		className="clickable-text"
																		style={{
																			cursor: "pointer",
																			margin: "auto",
																			padding: "2px",
																		}}
																		onClick={() => {
																			remove(name);
																			formChanged();
																		}}
																	>
																		{GetAntIcon("close")}
																	</span>
																</Form.Item>
															</div>
														)
													)}
												</>
											))}
										</>
									)}
								</Form.List>
							</div>
						</div>
					</div>
				);
			case "temporary-dynamic":
				console.log("i-debug form", element.children);
				return (
					<div
						style={{
							gap: "10px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{/* Create a form item that will use to select options as its value */}
						<div
							style={{
								gap: "8px",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
									gap: `${element.gap}`,
								}}
							>
								<Form.List name={element.name}>
									{(fields, { add, remove }) => (
										<>
											{/* First loop for trigerring the mapping */}
											{element.children?.map((child: any, index: any) => (
												<Form.Item
													label={child.label}
													required={child.required}
												>
													<Input
														// autoFocus={index == 0 && !activeRowKey && !isChild}
														autoComplete="off"
														count={
															child.count && {
																show: true,
																max: child.count,
															}
														}
														value={dynamicInput[child.name]}
														onChange={(event: any) => {
															let value = event.target.value
																.replace(/^ /, "")
																.replace(/\s+/g, " ");
															if (child.count) {
																value = value.slice(0, child.count);
															}
															// formRef.setFieldValue(child.name, value);
															// setFormReset(false);

															setDynamicInput({
																...dynamicInput,
																[child.name]: value,
															});
														}}
														onBlur={() => {
															if (
																Object.keys(dynamicInput).length ===
																element.children.length
															) {
																add(dynamicInput);
																setDynamicInput({});
															}
														}}
														style={{
															width: child.width
																? `${child.width * 4}%`
																: "100%",
														}}
														disabled={child.disabled}
													></Input>
												</Form.Item>
											))}
											<div
												style={{
													margin: "10px 0px 10px 0px",
													color: "rgba(255,255,255,0.45)",
													gridColumn: "1 / -1",
													display:
														formRef.getFieldValue(element.name)?.length === 0 ||
														formRef.getFieldValue(element.name) === undefined
															? "none"
															: "block",
												}}
												key={element.label}
											>
												<span
													style={{
														marginRight: "10px",
													}}
												>
													{GetAntIcon("team")}
												</span>
												List
											</div>
											{/* Dynamic Part where mapping is */}
											{fields?.map(({ key, name, ...restField }) => (
												<>
													{element.children?.map((child: any, index: any) =>
														element.children.length - 1 !== index ? (
															<>
																<Form.Item
																	{...restField}
																	name={[name, child.name]}
																	rules={[
																		{
																			required: true,
																			message: "",
																		},
																	]}
																	style={{
																		flex: 1,
																	}}
																>
																	<Input
																		onChange={() => {
																			setFormReset(false);
																		}}
																	></Input>
																</Form.Item>
															</>
														) : (
															<div
																style={{
																	display: "flex",
																	alignContent: "center",
																	alignItems: "center",
																	gap: "10px",
																}}
															>
																<Form.Item
																	{...restField}
																	name={[name, child.name]}
																	rules={[
																		{
																			required: true,
																			message: "",
																		},
																	]}
																	style={{
																		flex: 1,
																	}}
																>
																	<Input
																		onChange={() => {
																			setFormReset(false);
																		}}
																	></Input>
																</Form.Item>
																{/* Used Form.Item to use the same css settings as other form items. */}
																<Form.Item>
																	{/* Close Button */}
																	<span
																		className="clickable-text"
																		style={{
																			cursor: "pointer",
																			margin: "auto",
																			padding: "2px",
																		}}
																		onClick={() => {
																			remove(name);
																			// formChanged();
																			setFormReset(false);
																		}}
																	>
																		{GetAntIcon("close")}
																	</span>
																</Form.Item>
															</div>
														)
													)}
												</>
											))}
										</>
									)}
								</Form.List>
							</div>
						</div>
					</div>
				);
			case "*":
				// only for 2 input field dynamic form
				// combine role-dynamic-form and temporary-dynamic

				return (
					<div
						style={{
							gap: "10px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{/* Create a form item that will use to select options as its value */}
						<div
							style={{
								gap: "8px",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<div
								style={{
									display: "grid",
									gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
									gap: `${element.gap}`,
								}}
							>
								<Form.List name={element.name}>
									{(fields, { add, remove }) => (
										<>
											{element.children?.map((child: any, index: any) => (
												<Form.Item
													label={child.label}
													required={child.required}
													tooltip={
														child.tooltip
															? {
																	title: child.tooltip,
																	icon: GetAntIcon("question3"),
																	placement: "right",
																	overlayStyle: { maxWidth: "400px" },
															  }
															: undefined
													}
												>
													{/* condition based on child.type */}
													{child.type === "input" ? (
														<Input
															// autoFocus={
															// 	index == 0 && !activeRowKey && !isChild
															// }
															autoComplete="off"
															count={
																child.count && {
																	show: true,
																	max: child.count,
																}
															}
															value={dynamicInput[child.name]}
															onChange={(event: any) => {
																let value = event.target.value
																	.replace(/^ /, "")
																	.replace(/\s+/g, " ");
																if (child.count) {
																	value = value.slice(0, child.count);
																}

																if (element.children.length - 1 !== index) {
																	setFirstField(event.target.value);
																}

																setDynamicInput({
																	...dynamicInput,
																	[child.name]: value,
																});
															}}
															onBlur={(event: any) => {
																if (
																	Object.keys(dynamicInput).length ===
																	element.children.length
																) {
																	const currentList: any =
																		formRef.getFieldValue(element.name);
																	if (currentList && currentList.length > 0) {
																		const isExist = currentList.filter(
																			(item: any) =>
																				item[child.name] === event.target.value
																		);
																		if (
																			isExist.length > 0 &&
																			firstField === null
																		) {
																			Emitter.emit("alert", {
																				type: "error",
																				message: `Duplicate ${child.name} is not allowed`,
																				description: "",
																				top: true,
																				closeable: false,
																				timeout: 3000,
																			});
																		} else {
																			if (
																				event.target.value &&
																				event.target.value !== ""
																			) {
																				add(dynamicInput);
																				setFirstField(null);
																				setDynamicInput({});
																			}
																		}
																	} else {
																		if (
																			event.target.value &&
																			event.target.value !== ""
																		) {
																			add(dynamicInput);
																			setFirstField(null);
																			setDynamicInput({});
																		}
																	}
																}
															}}
															style={{
																width: child.width
																	? `${child.width * 4}%`
																	: "100%",
															}}
															disabled={
																element.children.length - 1 === index &&
																!firstField
															}
														></Input>
													) : child.type === "select" ? (
														<Select></Select>
													) : (
														<Input disabled></Input>
													)}
												</Form.Item>
											))}
											<div
												style={{
													margin: "10px 0px 10px 0px",
													color: "rgba(255,255,255,0.45)",
													gridColumn: "1 / -1",
													display:
														formRef.getFieldValue(element.name)?.length === 0 ||
														formRef.getFieldValue(element.name) === undefined
															? "none"
															: "block",
												}}
												key={element.label}
											>
												<span
													style={{
														marginRight: "10px",
													}}
												>
													{GetAntIcon("team")}
												</span>
												List
											</div>
											{fields?.map(({ key, name, ...restField }) => (
												<>
													{element.children?.map((child: any, index: any) =>
														element.children.length - 1 !== index ? (
															<>
																<Form.Item
																	{...restField}
																	name={[name, child.name]}
																	rules={[
																		{
																			required: true,
																			message: "",
																		},
																	]}
																	style={{
																		flex: 1,
																	}}
																>
																	<Input
																		onChange={() => {
																			setFormReset(false);
																		}}
																	></Input>
																</Form.Item>
															</>
														) : (
															<div
																style={{
																	display: "flex",
																	alignContent: "center",
																	alignItems: "center",
																	gap: "10px",
																}}
															>
																<Space.Compact
																	style={{ width: "100%", flex: 1 }}
																>
																	<Form.Item
																		{...restField}
																		name={[name, child.name]}
																		rules={[
																			{
																				required: true,
																				message: "",
																			},
																		]}
																		style={{ width: "100%" }}
																	>
																		<Input
																			onChange={() => {
																				setFormReset(false);
																			}}
																		></Input>
																	</Form.Item>
																	<Button
																		onClick={() => {
																			const docLinkArray =
																				formRef.getFieldValue(element.name);
																			const docLinkObject = docLinkArray[key]; //key is from fields key
																			if (
																				!docLinkObject[child.name].startsWith(
																					"https://"
																				) &&
																				!docLinkObject[child.name].startsWith(
																					"http://"
																				)
																			)
																				window.open(
																					"https://" +
																						docLinkObject[child.name],
																					"_blank"
																				);
																			else
																				window.open(docLinkObject[child.name]);
																		}}
																	>
																		{GetAntIcon("export")}
																	</Button>
																</Space.Compact>
																{/* Used Form.Item to use the same css settings as other form items. */}
																<Form.Item>
																	{/* Close Button */}
																	<span
																		className="clickable-text"
																		style={{
																			cursor: "pointer",
																			margin: "auto",
																			padding: "2px",
																		}}
																		onClick={() => {
																			remove(name);
																			// formChanged();
																			setFormReset(false);
																		}}
																	>
																		{GetAntIcon("close")}
																	</span>
																</Form.Item>
															</div>
														)
													)}
												</>
											))}
										</>
									)}
								</Form.List>
							</div>
						</div>
					</div>
				);
			case "rolesPermissions":
				let arrayList: any = formRef.getFieldValue(element.name) || [];
				arrayList = [...arrayList];

				return (
					<div
						style={{ display: "flex", flexDirection: "column", gap: "10px" }}
					>
						<Form.Item
							style={{ display: "none" }}
							name={element.name}
							rules={[{ required: element.required, message: "" }]}
						></Form.Item>
						{multiCheckItems(tabKey)?.map((permissions: any) => {
							return (
								<div style={{ border: "1px solid #424242" }}>
									<Space size={5} direction="vertical">
										<div>
											<span>{permissions.label}</span>
											<span>
												{permissions.keys.reduce((acc: any, item: any) => {
													if (arrayList.includes(item.key)) {
														return acc + 1;
													}
													return acc;
												}, 0)}
												/{permissions.keys.length}
											</span>
										</div>
										<span>{permissions.description}</span>
										<Space size={10} direction="vertical">
											<Checkbox
												onChange={(e: any) => {
													setFormReset(false);
													let checked = e.target.checked;
													permissions.keys.forEach((item: any) => {
														let foundIndex = arrayList.findIndex(
															(permissionKeys: any) => {
																return permissionKeys == item.key;
															}
														);

														if (foundIndex != -1 && !checked) {
															arrayList.splice(foundIndex, 1);
														} else if (checked) {
															arrayList.push(item.key);
														}
													});
													formRef.setFieldValue(element.name, [...arrayList]);
													setFilteredDataOptions([...arrayList]);
												}}
												checked={permissions.keys.every((item: any) => {
													return arrayList.includes(item.key);
												})}
											>
												Select all
											</Checkbox>

											{permissions.keys.map((item: any) => {
												return (
													<Checkbox
														onChange={(e: any) => {
															setFormReset(false);
															let checked = e.target.checked;
															let foundIndex = arrayList.findIndex(
																(permissionKeys: any) => {
																	return permissionKeys == item.key;
																}
															);
															if (foundIndex != -1 && !checked) {
																arrayList.splice(foundIndex, 1);
															} else if (checked) {
																arrayList.push(item.key);
															}
															console.log(arrayList);
															formRef.setFieldValue(element.name, [
																...arrayList,
															]);
															setFilteredDataOptions([...arrayList]);
														}}
														checked={arrayList.includes(item.key)}
													>
														{item.label}
													</Checkbox>
												);
											})}
										</Space>
									</Space>
								</div>
							);
						})}
					</div>
				);
			case "image":
				const data: any = activeRecord;
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							gap: "8px",
						}}
					>
						<span>{element.label}</span>
						<UploadComponent
							label={element.name}
							prefix={""}
							initials={getInitials(activeRecord.name, 0)}
							setEmptyModalOpen={() => {}}
							image={data[element.name]}
							removeMessage={element.removeMessage}
							setImage={(image: any) => {
								setImage(image);
							}}
							handleImageSave={(image: any) => {
								if (typeof handleFormSave === "function") {
									activeRecord[element.name] = image;
									handleFormSave({ ...activeRecord });
								} else {
									element
										.updateImage(activeRowKey, { ...activeRecord, image })
										.then(() => {
											Emitter.emit("alert", {
												type: "success",
												message: `Your ${element.name} has been successfully updated.`,
												description: "",
												top: true,
												closeable: false,
												timeout: 3000,
											});
											setImage(image);
										})
										.catch(() => {
											Emitter.emit("alert", {
												type: "error",
												message: "Failed to update. Please try again.",
												description: "",
												top: true,
												closeable: false,
												timeout: 3000,
											});
										});
								}
							}}
							handleImageRemove={() => {
								if (typeof handleFormSave === "function") {
									activeRecord[element.name] = "";
									handleFormSave({ ...activeRecord });
								} else {
									element
										.removeImage(activeRowKey, null)
										.then(() => {
											Emitter.emit("alert", {
												type: "success",
												message: `Your ${element.name} has been successfully removed.`,
												description: "",
												top: true,
												closeable: false,
												timeout: 3000,
											});
											setImage(null);
										})
										.catch(() => {
											Emitter.emit("alert", {
												type: "error",
												message: "Failed to update. Please try again.",
												description: "",
												top: true,
												closeable: false,
												timeout: 3000,
											});
										});
								}
							}}
						></UploadComponent>
					</div>
				);
			case "counter":
				// Depreciated function. Not being used because cannot pass qc
				const format = (value: any, precision: any = null) => {
					if (value === "" || value === undefined || value === null) {
						return "";
					} else {
						// If there is decimal in the value, restrict user to only enter the amount of precision
						if (precision !== null && precision !== 0) {
							if (value.match(/\./g)) {
								let decimal = value.split(".")[1];
								decimal = decimal.substring(0, 1);
								return `${value.split(".")[0]}.${decimal}`;
							} else {
								return value;
							}
						} else {
							// Dont allow decimal to be entered
							return value.replace(/\./g, "");
						}
					}
				};
				return (
					<Form.Item
						label={element.label}
						name={element.name}
						tooltip={
							element.tooltip
								? {
										title: element.tooltip,
										icon: GetAntIcon("question3"),
										placement: "right",
										overlayStyle: { maxWidth: "400px" },
								  }
								: undefined
						}
						rules={[{ required: element.required, message: "" }]}
					>
						<InputNumber
							min={element.min}
							max={element.max}
							step={element.step}
							onChange={() => {
								setFormReset(false);
							}}
							precision={element.precision}
							// formatter={(value: any) => {
							// 	return format(value, element.precision);
							// }}
						/>
					</Form.Item>
				);
			case "datepicker":
				let formdate: any = null;
				if (formRef.getFieldValue(element.name))
					formdate = dayjs(formRef.getFieldValue(element.name)) || null;
				// Change to utx
				return (
					<div
						style={{ gap: "10px", display: "flex", flexDirection: "column" }}
					>
						<div
							style={{ gap: "8px", display: "flex", flexDirection: "column" }}
						>
							<Form.Item
								style={{ display: "none" }}
								name={element.name}
								rules={[{ required: element.required, message: "" }]}
							></Form.Item>
							<span style={{ display: "inline-flex" }}>
								{element.label}{" "}
								{!element.required && (
									<span style={{ color: "rgba(255, 255, 255, 0.45)" }}>
										(optional)
									</span>
								)}
							</span>
							<DatePicker
								style={{
									width: element.width ? `${element.width * 4}%` : "100%",
								}}
								value={formdate}
								format={element.format || "DD MMM YYYY"}
								onChange={(date: any, dateString: any) => {
									formdate = null;
									if (date) {
										formdate = dayjs(date)
											.startOf("day")
											.utc()
											.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
									}
									formRef.setFieldValue(element.name, formdate);
									forceRerender(forceRender + 1);
									setFormReset(false);
								}}
							></DatePicker>
						</div>
					</div>
				);
			case "radio":
				// formRef.setFieldValue(element.name, element.initialValue);
				return (
					<Form.Item
						label={element.label}
						name={element.name}
						rules={[{ required: element.required, message: "" }]}
					>
						<Radio.Group
							// options={element.options}
							// optionType="button"
							name={element.name}
							onChange={() => {
								setFormReset(false);
							}}
							style={{ width: "100%" }}
						>
							{element.options.map((option: any) => {
								return (
									<Radio.Button
										style={{
											width:
												element.options.length !== 0
													? `calc(100%/${element.options.length})`
													: "100%",
											textAlign: "center",
										}}
										value={option.value}
									>
										{option.label}
									</Radio.Button>
								);
							})}
						</Radio.Group>
					</Form.Item>
				);
			case "latlong":
				const toggleEditMode = (field: string) => {
					setEditLatLong({
						...editLatLong,
						[field]: !editLatLong[field],
					});
				};

				return (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
							gap: `2%`,
						}}
					>
						<Form.Item
							label={"Latitude"}
							name={[element.name, 0]}
							rules={[{ required: element.required, message: "" }]}
							style={{ flexGrow: "1" }}
						>
							<InputNumber
								min={-90}
								max={90}
								onFocus={() => {
									// When focus, set editLatLong to true to allow editing
									toggleEditMode("latitude");
								}}
								onBlur={() => {
									// When blur, set editLatLong to false to disable editing
									if (editLatLong["latitude"]) {
										toggleEditMode("latitude");
									}
								}}
								formatter={(value: any) => {
									// Continue working here
									// Change to DMS format if not in edit mode
									if (editLatLong["latitude"] === false) {
										if (value) {
											`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
											const dmsFormattedValue = convertToDMS(value, "latitude");
											return dmsFormattedValue;
										}
									}
									return value;
								}}
								parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
						<Form.Item
							label={"Longitude"}
							name={[element.name, 1]}
							rules={[{ required: element.required, message: "" }]}
							style={{ flexGrow: "1" }}
						>
							<InputNumber
								min={-180}
								max={180}
								onFocus={() => {
									// When focus, set editLatLong to true to allow editing
									toggleEditMode("longitude");
								}}
								onBlur={() => {
									// When blur, set editLatLong to false to disable editing
									if (editLatLong["longitude"]) {
										toggleEditMode("longitude");
									}
								}}
								formatter={(value: any) => {
									// Change to DMS format if not in edit mode
									if (editLatLong["longitude"] === false) {
										if (value) {
											`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
											const dmsFormattedValue = convertToDMS(
												value,
												"longitude"
											);
											return dmsFormattedValue;
										}
									}
									return value;
								}}
								parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</div>
				);
			case "colorPicker":
				return (
					<Form.Item
						label={element.label}
						name={element.name}
						rules={[{ required: element.required, message: "" }]}
						initialValue={"#299BA3"}
					>
						<ColorPicker
							showText
							// defaultValue="#000000"
							onChange={() => {
								setFormReset(false);
							}}
						></ColorPicker>
					</Form.Item>
				);
			case "grid":
				return (
					<div
						style={{
							display: "grid",
							gridTemplateColumns: element.gridTemplateColumns
								? element.gridTemplateColumns
								: `repeat(${element.children.length}, 1fr)`,
							gap: `${element.gap}`,
						}}
					>
						{element.children?.map((child: any, index: any) =>
							setInputType(child, index, true)
						)}
					</div>
				);
			case "flex":
				return (
					<div
						style={{
							display: "flex",
							flexDirection: element.direction || "row",
							flexWrap: element.wrap || "wrap",
							gap: `${element.gap}`,
							rowGap: `${element.rowGap}` || "20px",
						}}
					>
						{element.children?.map((child: any, index: any) => (
							<div style={{ flexGrow: 1 }}>{setInputType(child, index)}</div>
						))}
					</div>
				);
			case "checkbox":
				return (
					<Form.Item
						label={element.label}
						name={element.name}
						valuePropName="checked"
						rules={[
							{
								required: element.required,
								message: "",
								validator: (rule, value) => {
									if (value) {
										return Promise.resolve();
									} else {
										return Promise.reject("Please check the checkbox");
									}
								},
							},
						]}
					>
						<Checkbox onChange={() => setFormReset(false)}>
							{element.text}
						</Checkbox>
					</Form.Item>
				);
			case "phone_number":
				const countryCode = uniqueCountryCodes
					.map((code: any) => {
						return {
							label: code,
							value: code,
						};
					})
					.sort((a: any, b: any) => {
						if (a.label.toLowerCase() < b.label.toLowerCase()) {
							return -1;
						}
						if (a.label.toLowerCase() > b.label.toLowerCase()) {
							return 1;
						}
						return 0;
					});
				return (
					<Form.Item
						label={element.label}
						name={element.name}
						rules={[{ required: element.required, message: "" }]}
					>
						<Input
							// autoFocus={index == 0 && !activeRowKey && !isChild}
							autoComplete="off"
							count={
								element.count && {
									show: true,
									max: element.count,
								}
							}
							addonBefore={
								<Form.Item name="country_code" noStyle>
									<Select
										getPopupContainer={(trigger: any) => trigger.parentNode}
										style={{ width: "80px" }}
										showSearch
										removeIcon
										options={countryCode}
										disabled={element.disabled}
									></Select>
								</Form.Item>
							}
							onChange={(event: any) => {
								const numericValue =
									event.target.value.replace(/\D/g, "") || null;

								formRef.setFieldsValue({
									[element.name]: numericValue,
								});
							}}
							style={{
								width: element.width ? `${element.width * 4}%` : "100%",
							}}
							disabled={element.disabled}
						></Input>
					</Form.Item>
				);
			case "divider":
				return (
					<Divider
						style={{ margin: 0, color: "rgba(255,255,255,0.45)" }}
						key={element.label}
						orientation="left"
					>
						{element.label}
					</Divider>
				);
			case "disabled":
				let fieldValue = formRef.getFieldValue(element.name) || "";
				return (
					<div style={{ gap: "8px", display: "flex", flexDirection: "column" }}>
						<Form.Item
							style={{ display: "none" }}
							name={element.name}
							rules={[{ required: element.required, message: "" }]}
						></Form.Item>
						<span>{element.label}</span>
						<Input
							style={{
								width: element.width ? `${element.width * 4}%` : "100%",
							}}
							disabled
							value={fieldValue}
						></Input>
					</div>
				);
			case "button":
				const checkDisable = () => {
					if (element.disableKey === "activeRowKey") {
						return !activeRowKey;
					} else {
						return true;
					}
				};
				return (
					<Button
						style={{
							display: "flex",
							padding: element?.padding || 0,
							width: "max-content",
							margin: element?.margin || 0,
							height: 0,
						}}
						onClick={element.function}
						type={element.buttonType}
						disabled={checkDisable()}
					>
						{element.label}
					</Button>
				);

			case "list":
				dataObject = dataOptions?.[element.name]?.[activeRowKey];
				if (dataObject?.length > 0) {
					return (
						<div
							style={{ display: "flex", flexDirection: "column", gap: "10px" }}
						>
							<span>{element.label}</span>
							{dataObject.map((object: any) => {
								let suffix = object[element.suffixKey] || "";
								let prefix = object[element.prefixKey] || "";
								let label = object[element.labelKey] || "";
								return (
									<div
										style={{
											padding: "5px 12px",
											border: "1px solid #434343",
											borderRadius: "2px",
										}}
									>
										{prefix} {label} {suffix}
									</div>
								);
							})}
						</div>
					);
				} else {
					return <></>;
				}

			default:
				return <Input disabled></Input>;
		}
	};

	// Populate form with data from the form list
	const setForm = () => {
		const formList = getFormOptionList(tabKey, formOption, activeRecord);
		if (formList) {
			return (
				<>
					{/* {tabKey === "well_project" && props.activeRowKey && (
						<span
							style={{
								position: "absolute",
								// top: "0", // Adjust as needed
								right: "0",
								paddingRight: "16px",
								color: "rgba(255, 255, 255, 0.5)",
							}}
						>
							# {props.activeRecord.well_project_code}
						</span>
					)} */}

					<Form
						layout={"vertical"}
						form={formRef}
						requiredMark="optional"
						style={{
							gap: "20px",
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
						onChange={(values: any) => {
							setFormReset(false);
							console.log("Custom form values", values);
						}}
						onFinish={(values: any) => {
							console.log("Send value", values);
						}}
					>
						{formList?.map((element: any, index: any) =>
							setInputType(element, index)
						)}
					</Form>
				</>
			);
		} else {
			return null;
		}
	};

	return setForm();
};

export default CustomForm;
