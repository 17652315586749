import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "tasks";

export const getTasks = (tenantKey: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addTaskItem = (tenantKey: any, data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${tenantKey}/${apiURL}`, data, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const updateTaskItem = (tenantKey: any, id: any, data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${tenantKey}/${apiURL}`,
				{
					filter: { _id: id },
					data: data,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const deleteTaskItem = (tenantKey: any, id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${url}/${tenantKey}/${apiURL}`, {
				params: {
					_id: id,
				},
				...AuthHeader,
			})
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};
